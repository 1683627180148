import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageTool } from '../../../service/session/storage.tool';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ClientProductsShoopingComponent} from '../client-products-shooping/client-products-shooping.component';

@Component({
  selector: 'app-details-product',
  templateUrl: './details-product.component.html',
  styleUrls: ['./details-product.component.css']
})
export class DetailsProductComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [
    {
        width: '600px',
        height: '400px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide
    },
    // max-width 800
    {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
    },
    // max-width 400
    {
        breakpoint: 400,
        preview: false
    }
  ];
  galleryImages: NgxGalleryImage[] = [];
  productModel = {
    id:'',
    name:'',
    price:'',
    short_description:'',
    description:'',
    image:'',
    tags:'',
    category_id:'',
    status:true,
    en_name:'',
    en_short_description:'',
    en_description:'',
    availability:1,
    outstanding:false,
    discount:false,
    freeDelivery:false,
    discountValue:0,
    observation:'',
    en_observation:'',
    sku:'',
    additional_data:''
  };

  object = JSON.parse(JSON.stringify(this.productModel));
  metadata = {
    quantity:1,
    capacity:'',
    productPriceList:[],
    unitPrice:0,
    productPriceSelected:{
      id:''
    },
    unitPriceDiscount:0,
    firstTimeProperties:true,
    showCarritoBTN:false
  }
  productPriceMinModel = {
    price:'',
    quantity:0,
    wood:'',
    varnish:'',
    capacity:'',
    isTexture:false,
    texture:'#000',
    weight:'',
    dimension:'',
    tapestry:'',
    tissue:'',
    backrest:'',
    observation:'',
    istapestry:false,
    tapestryimg:'#000',
  }

  productPriceMin = {
    price:'',
    quantity:0,
    wood:'',
    varnish:'',
    capacity:'',
    isTexture:false,
    texture:'#000',
    weight:'',
    dimension:'',
    tapestry:'',
    tissue:'',
    backrest:'',
    observation:'',
    istapestry:false,
    tapestryimg:'#000',
  }
  productMin = {
    status: true,
    order:'',
    max:19,
    page:1,
    category:{
      id:''
    }
  };
  fakeQuantity = [];
  products = [];
  productAttributeList = [];
  // productPriceList = [];
  productPricesList = [];
  productPricesListAux = [];
  backrestList = [];
  capacityList = [];
  dimensionList = [];
  tapestryList = [];
  tissueList = [];
  varnishList = [];
  woodList = [];
  additionalDataModel = {
    name:'',
    value:''
  }
  additionalDataArray = [];
  productCategoryModel = {
    id:'',
    category:{
      id:''
    },
    product:{
      id:''
    }
  }
  productLastSee = [];
  constructor(private session: SessionService,
    private router:Router,
    public activatedRoute:ActivatedRoute,
    public snackBar: MatSnackBar,
    public loading:LoadingService,
    private _location: Location,
    public storage: StorageTool,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.galleryImages = [];
    this.fakeQuantity = [];
    this.activatedRoute.params.subscribe((params: Params)=>{
      this.getLastSeeProducts();
      if(params['id'] == 'new'){
      }else{
        this.object.id = params['id'];
        this.getCategoriesforProduct();
        this.loading.show(true,"Espere un momento...");
        this.session.postRequestAnonimus("product:get",this.object).subscribe((data:any)=>{
          this.object = data.object;
          this.metadata.unitPrice = this.object.price;
          this.productPricesList = JSON.parse(JSON.stringify(this.object.product_prices));
          this.productPricesListAux = JSON.parse(JSON.stringify(this.object.product_prices));
          this.metadata.productPriceList = JSON.parse(JSON.stringify(this.object.product_prices));
          this.object.product_prices = [];
          //verificamos si hay imagenes cargadas
          let imgAux = [];
          for(let item of this.object.images){
            imgAux.push({
              small:this.session.urlIMG+'uploads/'+item.image,
              medium:this.session.urlIMG+'uploads/'+item.image,
              big:this.session.urlIMG+'uploads/'+item.image,
            });
          }
          this.galleryImages = imgAux;
          // if(this.object.attributesList.length > 0){
          //   this.metadata.capacity = this.object.attributesList[0].capacity;
          //   this.showProductPriceList(this.object.attributesList[0]);
          // }
          //Calculamos descuento para mostrar en html
          if(this.productPricesList.length == 0){
            if(this.object.discount){
              this.calculateDiscount(this.object.discountValue,this.metadata.unitPrice);
            }
          }
          if(this.object.multiplePrice){
            this.metadata.showCarritoBTN = false;
            this.resetProperties();
          }else{
            this.metadata.showCarritoBTN = true;
            for(let i=1; i <= this.object.availability; i++){
              this.fakeQuantity.push(i);
            }
          }
          let arrayAuxtextArray = this.object.additional_data.split('|');
          for(let item of arrayAuxtextArray){
            if(item){
              let auxArra = JSON.parse(JSON.stringify(this.additionalDataModel));
              let itemTexts = item.split('[');
              auxArra.name = itemTexts[0];
              if(itemTexts.length == 2){
                auxArra.value = itemTexts[1];
              }
              this.additionalDataArray.push(auxArra);
            }
          }
          this.updateLastSee(this.object);
          // console.log("productPricesList::::",this.productPricesList);
          this.loading.hide();
        },error=>{
          console.log('product:get',error);
          this.loading.hide();
        })
      }
    },error=>{
      console.log("activatedRoute:error",error);
    })
  }

  /*funcionalidad para agregar un producto al carrito de compras
  */
  addProductToShoopingCart(){
    this.storage.getAkeyFromStorage('shoppingCart').then((data:any)=>{
      let shoppingCart = [];
      if(data){
        shoppingCart = data;
      }
      this.object.price = this.metadata.unitPrice;
      for(let item of this.metadata.productPriceList){
        if(item.selected){
          this.metadata.productPriceSelected = JSON.parse(JSON.stringify(item));
        }
      }
      // this.object.attributesList = [];
      shoppingCart.push({
        quantity:this.metadata.quantity,
        product:this.object,
        productPrice:this.metadata.productPriceSelected
      });
      localStorage.setItem('carritoLength', shoppingCart.length.toString());
      if(this.object.image.search(this.session.urlIMG) == -1){
        this.object.image = this.session.urlIMG+'uploads/'+this.object.image;
      }
      this.storage.saveAKeyOnTheStorage('shoppingCart',shoppingCart).then(()=>{
        this.session.setShoppingCartTotal(shoppingCart.length);
        this.snackBar.open('Listo, este producto se agrego a tu carrito.', 'Aceptar', {duration: 5000});
        const dialogRef = this.dialog.open(ClientProductsShoopingComponent, {
          width: '50%',
          data: this.object,
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != undefined ){
            if(result.transaction == 'ok'){
              // El modal se cerro con objeto
            }else{
              // El modal se cerro sin objeto
              // this.snackBar.open('No se guardo ningun registro', '', {
              //   duration: 1000
              // });
            }
          }else{
            // El modal se cerro sin seleccionar algo, dandole click fuera
            // this.snackBar.open('No se guardo ningun registro', '', {
            //   duration: 1000
            // });
          }
        });
      }).catch(e=>{
        console.log(e);
      })

    }).catch(e=>{
      console.log(e);
    })
  }

  /**funcionalidad para obtener el lsuatdo de productos
  */
  getProducts(category){
    return new Promise((resolve,reject)=>{
      this.loading.show(true,"Espere un momento...")
      let productMinAux = JSON.parse(JSON.stringify(this.productMin));
      productMinAux.category.id = category.id;
      this.session.postRequestAnonimus("product:findAllByCategory",productMinAux).subscribe((data:any)=>{
        // console.log(data);
        for(let i = 0; i < data.object.instanceList.length; i++){

          for(let j = 0; j < data.object.instanceList[i].images.length; j++){
            if(data.object.instanceList[i].images[j].image == data.object.instanceList[i].image){
              data.object.instanceList[i].images.splice(j,1);
              break;
            }
          }

          data.object.instanceList[i].image =  this.session.urlIMG+'uploads/'+data.object.instanceList[i].image;
          data.object.instanceList[i].showImage = data.object.instanceList[i].image;
          for(let j = 0; j < data.object.instanceList[i].images.length; j++){
            data.object.instanceList[i].images[j].image =  this.session.urlIMG+'uploads/'+data.object.instanceList[i].images[j].image;
          }
        }
        this.products = data.object.instanceList;
        // const LONGITUD_PEDAZOS = 4; // Partir en arreglo de 3
        // for (let i = 0; i < data.object.instanceList.length; i += LONGITUD_PEDAZOS) {
        //   let pedazo = data.object.instanceList.slice(i, i + LONGITUD_PEDAZOS);
        //   this.products.push(pedazo);
        // }
        this.loading.hide();
        resolve(true);
      },error=>{
        reject(error);
        console.log("product:findAllByStatusAndOutstanding:error",error);
        this.loading.hide();
      })
    });

  }
  /*Funcion para regresar*/
  backClicked() {
  this._location.back();
}

/**función para obtener los atributos de un producto
*/
  getAttributes(){
    this.session.postRequestAnonimus("productAttribute:findAllByProduct",{product:{id:this.object.id}}).subscribe((data:any)=>{
      this.productAttributeList = data.object.instanceList;
    },error=>{
      console.log(error);
    });
  }


  selectedProductPrice(object){
    for(let i = 0; i < this.metadata.productPriceList.length; i++){
      this.metadata.productPriceList[i].selected = false;
    }
    for(let i = 0; i < this.metadata.productPriceList.length; i++){
      if(this.metadata.productPriceList[i].id == object.id){
        this.metadata.productPriceList[i].selected = true;
      }
    }
    object.selected = true;
    this.metadata.unitPrice = object.price;
    //Calculamos descuento para mostrar en html
    if(this.object.discount){
      this.calculateDiscount(this.object.discountValue,this.metadata.unitPrice);
    }
  }

  /**funcionalidad para calcular el descuento de un producto
  */
  calculateDiscount(discountValue,price){
    this.metadata.unitPriceDiscount = price;
    this.metadata.unitPrice = (this.metadata.unitPriceDiscount * (100-discountValue))/100;
  }

  async resetProperties(){

    let backrestListAUX =  this.productPricesList.filter((item)=>{
      return item.backrest != "";
    });
    this.backrestList = JSON.parse(JSON.stringify(backrestListAUX));

    let capacityListAux = this.productPricesList.filter((item)=>{
      return item.capacity != "";
    });
    this.capacityList = JSON.parse(JSON.stringify(capacityListAux));

    let dimensionListAUX = this.productPricesList.filter((item)=>{
      return item.dimension != "";
    });
    this.dimensionList = JSON.parse(JSON.stringify(dimensionListAUX));


    let tapestryListAux = this.productPricesList.filter((item)=>{
      return item.tapestry != "";
    });
    this.tapestryList = JSON.parse(JSON.stringify(tapestryListAux));

    let tissueListAux = this.productPricesList.filter((item)=>{
      return item.tissue != "";
    });
    this.tissueList = JSON.parse(JSON.stringify(tissueListAux));


    let varnishListAux = this.productPricesList.filter((item)=>{
      return item.varnish != "";
    });
    this.varnishList = JSON.parse(JSON.stringify(varnishListAux));

    let woodListAUX = this.productPricesList.filter((item)=>{
      return item.wood != "";
    });
    this.woodList = JSON.parse(JSON.stringify(woodListAUX));

    this.woodList = await this.deleteDuplicates(this.woodList,'wood');
    this.varnishList = await this.deleteDuplicates(this.varnishList,'varnish');
    this.tissueList = await this.deleteDuplicates(this.tissueList,'tissue');
    this.tapestryList = await this.deleteDuplicates(this.tapestryList,'tapestry');
    this.dimensionList = await this.deleteDuplicates(this.dimensionList,'dimension');
    this.capacityList = await this.deleteDuplicates(this.capacityList,'capacity');
    this.backrestList = await this.deleteDuplicates(this.backrestList,'backrest');
    this.metadata.firstTimeProperties = true;
    this.metadata.showCarritoBTN = false;
    this.productPriceMin = JSON.parse(JSON.stringify(this.productPriceMinModel))
  }

  /**funcion para eliminar elementos duplicados
  */
  deleteDuplicates(list,type):Promise<Array<any>>{
    return new Promise((resolve,reject)=>{
      let newList = [];
      for(let i = 0; i < list.length; i++){
        let found = false;
        for(let item of newList){
          if(item[type] == list[i][type]){
            found = true;
          }
        }
        if(!found){
          list[i].enabled = true;
          newList.push(list[i]);
        }
      }
      resolve(newList);
    });
  }

  /**función para seleccionar una propiedad
  */
  selecteProperty(type){
    this.checkisPropertyIsAble(type);
  }

  selectAProperty(object,value,type){
    if(object.enabled){
      object.selected = true;
      this.productPriceMin[type] = value;
      this.selecteProperty(type);
    }else{
      // console.log("No hacemos nada.....")
    }
  }

  checkisPropertyIsAble(type){
    if(this.metadata.firstTimeProperties){
      this.productPricesListAux = JSON.parse(JSON.stringify(this.productPricesList));
    }
    this.metadata.firstTimeProperties = false;
    let arrayFilteredWithProperties = [];
    arrayFilteredWithProperties = this.productPricesListAux.filter((item)=>{
      return item[type] == this.productPriceMin[type];
    });
    this.productPricesListAux = JSON.parse(JSON.stringify(arrayFilteredWithProperties));

    // console.log("FILTRADO:::::::",arrayFilteredWithProperties);
    // console.log("productPricesListAux:::::::",this.productPricesListAux);

    for(let i=0; i < this.backrestList.length; i++){
      if(!this.backrestList[i].selected)
        this.backrestList[i].enabled = false;
    }
    for(let i=0; i < this.capacityList.length; i++){
      if(!this.capacityList[i].selected)
        this.capacityList[i].enabled = false;
    }
    for(let i=0; i < this.dimensionList.length; i++){
      if(!this.dimensionList[i].selected)
        this.dimensionList[i].enabled = false;
    }
    for(let i=0; i < this.tapestryList.length; i++){
      if(!this.tapestryList[i].selected)
        this.tapestryList[i].enabled = false;
    }
    for(let i=0; i < this.tissueList.length; i++){
      if(!this.tissueList[i].selected)
        this.tissueList[i].enabled = false;
    }
    for(let i=0; i < this.varnishList.length; i++){
      if(!this.varnishList[i].selected)
        this.varnishList[i].enabled = false;
    }
    for(let i=0; i < this.woodList.length; i++){
      if(!this.woodList[i].selected)
        this.woodList[i].enabled = false;
    }

    for(let onearrayFilteredWithProperties of arrayFilteredWithProperties){

      for(let i=0; i < this.backrestList.length; i++){
        if(!this.backrestList[i].selected)
          this.backrestList[i].enabled = this.backrestList[i].enabled ? true : this.backrestList[i].backrest == onearrayFilteredWithProperties.backrest;
      }
      for(let i=0; i < this.capacityList.length; i++){
        if(!this.capacityList[i].selected)
          this.capacityList[i].enabled = this.capacityList[i].enabled ? true :  this.capacityList[i].capacity == onearrayFilteredWithProperties.capacity;
      }


      for(let i=0; i < this.dimensionList.length; i++){
        if(!this.dimensionList[i].selected)
          this.dimensionList[i].enabled = this.dimensionList[i].enabled ? true : this.dimensionList[i].dimension == onearrayFilteredWithProperties.dimension;
      }

      for(let i=0; i < this.tapestryList.length; i++){
        if(!this.tapestryList[i].selected)
          this.tapestryList[i].enabled = this.tapestryList[i].enabled ? true : this.tapestryList[i].tapestry == onearrayFilteredWithProperties.tapestry;
      }

      for(let i=0; i < this.tissueList.length; i++){
        if(!this.tissueList[i].selected)
          this.tissueList[i].enabled = this.tissueList[i].enabled ? true : this.tissueList[i].tissue == onearrayFilteredWithProperties.tissue;
      }

      for(let i=0; i < this.varnishList.length; i++){
        if(!this.varnishList[i].selected)
          this.varnishList[i].enabled = this.varnishList[i].enabled ? true : this.varnishList[i].varnish == onearrayFilteredWithProperties.varnish;
      }

      for(let i=0; i < this.woodList.length; i++){
        if(!this.woodList[i].selected)
          this.woodList[i].enabled = this.woodList[i].enabled ? true : this.woodList[i].wood == onearrayFilteredWithProperties.wood;
      }

    }

    // si solo hay un objeto en el arreglo por default seleccionamos el unico
    if(this.productPricesListAux.length == 1 ){

      for(let i=0; i < this.backrestList.length; i++){
        if(this.productPricesListAux[0].backrest == this.backrestList[i].backrest && this.backrestList[i].enabled){
          this.backrestList[i].selected = true;
          this.productPriceMin.backrest = this.productPricesListAux[0].backrest;
        }
      }

      for(let i=0; i < this.capacityList.length; i++){
        if(this.productPricesListAux[0].capacity == this.capacityList[i].capacity && this.capacityList[i].enabled){
          this.capacityList[i].selected = true;
          this.productPriceMin.capacity = this.productPricesListAux[0].capacity;
        }
      }

      for(let i=0; i < this.dimensionList.length; i++){
        if(this.productPricesListAux[0].dimension == this.dimensionList[i].dimension && this.dimensionList[i].enabled){
          this.dimensionList[i].selected = true;
          this.productPriceMin.dimension = this.productPricesListAux[0].dimension;
        }
      }

      for(let i=0; i < this.tapestryList.length; i++){
        if(this.productPricesListAux[0].tapestry == this.tapestryList[i].tapestry && this.tapestryList[i].enabled){
          this.tapestryList[i].selected = true;
          this.productPriceMin.tapestry = this.productPricesListAux[0].tapestry;
        }
      }

      for(let i=0; i < this.tissueList.length; i++){
        if(this.productPricesListAux[0].tissue == this.tissueList[i].tissue && this.tissueList[i].enabled){
          this.tissueList[i].selected = true;
          this.productPriceMin.tissue = this.productPricesListAux[0].tissue;
        }
      }

      for(let i=0; i < this.varnishList.length; i++){
        if(this.productPricesListAux[0].varnish == this.varnishList[i].varnish && this.varnishList[i].enabled){
          this.varnishList[i].selected = true;
          this.productPriceMin.varnish = this.productPricesListAux[0].varnish;
        }
      }

      for(let i=0; i < this.woodList.length; i++){
        if(this.productPricesListAux[0].wood == this.woodList[i].wood && this.woodList[i].enabled){
          this.woodList[i].selected = true;
          this.productPriceMin.wood = this.productPricesListAux[0].wood;
        }
      }
      this.metadata.showCarritoBTN = true;
      this.object.availability = this.productPricesListAux[0].quantity;
      this.fakeQuantity = [];
      for(let i=1; i <= this.object.availability; i++){
        this.fakeQuantity.push(i);
      }
      this.selectedProductPrice(this.productPricesListAux[0])
    }

    // console.log("this.capacityList",this.capacityList);
    // console.log("this.backrestList",this.backrestList);
    // console.log("this.dimensionList",this.dimensionList);
    // console.log("this.tapestryList",this.tapestryList);
    // console.log("this.tissueList",this.tissueList);
    // console.log("this.varnishList",this.varnishList);
    // console.log("this.woodList",this.woodList);
  }


  // funcion para obtener los datos del paginado.
  scrollTo(element:HTMLElement){
    setTimeout(function(){
      element.scrollIntoView({behavior: 'smooth'});
    }, 100);
  }
  /**funcionalidad para obtener categorias de una productos
  */
  getCategoriesforProduct(){
    this.products = [];
    let auxCategory = JSON.parse(JSON.stringify(this.productCategoryModel));
    auxCategory.product.id = this.object.id;
    this.session.postRequest("productCategory:findAllByProduct",auxCategory).subscribe(async(data:any)=>{
      for(let item of data.object.instanceList){
        await this.getProducts(item.category);
      }
      var hash = {};
      this.products = this.products.filter((current)=> {
        var exists = !hash[current.sku];
        hash[current.sku] = true;
        return exists;
      });
    },error=>{
      console.log("error:productCategory:findAllByProduct",error);
    })
  }

  /**se agrega funcionalida para mostrar varias imaagnes
  */
  changeImgToShow(object){
    object.showImage = object.images[Math.floor((Math.random()*object.images.length))].image;
    return true;
  }
  /**se agrega funcionalida para mostrar la imagane de portada
  */
  ResetImgToShow(object){
    object.showImage = object.image;
    return true;
  }


  /**funcionalida para agregar un producto a lo ultimo visto
  */
  updateLastSee(object){
    this.storage.getAkeyFromStorage('lastSee').then((data:any)=>{
      let lastSee = [];
      if(data){
        lastSee = data;
      }
      // this.object.attributesList = [];
      let found = false;
      for(let  i = 0; i< lastSee.length; i++){
        if(lastSee[i].id == object.id){
          found = true;
        }
      }
      if(!found){
        // console.log(object);
        object.image =  this.session.urlIMG+'uploads/'+object.image;
        object.showImage = object.image;
        object.images = [];
        for(let item of this.galleryImages){
          object.images.push({image:item.big});
        }
        lastSee.unshift(object);

        if(lastSee.length >= 9){
          lastSee.pop();
        }
        this.storage.saveAKeyOnTheStorage('lastSee',lastSee).then(()=>{
          // console.log("Agregado a lo ultimo visto")
        }).catch(e=>{
          console.log(e);
        })
      }
    }).catch(e=>{
      console.log(e);
    })
  }

  /**funcionalidad para ver lo ultimo vistado
  */
  getLastSeeProducts(){
    this.storage.getAkeyFromStorage('lastSee').then((data:any)=>{
      if(data){
        this.productLastSee = data;
      }
    }).catch(e=>{
      console.log(e);
    })
  }

}
