<app-admin-toolbar></app-admin-toolbar>
<div class="row justify-content-center" style="margin-top: 50px;">
  <div class="col-12 col-md-10 cardTono">
    <div class="row justify-content-between">
      <div class="col-8">
        <h2 class="mb-2">Nuevo Producto</h2>
      </div>
      <div class="col-3 text-right">
        <button mat-button color="primary" routerLink="/admin/product">REGRESAR</button>
      </div>
      <p class="col-12">Ingrese los datos requeridos del formulario.</p>
    </div>
    <mat-tab-group mat-align-tabs="center" (selectedTabChange)="changeTab($event);" [selectedIndex]="indexTAB">
      <mat-tab label="Datos Generales">
        <div class="row justify-content-center">
          <div class="col-10">
            <mat-slide-toggle [labelPosition]="'before'" [(ngModel)]="object.status" style="float:right;">Activado</mat-slide-toggle>
          </div>
          <div class="col-10">
            <mat-slide-toggle [labelPosition]="'before'" [(ngModel)]="object.outstanding" style="float:right;">Producto Destacado</mat-slide-toggle>
          </div>
          <div class="col-10">
            <mat-slide-toggle [labelPosition]="'before'" [(ngModel)]="object.freeDelivery" style="float:right;">Producto con recoge solo en tienda</mat-slide-toggle>
          </div>
          <mat-form-field appearance="outline" class="col-4 col-md-4">
            <mat-label>SKU</mat-label>
            <input matInput placeholder="SKU." name="sku" [(ngModel)]="object.sku">
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-8 col-md-4">
            <mat-label>Nombre.</mat-label>
            <input matInput placeholder="Agregar un nombre." name="nombre" [formControl]="complexForm.controls['name']" [(ngModel)]="object.name" required>
            <mat-hint>
              <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
                Campo <strong>requerido</strong>.
              </mat-error>
            </mat-hint>
          </mat-form-field>
          <div class="col-2 col-md-2">

          </div>

          <mat-form-field appearance="outline" class="col-10">
            <mat-label>Cantidad a vender</mat-label>
            <input matInput placeholder="Cantidad a vender" name="quantity" [(ngModel)]="object.availability" required>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-10">
            <mat-label>Descripción</mat-label>
            <textarea matInput placeholder="Descripción" name="descripcion" [formControl]="complexForm.controls['description']" [(ngModel)]="object.description" required></textarea>
            <mat-error *ngIf="complexForm.controls['description'].hasError('required')">
              Campo <strong>requerido</strong>.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-10">
            <mat-label>Descripción corta</mat-label>
            <textarea matInput placeholder="Descripción corta" name="short_description"  [(ngModel)]="object.short_description"></textarea>
          </mat-form-field>
          <!-- <hr class="col-11">
          <p class="col-12">INGLES</p>
          <mat-form-field appearance="outline" class="col-10">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Agregar un nombre." name="en_name" [(ngModel)]="object.en_name" required>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-10">
            <mat-label>Description</mat-label>
            <textarea matInput placeholder="Descripción" name="en_descripcion"  [(ngModel)]="object.en_description" required></textarea>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-10">
            <mat-label>Short description</mat-label>
            <textarea matInput placeholder="Short description" name="en_short_description"  [(ngModel)]="object.en_short_description"></textarea>
          </mat-form-field> -->

          <div class="col-10">
            <div class="row m-3">

              <div class="col-6 row">
                <p>Selecciona una categoria para agregarla al producto.</p>
                <div class="col-12">
                  <mat-form-field class="col-12" appearance="fill">
                    <mat-label>Categorias seleccionadas</mat-label>
                    <mat-chip-list #chipList aria-label="Fruit selection">
                      <mat-chip *ngFor="let object of categorySelectedList" color="accent" selected (removed)="remove(object)">
                        {{object.name}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                      <input [formControl]="catCtrl"  [matChipInputFor]="chipList" name="scategory">
                    </mat-chip-list>
                  </mat-form-field>
                </div>
                <div class="col-12 mt-4 mb-4">
                  <div class="" *ngFor="let object of list;">
                    <div class="treeRow">
                      <div class="treeArrows">
                        <button mat-icon-button color="primary" *ngIf="object.more > 0 && !object.opened" (click)="loadChildCategories(object);">
                          <mat-icon>navigate_next</mat-icon>
                        </button>
                        <button mat-icon-button color="primary" *ngIf="object.opened" (click)="closeCategoryList(object);">
                          <mat-icon>keyboard_arrow_down</mat-icon>
                        </button>
                        <button mat-icon-button color="primary" *ngIf="object.more == 0" disabled>
                          <mat-icon>remove</mat-icon>
                        </button>
                      </div>
                      <div class="treeTitle">
                        {{object.name}}
                      </div>
                      <div class="treeActions">
                        <button mat-icon-button color="accent" matTooltip="Seleccionar" (click)="checkedCategory(object);" *ngIf="!object.selected">
                          <mat-icon>radio_button_unchecked</mat-icon>
                        </button>
                        <button mat-icon-button color="accent" matTooltip="Seleccionar" *ngIf="object.selected" (click)="unCheckedCategory(object);">
                          <mat-icon>radio_button_checked</mat-icon>
                        </button>

                      </div>
                    </div>

                    <div class="" style="padding-left: 60px;" *ngIf="object.opened">
                      <div class=""  *ngFor="let object of object.categoryList;">
                        <div class="treeRow">
                          <div class="treeArrows">
                            <button mat-icon-button color="primary" *ngIf="object.more > 0 && !object.opened" (click)="loadChildCategories(object);">
                              <mat-icon>navigate_next</mat-icon>
                            </button>
                            <button mat-icon-button color="primary" *ngIf="object.opened" (click)="closeCategoryList(object);">
                              <mat-icon>keyboard_arrow_down</mat-icon>
                            </button>
                            <button mat-icon-button color="primary" *ngIf="object.more == 0" disabled>
                              <mat-icon>remove</mat-icon>
                            </button>
                          </div>
                          <div class="treeTitle">
                            {{object.name}}
                          </div>
                          <div class="treeActions">
                            <button mat-icon-button color="accent" matTooltip="Seleccionar" (click)="checkedCategory(object);" *ngIf="!object.selected">
                              <mat-icon>radio_button_unchecked</mat-icon>
                            </button>
                            <button mat-icon-button color="accent" matTooltip="Seleccionar" *ngIf="object.selected" (click)="unCheckedCategory(object);">
                              <mat-icon>radio_button_checked</mat-icon>
                            </button>
                          </div>
                        </div>
                        <div class="" style="padding-left: 60px;" *ngIf="object.opened">
                          <div class=""  *ngFor="let object of object.categoryList;">
                            <div class="treeRow">
                              <div class="treeArrows">
                                <button mat-icon-button color="primary" *ngIf="object.more > 0 && !object.opened" (click)="loadChildCategories(object);">
                                  <mat-icon>navigate_next</mat-icon>
                                </button>
                                <button mat-icon-button color="primary" *ngIf="object.opened" (click)="closeCategoryList(object);">
                                  <mat-icon>keyboard_arrow_down</mat-icon>
                                </button>
                                <button mat-icon-button color="primary" *ngIf="object.more == 0" disabled>
                                  <mat-icon>remove</mat-icon>
                                </button>
                              </div>
                              <div class="treeTitle">
                                {{object.name}}
                              </div>
                              <div class="treeActions">
                                <button mat-icon-button color="accent" matTooltip="Seleccionar" (click)="checkedCategory(object);" *ngIf="!object.selected">
                                  <mat-icon>radio_button_unchecked</mat-icon>
                                </button>
                                <button mat-icon-button color="accent" matTooltip="Seleccionar" *ngIf="object.selected" (click)="unCheckedCategory(object);">
                                  <mat-icon>radio_button_checked</mat-icon>
                                </button>
                              </div>
                            </div>
                            <div class=""  *ngFor="let object of object.categoryList;">
                              <div class="treeRow">
                                <div class="treeArrows">
                                  <button mat-icon-button color="primary" *ngIf="object.more > 0 && !object.opened" (click)="loadChildCategories(object);">
                                    <mat-icon>navigate_next</mat-icon>
                                  </button>
                                  <button mat-icon-button color="primary" *ngIf="object.opened" (click)="closeCategoryList(object);">
                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                  </button>
                                  <button mat-icon-button color="primary" *ngIf="object.more == 0" disabled>
                                    <mat-icon>remove</mat-icon>
                                  </button>
                                </div>
                                <div class="treeTitle">
                                  {{object.name}}
                                </div>
                                <div class="treeActions">
                                  <button mat-icon-button color="accent" matTooltip="Seleccionar" (click)="checkedCategory(object);" *ngIf="!object.selected">
                                    <mat-icon>radio_button_unchecked</mat-icon>
                                  </button>
                                  <button mat-icon-button color="accent" matTooltip="Seleccionar" *ngIf="object.selected" (click)="unCheckedCategory(object);">
                                    <mat-icon>radio_button_checked</mat-icon>
                                  </button>
                                </div>
                              </div>
                              <div class="" style="padding-left: 60px;" *ngIf="object.opened">
                                <div class=""  *ngFor="let object of object.categoryList;">
                                  <div class="treeRow">
                                    <div class="treeArrows">
                                      <button mat-icon-button color="primary" *ngIf="object.more > 0 && !object.opened" (click)="loadChildCategories(object);">
                                        <mat-icon>navigate_next</mat-icon>
                                      </button>
                                      <button mat-icon-button color="primary" *ngIf="object.opened" (click)="closeCategoryList(object);">
                                        <mat-icon>keyboard_arrow_down</mat-icon>
                                      </button>
                                      <button mat-icon-button color="primary" *ngIf="object.more == 0" disabled>
                                        <mat-icon>remove</mat-icon>
                                      </button>
                                    </div>
                                    <div class="treeTitle">
                                      {{object.name}}
                                    </div>
                                    <div class="treeActions">
                                      <button mat-icon-button color="accent" matTooltip="Seleccionar" (click)="checkedCategory(object);" *ngIf="!object.selected">
                                        <mat-icon>radio_button_unchecked</mat-icon>
                                      </button>
                                      <button mat-icon-button color="accent" matTooltip="Seleccionar" *ngIf="object.selected" (click)="unCheckedCategory(object);">
                                        <mat-icon>radio_button_checked</mat-icon>
                                      </button>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>


                  </div>

                </div>
              </div>
              <div class="col-6">
                <div class="row m-0 p-0">
                  <mat-form-field appearance="outline" class="col-6 p-0">
                    <mat-label>Marca</mat-label>
                    <mat-select name="Marca" [(ngModel)]="object.maker_id" [formControl]="complexForm.controls['maker']" required>
                      <mat-option *ngFor="let object of makerList" [value]="object.id">
                        {{object.name}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="complexForm.controls['maker'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>
                  <div class="col-3">
                    <button mat-button color="primary" (click)="updatemaker('new');"><mat-icon aria-hidden="false" aria-label="Nuevo">add_circle</mat-icon> Nueva Marca</button>
                  </div>
                  <mat-form-field appearance="outline" class="col-12 p-0">
                    <mat-label>Tags</mat-label>
                    <input matInput placeholder="Tags" name="tags" [(ngModel)]="object.tags" required>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="col-12 p-0">
                    <mat-label>Observaciones</mat-label>
                    <textarea matInput placeholder="Observaciones" name="observation"  [(ngModel)]="object.observation"></textarea>
                  </mat-form-field>
                </div>

              </div>
              <!-- <mat-form-field appearance="outline" class="col-6 p-0">
                <mat-label>Categoria</mat-label>
                <mat-select name="categoria" [(ngModel)]="object.category_id" [formControl]="complexForm.controls['categoria']" required>
                  <mat-option *ngFor="let object of categoryList" [value]="object.id">
                    {{object.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="complexForm.controls['categoria'].hasError('required')">
                  Campo <strong>requerido</strong>.
                </mat-error>
              </mat-form-field>
              <div class="col-3">
                <button mat-button color="primary" (click)="updateCategory('new');"><mat-icon aria-hidden="false" aria-label="Nuevo">add_circle</mat-icon> Nueva Categoria</button>
              </div> -->



              <div class="col-12">
                <p>Ingrega las images a utilzar en la plataforma, dale click en <strong>"SUBIR"</strong> y selecciona la <strong>portada principal</strong> de tu producto</p>
                <div *ngFor="let object of imgesList">
                  <div class="row">
                    <div class="col-1">
                      <button mat-icon-button *ngIf="!object.cover" (click)="coverSelected(object);" style="top:25%;"> <mat-icon>radio_button_unchecked</mat-icon></button>
                      <button mat-icon-button *ngIf="object.cover"  style="top:25%;"> <mat-icon>check_circle_outline</mat-icon></button>
                    </div>
                    <div class="col-9">
                      <image-product [uploadCode]="'product:upload'" [mFile]="object"></image-product>
                    </div>
                    <div class="col-1">
                      <button mat-icon-button *ngIf="object.uploadted && object.img.name != ''"  style="top:25%;" (click)="deleteAnImage(object)"> <mat-icon>delete</mat-icon></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-2">
                <br>
                <button mat-raised-button color="primary" class="cardImgButtom"   (click)="addImgModel();">Agregar nueva imagen</button>
                <button mat-raised-button color="primary"  class="cardImgButtom"  (click)="deleteImgModel();">Quitar una imagen</button>

              </div>
            </div>
          </div>
          <div class="col-10">
            <p class="mb-3"><strong>Ingrese datos adicionales</strong></p>
            <div class="aditionalDataCC" *ngFor="let object of additionalDataArray; let  i = index;">
              <div class="" style="padding: 5px 15px;">
                <mat-form-field appearance="fill">
                  <mat-label>Nombre</mat-label>
                  <input matInput placeholder="Nombre" name="names{{i}}" [(ngModel)]="object.name">
                </mat-form-field>
              </div>
              <div class="" style="padding: 5px 15px;">
                <mat-form-field appearance="fill">
                  <mat-label>Valor</mat-label>
                  <input matInput placeholder="Valor" name="values{{i}}" [(ngModel)]="object.value">
                </mat-form-field>
              </div>
            </div>
            <div class="">
              <button mat-raised-button color="primary" (click)="addAdditionalData();">Agregar item</button>
            </div>
          </div>
          <div class="col-10 col-md-6 mt-3">
            <button mat-raised-button color="primary" style="width:100%;"  class="cardImgButtom" (click)="nextTab();" [disabled]="complexForm.invalid">SIGUIENTE</button>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Precios" [disabled]="object.nombre == '' || object.image == ''">
        <div class="row justify-content-center">
          <div class="col-10">
            <mat-slide-toggle [labelPosition]="'before'" [(ngModel)]="object.discount" style="float: left; margin-bottom: 15px; margin-top: 15px;" (change)="send(false);">¿ El producto tiene descuento ? <strong *ngIf="object.discount">Si</strong> <strong *ngIf="!object.discount">No</strong> </mat-slide-toggle>
          </div>
          <mat-form-field appearance="outline" class="col-10 col-md-10" *ngIf="object.discount">
            <mat-label>Descuento (%)</mat-label>
            <input matInput placeholder="Descuento" name="discountValue" type="number"[(ngModel)]="object.discountValue" (change)="send(false);" required>
          </mat-form-field>

          <div class="col-10">
            <mat-slide-toggle [labelPosition]="'before'" [(ngModel)]="object.multiplePrice" style="float: left; margin-bottom: 15px; margin-top: 15px;" (change)="send(false);">¿ El producto tiene multiples precios ? <strong *ngIf="object.multiplePrice">Si</strong> <strong *ngIf="!object.multiplePrice">No</strong> </mat-slide-toggle>
          </div>
          <mat-form-field appearance="outline" class="col-10 col-md-10">
            <mat-label>Precio a mostrar</mat-label>
            <input matInput placeholder="Precio" name="price" type="number" [formControl]="complexForm.controls['price']" [(ngModel)]="object.price" required>
            <mat-hint>
              <mat-error *ngIf="complexForm.controls['price'].hasError('required')">
                Campo <strong>requerido</strong>.
              </mat-error>
            </mat-hint>
          </mat-form-field>

          <div class="col-10 col-md-10" *ngIf="object.multiplePrice">
            <table class="table table-general" style="font-size: .9rem;">
              <thead class="header-table">
                <tr>
                  <th>Cantidad / Precio</th>
                  <th>Acabados</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let object of productPriceList; let i = index;">
                  <td>
                    <div class="productPriceCant">
                      <div class="">
                        <mat-form-field appearance="legacy">
                          <mat-label>Cantidad Disponible</mat-label>
                          <input matInput placeholder="Cantidad Disponible" [(ngModel)]="object.quantity" (change)="autoSaveProductPrice(object);">
                        </mat-form-field>
                        <mat-form-field appearance="legacy">
                          <mat-label>Precio</mat-label>
                          <input matInput placeholder="Precio" [(ngModel)]="object.price" (change)="autoSaveProductPrice(object);">
                        </mat-form-field>
                      </div>
                      <div class="">
                        <button mat-icon-button color="accent" *ngIf="object.id != ''" (click)="deleteProductPrice(object);"><mat-icon>delete</mat-icon> </button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="boxAttributes">
                      <div class="">
                        <p class="mb-2" style="line-height: 1.5;"> <strong>Propiedades</strong> </p>
                        <mat-form-field appearance="legacy" style="width:80%;">
                          <mat-label>Madera</mat-label>
                          <input matInput placeholder="Madera" [(ngModel)]="object.wood" (change)="autoSaveProductPrice(object);">
                        </mat-form-field>
                        <mat-form-field appearance="legacy" style="width:80%;">
                          <mat-label>Capacidad</mat-label>
                          <input matInput placeholder="Capacidad" [(ngModel)]="object.capacity" (change)="autoSaveProductPrice(object);">
                        </mat-form-field>
                        <mat-form-field appearance="legacy" style="width:80%;">
                          <mat-label>Tejido</mat-label>
                          <input matInput placeholder="Tejido" [(ngModel)]="object.tissue" (change)="autoSaveProductPrice(object);">
                        </mat-form-field>
                        <mat-form-field appearance="legacy" style="width:80%;">
                          <mat-label>Respaldo</mat-label>
                          <input matInput placeholder="Respaldo" [(ngModel)]="object.backrest" (change)="autoSaveProductPrice(object);">
                        </mat-form-field>
                        <mat-form-field appearance="legacy" style="width:40%;">
                          <mat-label>Peso</mat-label>
                          <input matInput placeholder="Peso" [(ngModel)]="object.weight" (change)="autoSaveProductPrice(object);">
                        </mat-form-field>
                        <mat-form-field appearance="legacy" style="width:40%;">
                          <mat-label>Dimensiones</mat-label>
                          <input matInput placeholder="Dimensiones" [(ngModel)]="object.dimension" (change)="autoSaveProductPrice(object);">
                        </mat-form-field>

                        <mat-form-field appearance="legacy" style="width:80%;">
                          <mat-label>Observaciones</mat-label>
                          <textarea matInput placeholder="Observaciones" [(ngModel)]="object.observation"  (change)="autoSaveProductPrice(object);"></textarea>
                        </mat-form-field>

                      </div>
                      <div class="">
                        <p class="mb-2" style="line-height: 1.5;"> <strong>Barniz</strong> </p>
                        <mat-form-field appearance="legacy" style="width:80%;">
                          <mat-label>Barniz</mat-label>
                          <input matInput placeholder="Barniz" [(ngModel)]="object.varnish" (change)="autoSaveProductPrice(object);">
                        </mat-form-field>
                        <mat-slide-toggle [labelPosition]="'afther'" [(ngModel)]="object.isTexture"> Agregar <strong *ngIf="object.isTexture">textura</strong> <strong *ngIf="!object.isTexture">color</strong> </mat-slide-toggle>

                        <mat-form-field appearance="legacy" style="width:100%;" *ngIf="!object.isTexture">
                          <input matInput type="color" placeholder="Color" [(ngModel)]="object.texture" (change)="autoSaveProductPrice(object);">
                        </mat-form-field>
                        <app-upload-img-min [uploadCode]="'productPrice:upload'" [mFile]="object.image"  *ngIf="object.isTexture" (imageUploated)="autoSaveProductPrice(object);"></app-upload-img-min>

                        <p class="mb-2" style="line-height: 1.5;"> <strong>Tapiz</strong> </p>
                        <mat-form-field appearance="legacy" style="width:80%;">
                          <mat-label>Tapiz</mat-label>
                          <input matInput placeholder="Tapiz" [(ngModel)]="object.tapestry" (change)="autoSaveProductPrice(object);">
                        </mat-form-field>
                        <mat-slide-toggle [labelPosition]="'afther'" [(ngModel)]="object.istapestry"> Agregar <strong *ngIf="object.istapestry">textura</strong> <strong *ngIf="!object.istapestry">color</strong> </mat-slide-toggle>
                        <mat-form-field appearance="legacy" style="width:100%;" *ngIf="!object.istapestry">
                          <input matInput type="color" placeholder="Color" [(ngModel)]="object.tapestryimg" (change)="autoSaveProductPrice(object);">
                        </mat-form-field>
                        <app-upload-img-min [uploadCode]="'productPrice:upload'" [mFile]="object.imagetapestry"  *ngIf="object.istapestry" (imageUploated)="autoSaveProductPrice(object);"></app-upload-img-min>
                      </div>
                    </div>

                  </td>

                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-10 mt-3">
            <button mat-raised-button color="primary" style="width:100%;"  class="cardImgButtom" (click)="send(true);">GUARDAR</button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>


  </div>
</div>
