import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-footer',
  templateUrl: './client-footer.component.html',
  styleUrls: ['./client-footer.component.css']
})
export class ClientFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  goExternal(url){
    window.open(url, '_blank');
  }

}
