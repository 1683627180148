import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { LoadingService } from './../../directive/loading/loading.module';
import { SessionService } from './../../service/session/session.module';
import * as moment from 'moment';
moment.locale('es-us');

@Component({
  selector: 'app-client-order',
  templateUrl: './client-order.component.html',
  styleUrls: ['./client-order.component.css']
})
export class ClientOrderComponent implements OnInit {
  firstStepForm: FormGroup;

  shippingModel = {
    id:'',
    cp:'',
    street:'',
    colony:'',
    city:'',
    state:'',
    observations:'',
    delivery:'',
    sale:{
      id:''
    }
  }
  saleModel = {
    id:'',
    folio:'',
    clientName:'',
    clientSocialReason:'',
    clientRfc:'',
    clientPhone:'',
    clientEmail:'',
    platform:'web',
    typePayment:'',
    paymentId:'',
    discount:0,
    tax:0,
    delivery:0,
    subtotal:0,
    total:0,
    status:'Pendiente',
    terms: false,
    privacy:false,
    user:{
      id:0
    },
    shipping:this.shippingModel,
    saleProducts:[]
  };
  saleList = [];
  object = JSON.parse(JSON.stringify(this.saleModel));
  mexpago = {
    folioMexPago:'',
    monto:'',
    numeroTransaccion:'',
    pago:''
  }
  metadata = {
    status:'',
    phone:'',
    folio:'',
    msj:'',
    orderStatusSelected : {
      position:'1',
      status:'Nuevo',
      selected:false,
      date:'',
      description:''
    }
  }
  saleProductsList = [];

  order = {
    id:'',
    status:'',
    guide:''
  }
  orderStatus = [];
  statusList = [
    {
      position:'1',
      status:'Nuevo',
      selected:false,
      date:'',
      description:'',
      actived:false
    },{
      position:'2',
      status:'Confirmado',
      selected:false,
      date:'',
      description:'',
      actived:false
    },{
      position:'3',
      status:'En camino',
      selected:false,
      date:'',
      description:'',
      actived:false
    },{
      position:'4',
      status:'Entregado',
      selected:false,
      date:'',
      description:'',
      actived:false
    },
  ]
  constructor(
    protected session: SessionService,
    public loading: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
     public fb: FormBuilder
  ) {
    this.firstStepForm = fb.group({
      'phone': [null, Validators.compose([Validators.required])],
      'folio': [null, Validators.compose([Validators.required])],
    });
   }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      console.log(params['phone'])
      if (params['phone'] == 'evopayment') {
        this.activatedRoute.queryParams.subscribe(async(params) => {
          console.log("evopayment",params);
          this.object.id = params.sale_id;
          this.object.folio = params.folio;
          this.getSaleByEvopayment();
        });
      }else{
        if(params['phone'] != 'traking'){
          // console.log(params);
          this.metadata.phone = params['phone'];
          this.activatedRoute.queryParams.subscribe(async(params) => {
            // console.log(params);
            this.metadata.folio = params.folio;
            this.getSale(this.metadata.phone,this.metadata.folio);
          });
        }else{

        }
      }
    });
  }

  /**funcion para obtener las refervaciones por numero telefonico
  */
  getSale(phone,folio){
    this.loading.show(true,"Espere un momento...");
    this.session.postRequestAnonimus("sale:findByPhoneAndFolio",{phone:phone,folio:folio}).subscribe((data:any)=>{
      this.object = JSON.parse(JSON.stringify(data.object));
      console.log(":>:>:>:>:>:>:>:>",this.object);
      if(this.object.id == ''){
        this.metadata.msj = 'No hay resultados con los datos de su busqueda, verifique su información';
      }else{
        this.getOrder(this.object);
        this.getProductlist();
      }

      this.loading.hide();
    },error=>{
      console.log(error);
      this.metadata.msj = 'No hay resultados con los datos de su busqueda, verifique su información';
      this.loading.hide();
    })
  }

  /**función para seleccionar una reservación
  */
  selectedsale(object){
    this.object = JSON.parse(JSON.stringify(object));
    this.getProductlist();
    this.getOrder(this.object);
  }

  /**función para obtener reservacion por sale
  */
  getSaleByEvopayment(){
    this.loading.show(true,"Espere un momento...");
    this.session.postRequestAnonimus("sale:get",this.object).subscribe((data:any)=>{
      //actualizamos el pago
      this.object = data.object;
      this.getProductlist();
      let status = 'Pagado';
      this.session.postRequestAnonimus("sale:checkAndValidate",{
        id:this.object.id,
        status:status
      }).subscribe((data:any)=>{
        //actualizamos el pago
        this.object.status = data.object.status;
        this.getOrder(this.object);
        this.getProductlist();
        this.loading.hide();
      },error=>{
        console.log(error);
        this.loading.hide();
      })
    },error=>{
      console.log(error);
      this.loading.hide();
    })
  }
  /**funcionalidad para obetenr el producto
  */
  getProduct(object){
    this.session.postRequest("product:get",{id:object.product_id}).subscribe((data:any)=>{
      object.product = data.object;
      object.product.image = this.session.urlIMG+'uploads/'+object.product.image;
    },error=>{
      console.log(error);
    })
  }
  /**metodo para obetner los productos
  */
  getProductlist(){
    for(let i = 0; i < this.object.products.length; i++){
      this.getProduct(this.object.products[i]);
    }
    this.saleProductsList = this.object.products;
    console.log(this.saleProductsList);
  }

  /**Método para obtner la guia y los status de una venta
  */
  getOrder(sale){
    return new Promise((resolve,reject)=>{
      this.session.postRequestAnonimus("order:findBySale",{id:sale.id}).subscribe((data:any)=>{
        this.order = data.object
        //buscamos los status del pedido
        this.session.postRequestAnonimus("order:findAllStatus",{id:this.order.id}).subscribe((data:any)=>{
          this.orderStatus = data.object.instanceList;
          for(let i = 0; i < this.statusList.length; i++){
            for(let item of this.orderStatus){
              if(item.position == this.statusList[i].position){
                this.statusList[i].selected = true;
                this.statusList[i].date = item.date;
                this.statusList[i].description = item.description;
                this.statusList[i].actived = item.actived;

              }
            }
          }
        },error=>{
          reject(error);
        })
      },error=>{
        reject(error);
      })
    });
  }

  selectedOrderStatus (object){
    this.metadata.orderStatusSelected = JSON.parse(JSON.stringify(object));
  }

}
