<app-client-menu></app-client-menu>
<div class="row justify-content-center mt-2">
  <div class="col-md-11 col-12">
    <div class="row">
      <div class="col-md-3 col-12">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Gecapa</a></li>
          <li class="breadcrumb-item" aria-current="page">Seguimiento de pedido</li>
        </ol>
      </div>
    </div>
    <div class="row bg-light mb-4">
      <div class="row justify-content-center">
        <div class="col-12">
          <p>Ingresa tu número teléfonico y tu folio de compra para restrear tu pedido.</p> <br>
        </div>
        <div class="col-12">
          <div class="row" style="align-items: center;">
            <div class="col-6 col-md-4">
              <mat-form-field appearance="outline" style="width:100%">
                <mat-label>Teléfono</mat-label>
                <input matInput name="phone" [(ngModel)]="object.clientPhone" [formControl]="firstStepForm.controls['phone']" required>
                <mat-error *ngIf="firstStepForm.controls['phone'].hasError('required')">
                  Campo <strong>requerido</strong>.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-6 col-md-4">
              <mat-form-field appearance="outline" style="width:100%">
                <mat-label>Folio</mat-label>
                <input matInput name="folio" [(ngModel)]="object.folio" [formControl]="firstStepForm.controls['folio']" required>
                <mat-error *ngIf="firstStepForm.controls['folio'].hasError('required')">
                  Campo <strong>requerido</strong>.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
              <button mat-flat-button style="width:100%" color="primary" [disabled]="firstStepForm.invalid" (click)="getSale(object.clientPhone,object.folio);">Buscar</button>
            </div>
          </div>
        </div>
        <div class="col-10" *ngIf="object.id != ''">
          <h2 class="thank">¡ Gracias por tu compra !</h2>
        </div>
      </div>

      <div class="col-md-6 col-12" *ngIf="object.id != ''">
        <mat-card class="mt-3 mb-3">
          <div class="" *ngFor="let object of statusList">
            <span class="badge bg-warning text-dark" *ngIf="object.actived">{{object.status}}</span>
            <h3 class="mb-0"*ngIf="object.actived" style="color: #7f2346;">{{object.description}}</h3>
            <p *ngIf="object.actived">{{object.date | date:'dd-MM-yyyy HH:mm'}}</p>
          </div>

          <hr>
          <h4 class="mb-0">Detalle de envío</h4> <br>
          <table>
            <tr *ngFor="let object of statusList">
              <td valign="top" *ngIf="object.selected"><small>{{object.date | date:'dd-MM-yyyy HH:mm'}}</small></td>
              <td class="pl-2" *ngIf="object.selected">
                <p class="fs-4 mb-0" style="color: #343a40;" >{{object.status}}</p>
                <small>{{object.description}}</small>
              </td>
            </tr>
          </table>
        </mat-card>
      </div>
      <div class="col-md-6 col-12" *ngIf="object.id != ''">
        <mat-card class="mt-3 mb-3">
          <h3 class="mb-0">Detalle de compra</h3>
          <p style="color: #7f2346;">#{{object.folio}}</p>
          <hr>
          <table style="width:100%">
            <tr *ngFor="let object of saleProductsList">
              <td>
                <img src="{{object.product.image}}"  class="img-list" style="width:4rem">
              </td>
              <td class="pl-2">
                <p class="fs-7 mb-0">{{object.description}}</p>
              </td>
              <td class="pl-2">
                <p class="fs-7 mb-0">{{object.quantity}} @ {{object.unit_price | currency:'MXN':'symbol-narrow'}}</p>
              </td>
              <td class="pl-2">
                <p class="fs-7 mb-0">{{object.total | currency:'MXN':'symbol-narrow'}}</p>
              </td>
            </tr>
          </table>
          <hr>
          <table class="w-100 mt-3">
            <tbody>
              <tr>
                <th>
                  <h3>Subtotal</h3>
                </th>
                <td class="text-right">
                  <h3>{{(object.total - object.delivery)| currency:'MXN':'symbol-narrow'}}</h3>
                </td>
              </tr>
              <tr style="border-bottom:1px solid #000">
                <th>
                  <h3>Envío</h3>
                </th>
                <td class="text-right">
                  <h3 >{{object.delivery | currency:'MXN':'symbol-narrow'}}</h3>
                </td>
              </tr>
              <tr>
                <th>
                  <h2>Total</h2>
                </th>
                <td class="text-right">
                  <h2>{{object.total | currency:'MXN':'symbol-narrow'}}</h2>
                </td>
              </tr>
            </tbody>
          </table>
        </mat-card>
      </div>
      <div class="col-12" *ngIf="metadata.msj != '' && object.id == ''">
        <p class="msg">{{metadata.msj }}</p>
      </div>
    </div>
  </div>
</div>
<app-client-footer></app-client-footer>
