import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { LoginComponent } from '../../login/login.component';
import { SessionService } from '../../service/session/session.service';
import { ClientMenuService } from './client-menu.service';
import { Subject } from "rxjs";
import { debounceTime, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-client-menu',
  templateUrl: './client-menu.component.html',
  styleUrls: ['./client-menu.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class ClientMenuComponent implements OnInit {

  closeResult = '';
  shoppingcart = {
    total:0
  }
  categories = [];
  metadata = {
    username:'',
    login:false,
    showInputSearch:false,
    searchBoxInput: new Subject<string>(),
    showModal:false,
    totalProducts:0
  }
  searchProductList = [];
  productMin = {
    filter:'',
    max: 10,
    page: 1,
  }
  constructor(public dialog: MatDialog,private session:SessionService, public menuService:ClientMenuService) {
    if(this.session.getSession()){
      this.metadata.username = this.session.getSession().user.name;
      if(this.metadata.username)
        this.metadata.login = true;
    }
    this.metadata.searchBoxInput.pipe(debounceTime(700),
      switchMap(val => {
        if (val != '') {
          this.searchProductList = [];
          // this.productMin.filter = '';
        } else {
          this.searchProductList = [];
        }
        this.metadata.totalProducts = 0;
        this.getProducts();
        return val;
      })
    ).subscribe(()=>{});
   }

  /**Modal para login
  */
  login(){
    this.dialog.open(LoginComponent, {
      width: '36rem',
      // data: uuid
    }).afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          window.location.reload();
        }else{
        }
      }else{
      }
    });
  }

  ngOnInit(): void {
    this.shoppingcart = this.session.getShoppingCarTotal();
    this.session.shippingcartTotal.subscribe((value)=>{
      this.shoppingcart = value;
    })
    this.menuService.getCategorires().then((data:Array<any>)=>{
      this.categories = data;
    }).catch(e=>{
      console.log(e);
    })
  }

  async logOut(){
    await this.session.logout();
    window.location.reload();
  }

  search(){
    this.productMin.page = 1;
    this.metadata.searchBoxInput.next(this.productMin.filter);
  }

  /**funcionalidad para aparecer/desaprecer el input de buscar
  */

  toggleInputSearch(){
    this.metadata.showInputSearch = !this.metadata.showInputSearch;
    if(!this.metadata.showInputSearch){
      this.metadata.showModal = false;
      this.productMin.filter = '';
    }
  }

  /**función para obtener el listado de categorias
  */
  getProducts(){
    this.metadata.showModal = true;
    if(this.productMin.filter != ''){
      this.session.postRequest("product:searching",this.productMin).subscribe((data:any)=>{
        for(let i = 0; i < data.object.instanceList.length; i++){
          for(let j = 0; j < data.object.instanceList[i].images.length; j++){
            if(data.object.instanceList[i].images[j].image == data.object.instanceList[i].image){
              data.object.instanceList[i].images.splice(j,1);
              break;
            }
          }
          data.object.instanceList[i].image = this.session.urlIMG+'uploads/'+data.object.instanceList[i].image;
          data.object.instanceList[i].showImage = data.object.instanceList[i].image;
          for(let j = 0; j < data.object.instanceList[i].images.length; j++){
            data.object.instanceList[i].images[j].image =  this.session.urlIMG+'uploads/'+data.object.instanceList[i].images[j].image;
          }
        }
        this.searchProductList = data.object.instanceList;
        this.metadata.totalProducts = data.object.total;
      },error=>{
        console.log("error:product:searching",error);
      })
    }
  }

  closeModal(){
    this.metadata.showModal = false;
    this.productMin.filter = '';
    this.metadata.showInputSearch = false;
  }

  /**se agrega funcionalida para mostrar varias imaagnes
  */
  changeImgToShow(object){
    object.showImage = object.images[Math.floor((Math.random()*object.images.length))].image;
    return true;
  }
  /**se agrega funcionalida para mostrar la imagane de portada
  */
  ResetImgToShow(object){
    object.showImage = object.image;
    return true;
  }

}
