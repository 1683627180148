import { Component, OnInit, Input } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import { ClientAlertSessionComponent } from '../client-alert-session/client-alert-session.component';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../../login/login.component';

@Component({
  selector: 'app-client-favorite-btn',
  templateUrl: './client-favorite-btn.component.html',
  styleUrls: ['./client-favorite-btn.component.css']
})
export class ClientFavoriteBtnComponent implements OnInit {
  @Input() object = {
    id:'',
    like:false
  }
  metadata = {
    login:false
  }
  wishlistModel = {
    id:'',
    product:{
      id:''
    },
    user:{
      id:''
    }
  }
  wishlist = JSON.parse(JSON.stringify(this.wishlistModel));
  constructor(public session:SessionService,
    public loading:LoadingService,public dialog: MatDialog) {
    this.metadata.login = this.session.getLoggedIn();

   }

  ngOnInit(): void {
    if(this.metadata.login)
      this.getFav();
  }

  /**funcionalidad para saber sun un prodcutos esta en favoritos
  */
  getFav(){
    let aux = {
      id:'',
      product:{
        id:this.object.id
      },
      user:{
        id:this.session.getSession().user.id
      }
    }
    this.session.postRequest("wishlist:findByUserAndProduct",aux).subscribe((data:any)=>{
      if(data.object.id){
        this.object.like = true;
        this.wishlist = JSON.parse(JSON.stringify(data.object));
      }
    },error=>{
      console.log(error);
    })
  }

  /**funcionalidad para agregar un producto a favoritos
  */
  addFavorite(){
    if(this.metadata.login){
      if(this.object.like){
        this.loading.show(true,"Espere un momento...");
        this.session.postRequest("wishlist:delete",this.wishlist).subscribe((data)=>{
          this.object.like = false;
          this.wishlist = JSON.parse(JSON.stringify(this.wishlistModel));
          this.loading.hide();
        },error=>{
          console.log(error);
          this.loading.hide();
        })
      }else{
        this.wishlist.product.id = this.object.id;
        this.wishlist.user.id = this.session.getSession().user.id;
        this.loading.show(true,"Espere un momento...");
        this.session.postRequest("wishlist:update",this.wishlist).subscribe((data:any)=>{
          this.object.like = true;
          this.wishlist.id = data.object.id;
          this.loading.hide();
        },error=>{
          console.log(error);
          this.loading.hide();
        })
      }
    }else{
      this.dialog.open(ClientAlertSessionComponent, {
        width: '36rem',
        // data: uuid
      }).afterClosed().subscribe(result => {
        if(result != undefined ){
          if(result.transaction == 'ok'){
            this.dialog.open(LoginComponent, {
              width: '36rem',
              // data: uuid
            }).afterClosed().subscribe(result => {
              if(result != undefined ){
                if(result.transaction == 'ok'){
                  window.location.reload();
                }else{
                }
              }else{
              }
            });
          }else{
          }
        }else{
        }
      });
    }
  }

}
