<app-client-menu></app-client-menu>
<!-- TITULO CARRITO -->
<div class="row justify-content-center m-0" style="">
</div>
<div class="row justify-content-center m-0">
  <div class="col-11 col-md-10 col-lg-10 mt-5" style="text-aling:right;">
    <button mat-flat-button color="primary" class="btnGeneric" style="float: right;" (click)="backClicked();">REGRESAR</button>
  </div>
  <div class="col-11 col-md-10 col-lg-10">
    <h3 style="padding-top: 20px;">PEDIDO</h3>
  </div>
</div>
<!-- TABLAS DE CARRITO DE COMPRAS !-->

<div class="row justify-content-center m-0" style="padding: 20px 0px;">
  <div class="col-11 col-md-10 col-lg-10">
    <div class="row justify-content-start">
      <div class="col-12 col-md-6" style="margin-top: 10px; padding: 0px 5px;">
        <div style="padding: 10px; padding-top: 0px;">
          <mat-card style="background-color: #fdfdfd;">
            <mat-horizontal-stepper [linear]="true" #stepper>
              <mat-step [stepControl]="firstStepForm">
                <ng-template matStepLabel>Datos de envio</ng-template>
                <div class="row justify-content-start">
                  <p class="col-12 pb-1">Ingresa el correo y número telefonico de contacto.</p>
                  <mat-form-field appearance="outline" class="col-12 col-md-8">
                    <mat-label>Correo electrónico</mat-label>
                    <input matInput placeholder="Correo electrónico" name="mEmail_" [(ngModel)]="object.clientEmail" [formControl]="firstStepForm.controls['email']" required>
                    <mat-error *ngIf="firstStepForm.controls['email'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="col-12 col-md-8">
                    <mat-label>Télefono</mat-label>
                    <input matInput placeholder="Télefono" name="phone" [(ngModel)]="object.clientPhone" [formControl]="firstStepForm.controls['phone']" required>
                    <mat-error *ngIf="firstStepForm.controls['phone'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>

                  <p class="col-12 pb-1 pt-1">Ingrese los datos de envio</p>
                  <mat-form-field appearance="outline" class="col-12">
                    <mat-label>Nombre completo</mat-label>
                    <input matInput name="name" [(ngModel)]="object.clientName" [formControl]="firstStepForm.controls['name']" required>
                    <mat-error *ngIf="firstStepForm.controls['name'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="col-12">
                    <mat-label>Domicilio de entrega</mat-label>
                    <textarea matInput name="shippingStreet" [(ngModel)]="object.shipping.street" [formControl]="firstStepForm.controls['address']" required></textarea>
                    <mat-error *ngIf="firstStepForm.controls['address'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="col-6">
                    <mat-label>Colonia</mat-label>
                    <input matInput name="colony" [(ngModel)]="object.shipping.colony" [formControl]="firstStepForm.controls['colony']" required>
                    <mat-error *ngIf="firstStepForm.controls['colony'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="col-6">
                    <mat-label>CP</mat-label>
                    <input matInput name="cp" [(ngModel)]="object.shipping.cp" [formControl]="firstStepForm.controls['cp']" required>
                    <mat-error *ngIf="firstStepForm.controls['cp'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="col-6">
                    <mat-label>Ciudad ó Municipio</mat-label>
                    <input matInput name="city" [(ngModel)]="object.shipping.city" [formControl]="firstStepForm.controls['city']" required>
                    <mat-error *ngIf="firstStepForm.controls['city'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="col-6">
                    <mat-label>Estado</mat-label>
                    <input matInput name="state" [(ngModel)]="object.shipping.state" [formControl]="firstStepForm.controls['state']" required>
                    <mat-error *ngIf="firstStepForm.controls['state'].hasError('required')">
                      Campo <strong>requerido</strong>.
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="col-12">
                    <mat-label>Referencias de domicilio</mat-label>
                    <textarea matInput name="referencesShipping"  [(ngModel)]="object.shipping.observations"></textarea>
                  </mat-form-field>
                </div>
                <div class="row">
                  <div class="col-6"></div>
                  <button mat-button matStepperNext class="col-6 buttonPayment" >CONTINUAR <span class="material-icons">forward</span></button>
                </div>
              </mat-step>
              <mat-step [stepControl]="secondStepFrom">
                <ng-template matStepLabel>Envio</ng-template>
                <div class="row justify-content-start">
                  <p class="col-12 pb-1">Selecciona un método de envio.</p>

                  <div class="col-12">
                    <mat-radio-group aria-label="Select an option" color="warn" name="mSender" [(ngModel)]="metadata.sender"  [formControl]="secondStepFrom.controls['sender']" required (change)="deliverySelected();">
                      <mat-radio-button [value]="object.id" class="senders" *ngFor="let object of deliveryList">{{object.name}} <span class="senderPrice" *ngIf="!metadata.freeDelivery">{{(object.price)| currency:'MXN':'symbol-narrow'}}</span> <span class="senderPrice" *ngIf="metadata.freeDelivery">GRATIS</span>
                        <span style="display: block; color: #9E9E9E;">Tiempo de entrega aprox. {{object.time}} dias</span>
                      </mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="secondStepFrom.controls['sender'].hasError('required')">
                      El campo es <strong>requerido</strong>
                    </mat-error>
                  </div>
                </div>
                <div class="row">
                  <button mat-button matStepperPrevious class="col-6 buttonPaymentBack">ATRAS</button>
                  <button mat-button matStepperNext class="col-6 buttonPayment" [disabled]="secondStepFrom.invalid">CONTINUAR <span class="material-icons">forward</span></button>
                </div>
              </mat-step>
              <mat-step [stepControl]="thirdStepFrom">
                <ng-template matStepLabel>Método de pago</ng-template>
                <div class="row justify-content-start">
                  <p class="col-12 pb-1">Selecciona un método de pago.</p>

                  <div class="col-12">
                    <mat-radio-group aria-label="Select an option" color="warn" name="mpaymentMethod" [(ngModel)]="object.typePayment"  [formControl]="thirdStepFrom.controls['paymentMethod']" required  (ngModelChange)="changePaymentMethod();">
                      <mat-radio-button [value]="object.selected" class="senders" *ngFor="let object of paymentMethods"><img [src]="object.icon" style="width: 100px;">
                      </mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="thirdStepFrom.controls['paymentMethod'].hasError('required')">
                      El campo es <strong>requerido</strong>
                    </mat-error>
                  </div>
                </div>
                <div class="row">
                  <button mat-button matStepperPrevious class="col-6 buttonPaymentBack">ATRAS</button>
                  <div class="col-6" style="margin-top:30px;">
                    <!-- seccion de paypal -->
                    <div *ngIf="object.typePayment == 'PayPal'" class="col-12">
                      <div id="paypal-button-container"></div>
                    </div>
                    <!-- session de mexpago -->
                    <!-- <div class="" *ngIf="object.typePayment == 'Mexpago'">
                      <form #myForm ngForm  [action]="mexpagoModel.url" method="post">
                        <input type="hidden" name="monto" value="{{mexpagoModel.monto}}">
                        <input type="hidden" name="noTransaccion" value="{{mexpagoModel.noTransaccion}}">
                        <input type="hidden" name="llave" value="{{mexpagoModel.llave}}">
                        <input type="hidden" name="fecha" value="{{mexpagoModel.fecha}}">
                        <input type="hidden" name="articulos" value= "{{mexpagoModel.articulos}}">
                        <input type="hidden" name="precargaDatos" value= "{{mexpagoModel.precargaDatos}}">
                        <input type="hidden" name="enviarCorreo" value= "{{mexpagoModel.enviarCorreo}}">
                        <input type="hidden" name="infoComercio" value= "{{mexpagoModel.infoComercio}}">
                        <input type="hidden" name="lenguaje" value= "{{mexpagoModel.lenguaje}}">
                        <button mat-button matStepperNext class="col-12 buttonPayment" [disabled]="thirdStepFrom.invalid" (click)="sendToPay();">PAGAR</button>
                      </form>
                    </div> -->
                    <!-- seccion de evopayments -->
                    <div class="" *ngIf="object.typePayment == 'Tarjeta'">
                      <div id="embeddedEvoPayments">
                      </div>
                      <button mat-button matStepperNext class="col-12 buttonPayment" [disabled]="thirdStepFrom.invalid" (click)="sendToPay();">PAGAR</button>

                    </div>

                    <!-- <button mat-flat-button color="primary" class="buttonPayment" style="float: right;" (click)="createCheckout();">PAGAR </button> -->
                  </div>
                </div>
              </mat-step>
            </mat-horizontal-stepper>
          </mat-card>

        </div>
      </div>

      <div class="col-12 col-md-6" style="margin-top: 10px; padding: 0px 5px;">
        <mat-card >
          <div class="row justify-content-center">
            <div class="col-12">
              <ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
                <li style="margin-bottom: 15px;display: flex;" *ngFor="let object of saleProductsList">
                  <div class="imgTitle">
                    <span data-action="cart-can" class="badge rounded-circle" style="position: absolute; background: #60c56e; color: #fff; font-size: 0.7rem; top: 5px; left:5px;">{{object.quantity}}</span>
                    <div class="imgAvatar">
                      <img src="{{object.product.image}}" alt="{{object.product.name}}">
                    </div>
                  </div>
                  <div class="titlePayment">
                    {{object.description}}
                  </div>
                  <div class="priceTitle">
                    {{object.total | currency:'MXN':'symbol-narrow'}}
                  </div>
                </li>
              </ul>
              <hr>
            </div>
            <div class="col-6 col-md-4 col-lg-6" style="margin: 0px; padding: 0px 25px;">
              <ul style="list-style:none; padding: 0px; font-size: .8rem; color:#6f6f6f;">
                <li style="margin-bottom: 15px;">ENVÍO</li>
                <li style="margin-bottom: 15px; font-weight: bold;">TOTAL</li>
              </ul>
            </div>
            <div class="col-6 col-md-4 col-lg-6" style="margin: 0px; padding: 0px 15px; text-align: end;">
              <ul  style="list-style:none; padding: 0px;">
                <li style="margin-bottom: 15px;" *ngIf="metadata.sender != ''">{{object.delivery | currency:'MXN':'symbol-narrow'}}</li>
                <li style="margin-bottom: 15px;" *ngIf="metadata.sender == ''"><span style="font-size: 0.8rem;">seleccione un método de envio.</span></li>
                <li><h6 class="" style="font-size: 14pt;">{{object.total | currency:'MXN':'symbol-narrow'}}</h6></li>
              </ul>
            </div>
          </div>
        </mat-card>
      </div>
    </div>

  </div>
</div>

<app-client-footer></app-client-footer>
