<div class="row justify-content-end">
  <div class="col-1">
    <button mat-icon-button color="primary" style="position: absolute; top: -20px;" (click)="close(false)">
        <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
<div class="row text-center justify-content-center fade-in-image">
  <div class="col-11 fade-in-image" *ngIf="!metadata.showRegister">
    <h4 class="mt-3">Ingresar con mi cuenta</h4>
    <div class="row">
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email" name="mEmail" [(ngModel)]="object.email" [formControl]="loginForm.controls['username']" required>
        <mat-error *ngIf="loginForm.controls['username'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>Contraseña</mat-label>
        <input matInput placeholder="Contraseña" type="password" name="mEmail" [(ngModel)]="object.password" [formControl]="loginForm.controls['password']" required>
        <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="col-md-6 col-12 text-left">
        <mat-checkbox class="ml-1" name="remember_me" [(ngMode)]="object.remember_me">Recordarme</mat-checkbox>
      </div>
      <div class="col-md-6 col-12 text-right">
        <!-- <button mat-button>Olvidé mi contraseña</button> -->
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-8 col-12 d-grid gap-2">
        <button class="btn btn-danger" type="button" (click)="send();" [disabled]="loginForm.invalid">INGRESAR</button>
        <button class="btn btn-warning" type="button" (click)="changeView();">CREAR CUENTA</button>
      </div>
    </div>
  </div>
  <div class="col-11 fade-in-image" *ngIf="metadata.showRegister">
    <h4 class="mt-3">Crear tu cuenta</h4>
    <div class="row">
      <mat-form-field class="col-6" appearance="outline">
        <mat-label>Nombres</mat-label>
        <input matInput placeholder="Nombres" name="sname" [(ngModel)]="register.name" [formControl]="registerForm.controls['name']" required>
        <mat-error *ngIf="registerForm.controls['name'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-6" appearance="outline">
        <mat-label>Apellidos</mat-label>
        <input matInput placeholder="Apellidos" name="lastname" [(ngModel)]="register.lastname" [formControl]="registerForm.controls['lastname']" required>
        <mat-error *ngIf="registerForm.controls['lastname'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email" name="mEmail" [(ngModel)]="register.email" [formControl]="registerForm.controls['email']" required>
        <mat-error *ngIf="registerForm.controls['email'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>Telefono</mat-label>
        <input matInput placeholder="Telefono" name="phone"  [(ngModel)]="register.phone" [formControl]="registerForm.controls['phone']" required >
        <mat-error *ngIf="registerForm.controls['phone'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-6" appearance="outline">
        <mat-label>Contraseña</mat-label>
        <input matInput placeholder="Contraseña" type="password" name="passwordsd"  [(ngModel)]="register.password" [formControl]="registerForm.controls['password']" required >
        <mat-error *ngIf="registerForm.controls['password'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-6" appearance="outline">
        <mat-label>Contraseña</mat-label>
        <input matInput placeholder="Contraseña" type="password" name="password_confirmationsd" [(ngModel)]="register.password_confirmation" [formControl]="registerForm.controls['password_confirmation']" required>
        <mat-error *ngIf="registerForm.controls['password_confirmation'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-8 col-12 d-grid gap-2">
        <button class="btn btn-danger" type="button" (click)="changeView();">REGRESAR</button>
        <button class="btn btn-warning" type="button" (click)="createUser();" [disabled]="registerForm.invalid">CREAR CUENTA</button>
      </div>
    </div>
  </div>
  <div class="col-11">
    <div class="row">
      <div class="col-12">
        <h4 class="mt-3">Ingresar con redes sociales</h4>
        <br>
        <div class="btn-group pt-2" role="group" aria-label="Basic outlined example">
          <button type="button" class="btn btn-outline-primary" (click)="signInWithFacebook();">
            <img src="assets/img/client/btn-facebook.svg" style="width:2.5rem;">
          </button>
          <button type="button" class="btn btn-outline-primary" (click)="signInWithGoogle();">
            <img src="assets/img/client/btn-gmail.svg" style="width:2.5rem;">
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div clas></div>
