import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { CompressService } from '../../../compress/compress.service';

@Component({
  selector: 'app-admin-maker-update',
  templateUrl: './admin-maker-update.component.html',
  styleUrls: ['./admin-maker-update.component.css']
})
export class AdminMakerUpdateComponent implements OnInit {
  @ViewChild("imgGet", {static: false}) fileGet: ElementRef;


    complexForm:FormGroup;
    makerModel = {
      id:'',
      name:'',
      description:'',
      img:'',
      status:true
    }

    metadata = {
      ingles:false,
      img:{
        file: '',
        type: '',
        name: '',
      },
      uploadted:false
    }

    object = JSON.parse(JSON.stringify(this.makerModel));
    constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AdminMakerUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public compress:CompressService ) {
      this.complexForm = fb.group({
        'name': [null, Validators.required],
        'description': [null, Validators.required],
      })
    }

    ngOnInit() {
      if(this.data != 'new') {
        this.loadingService.wait();
         // cargamos los datos de la BD
         this.session.postRequest('maker:get',{id:this.data}).subscribe((data:any)=>{
           this.object = JSON.parse(JSON.stringify(data.object));
           if(this.object.img != ''){
             this.metadata.uploadted = true;
             this.metadata.img.name = this.object.img;
           }
           this.loadingService.hide();
         },
         (error)=>{
           this.loadingService.hide();
           console.log('Error: maker:get',error)
         })
      }
    }

    /**
     * funcion para guardar una unidades de medida
     */
    send(object){
      this.loadingService.show(true,'Guardando registro...');
      this.session.postRequest("maker:update",object).subscribe(
        (data:any) => {
          this.snackBar.open('El registro se guardo correctamente', '', {
            duration: 8000
          });
          this.loadingService.hide();
          this.close(true);
        },
        error => {
          console.log("Error: maker:update",error);
          this.loadingService.hide();
        }
      );
    }


    /**función para cargar una imagen
    */
    loadImg(){
      const fileUpload = this.fileGet.nativeElement;
      fileUpload.onchange = () => {
       this.metadata.img.name = fileUpload.files[0].name.replace(/ /g, "_");
       this.metadata.img.type = fileUpload.files[0].type;
       let file = fileUpload.files[0];
       console.log("FILE",fileUpload.files[0]);
       this.metadata.img.file = file;
       console.log("FILE",this.metadata.img);
      };
      fileUpload.click();
    }

    /** función para subir una imagen al servidor
    */
    async uploadImg(){
      try{
        this.loadingService.show(true,"Espere un momento...")
        const formData = new FormData();
        let fCompress:any = this.metadata.img.file;
        this.compress.compress(fCompress).then((fileData:any)=>{
          formData.append('file', fileData, this.metadata.img.name);
          this.session.uploadFile("maker:upload",formData).subscribe(async(data:any)=>{
            this.object.img = data.object.name;
            this.metadata.uploadted = true;
            this.snackBar.open('La imagen se subio con exito', 'Aceptar', {duration: 5000});
            this.loadingService.hide();
          },error=>{
            console.log("banner:upload",error);
            this.loadingService.hide();
          })
        }).catch(e=>{
          console.log(e);
          this.loadingService.hide();
        });

      }catch(e){
        console.log(e);
        this.loadingService.hide();
      }

    }

    /**función para quitar una iimagen
    */
    deleteImg(){
      this.metadata.img.file = '';
      this.metadata.img.name = '';
      this.metadata.uploadted = false;
    }

    close(status){
      let object = {
        transaction:'',
        code:'',
        object:{}
      };
      if(status){
        object.transaction = 'ok';
        object.object = this.object;
      }else{
        object.transaction = 'bad';
        object.code = 'maker:001';
      }
      this.dialogRef.close(object);
    }

}
