<div class="row pt-2">
  <div class="col-7">
    <img src="assets/img/client/logo.svg" style="width:13rem;" align="right">
  </div>
  <div class="col-5" align="right">
    <div class="btn-group pt-2" role="group" aria-label="Basic outlined example">
      <button type="button" class="btn btn-outline-primary" (click)="login()" style="display: flex; align-items: center; color: #7f2346;" *ngIf="!metadata.login">
        <img src="assets/img/client/icono-usuario.svg" style="width:1.2rem;">
      </button>
      <button type="button" class="btn btn-outline-primary" [matMenuTriggerFor]="menu" style="display: flex; align-items: center; color: #7f2346;" *ngIf="metadata.login">
        <img src="assets/img/client/icono-usuario.svg" style="width:1.2rem;">&nbsp;{{metadata.username}}
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item style="color: #f6f6f7;" routerLink="/client/favorite">Mis Favoritos</button>
        <button mat-menu-item style="color: #f6f6f7;" (click)="logOut();">Salir</button>
      </mat-menu>
      <button type="button" class="btn btn-outline-primary" style="color:#404040;" routerLink="/client/cart">
        <img src="assets/img/client/icono-compras.svg" style="width:1.2rem;"> {{shoppingcart.total}}
      </button>
      <button type="button" class="btn btn-outline-primary" style="color:#404040;">
        <img src="assets/img/client/icono-favorito.svg" style="width:1.2rem;"> 0
      </button>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark p-0 m-0">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo01" style="min-height: 60px;">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 fadeIN" *ngIf="!metadata.showInputSearch">
            <li class="nav-item">
              <a class="nav-link text-uppercase fw-light" aria-current="page" href="#" style="font-size: 9pt;">Inicio</a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link text-uppercase fw-light" routerLink="/client/product/all">Productos</a>
            </li> -->
            <li class="nav-item dropdown" *ngFor="let objectRoot of categories; let i = index;">
              <a class="nav-link text-uppercase fw-light" id="navbarDarkDropdownMenuLink{{i}}" style="font-size: 9pt;" data-bs-toggle="dropdown" aria-expanded="false" routerLink="/client/product/{{objectRoot.id}}">{{objectRoot.name}}</a>
              <ul class="dropdown-menu dropdown-obscuro" aria-labelledby="navbarDarkDropdownMenuLink{{i}}" *ngIf="objectRoot.categoryList.length > 0">
                <li *ngFor=" let object of objectRoot.categoryList; let j = index;" [ngClass]="{'dropend': !objectRoot.left, 'dropstart': objectRoot.left}">
                  <a class="dropdown-item dropdown-item-obscuro text-uppercase fw-light" [ngClass]="{'dropdown-toggle':object.categoryList.length > 0 ? true:false}"  id="navbarDarkDropdownSubMenuLink{{i}}{{j}}" data-bs-toggle="dropdown" aria-expanded="false" style="font-size: 9pt;" routerLink="/client/product/{{object.id}}">{{object.name}}</a>
                  <ul class="dropdown-menu dropdown-obscuro dropdown-sub-menu m-0" aria-labelledby="navbarDarkDropdownSubMenuLink{{i}}{{j}}" *ngIf="object.categoryList.length > 0">
                    <li *ngFor=" let object of object.categoryList; let k = index;" [ngClass]="{'dropendSub': !objectRoot.left, 'dropstartSub': objectRoot.left}">
                      <a class="dropdown-item dropdown-item-obscuro text-uppercase fw-light" [ngClass]="{'dropdown-toggle':object.categoryList.length > 0 ? true:false}"  id="navbarDarkDropdownSubMenuLink{{i}}{{j}}{{k}}" data-bs-toggle="dropdown" aria-expanded="false"  style="font-size: 9pt;" routerLink="/client/product/{{object.id}}">{{object.name}}</a>
                      <ul class="dropdown-menu dropdown-obscuro dropdown-sub-sub-menu m-0" aria-labelledby="navbarDarkDropdownSubMenuLink{{i}}{{j}}{{k}}" *ngIf="object.categoryList.length > 0">
                        <li *ngFor=" let object of object.categoryList">
                          <a class="dropdown-item dropdown-item-obscuro text-uppercase fw-light" style="font-size: 9pt;" routerLink="/client/product/{{object.id}}">{{object.name}}</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link text-uppercase fw-light" routerLink="/client/search/traking" style="font-size: 9pt;">Seguir mi pedido</a>
            </li>
          </ul>
          <form class="d-flex">
            <input class="form-control me-2 fadeIN" type="search" placeholder="Buscar" (keydown.enter)="$event.preventDefault();" aria-label="Search" name="searchingOK" *ngIf="metadata.showInputSearch" [(ngModel)]="productMin.filter" (ngModelChange)="search()">
            <button class="btn btn-danger pb-0 btn-search" (click)="toggleInputSearch();">
              <span class="material-icons" *ngIf="!metadata.showInputSearch">search</span>
              <span class="material-icons" *ngIf="metadata.showInputSearch">backspace</span>
            </button>
          </form>
        </div>
      </div>
    </nav>
  </div>
</div>

<div class="modalClass fadeIN" *ngIf="metadata.showModal">
  <div class="" style="width: 100%;">
    <div class="row justify-content-end">
      <div class="col-2 col-md-1">
        <button mat-icon-button aria-label="clear" matTooltip="Cerrar" (click)="closeModal();">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <div class="row justify-content-center mt-2">
      <div class="col-md-11 col-11">
        <div class="row mt-1">
          <div class="col-md-2 col-6">
            <p class="text-uppercase pt-3" style="color:#cac6c6;">{{metadata.totalProducts}} artículos</p>
          </div>
          <div class="col-md-2 col-6">
          </div>
          <div class="col-md-3 col-12 text-right">
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-12 col-md-3" style="padding-left:0px;">
            <span *ngIf="searchProductList.length == 0">NO HAY RESULTADOS PARA : <strong>{{productMin.filter}}</strong></span>
            <span *ngIf="searchProductList.length > 0">RESULTADOS PARA : <strong>{{productMin.filter}}</strong></span>
          </div>
          <div class="col-md-3 col-6 mb-3" *ngFor=" let object of searchProductList" style="padding-left:5px; padding-right:5px;">
            <mat-card class="text-center bg-light category productCard img-list" >
              <div class="banderaagotado" *ngIf="object.availability == 0">
                <p style="margin: 0px;">AGOTADO</p>
              </div>
              <div class="bandera" *ngIf="object.discount">
                <p style="margin: 0px;">DESCUENTO {{object.discountValue}}%</p>
              </div>
              <!--Se utiliza una variable para dar el efecto de hover, se tomo temporalmente la variable like pero
              se puede cambiar posteriormente
              --->
              <div mat-card-image class=" imgCard" (mouseover)="changeImgToShow(object)" (mouseout)="ResetImgToShow(object);">
                <img [src]="object.showImage" width="100%" >
              </div>
              <app-client-favorite-btn [object]="object"></app-client-favorite-btn>

              <div class="row justify-content-center colores" style="width: 100%; margin: 0px;">
                <ul class="color">
                  <li class="color" *ngFor="let object of object.attributesList" matTooltip="{{object.wood}}">
                    <div class="circulo" *ngIf="!object.isTexture" [ngStyle]="{'background': object.texture }"></div><p></p>
                    <div class="circulo" *ngIf="object.isTexture"> <img src="{{object.texture}}" class="circulo" style="position:absolute;"> </div><p></p>
                  </li>
                </ul>
              </div>
              <div class="row justify-content-center" style="width: 100%; margin: 0px;" routerLink="/client/product/details/{{object.id}}">
                <p class="producto-title">{{object.name}}</p>
              </div>
              <div class="row justify-content-center" style="width: 100%; margin: 0px;">
                <h3 class="precio">{{object.price | currency:'MXN':'symbol-narrow'}} MXN</h3>
              </div>
              <div class="btn-product d-grid">
                <!-- <button type="button" class="btn btn-success add-shopping text-uppercase fw-light btnGeneric" style="font-size: 9pt !important;" *ngIf="object.availability > 0" (click)="addToShoppingCard(object);">Agregar a carrito</button> -->
                <button class="btn btn-warning add-shopping text-uppercase fw-light mt-1 btnGeneric" routerLink="/client/product/details/{{object.id}}">Ver más</button>
              </div>
            </mat-card>
          </div>
          <div class="col-12" style="text-align: center;">
            <button name="button" class="btn btn-outline-danger mt-2 pl-3 pr-3 btnGeneric" *ngIf="metadata.getMoreProducts" (click)="showMoreProducts();">VER MÁS PRODUCTOS</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
