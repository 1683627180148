import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminCategoryUpdateComponent } from './admin-category-update/admin-category-update.component';

@Component({
  selector: 'app-admin-category',
  templateUrl: './admin-category.component.html',
  styleUrls: ['./admin-category.component.css']
})
export class AdminCategoryComponent implements OnInit {

  categoryMin = {
    max: 100,
    page: 1,
  }
  list = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  pageEvent: PageEvent;
  constructor(private session: SessionService,
    public loadding:LoadingService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getCategories();
  }

  /**función para obtener el listado de categorias
  */
  getCategories(){
    this.session.postRequest("category:listRoot",this.categoryMin).subscribe((data:any)=>{

      this.list = data.object.instanceList.map((item) =>{
        item.description = this.session.urlIMG+'category/'+item.description;
         return item;
      });
      this.paginator.total = data.object.total;
    },error=>{
      console.log("error:category:list",error);
    })
  }

  /** funcionalidad para crear una categoria
  */
  updateCategory(uuid,fatherId){
    const dialogRef = this.dialog.open(AdminCategoryUpdateComponent, {
      width: '50%',
      data: {uuid:uuid,fatherId:fatherId},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getCategories();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  loadChildCategories(object){
    object.opened = true;
    this.session.postRequest("category:findAllByCategory",{status:true,category:{id:object.id}}).subscribe((data:any)=>{
      object.categoryList = data.object.instanceList.map((item) =>{
        item.description = this.session.urlIMG+'category/'+item.description;
         return item;
      });
    },error=>{
      console.log("error:category:list",error);
    })
  }

  closeCategoryList(object){
    object.opened = false;
  }


  delete(object){
    object.opened = true;
    this.session.postRequest("category:delete",object).subscribe((data:any)=>{
      this.getCategories();
    },error=>{
      console.log("error:category:list",error);
    })
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.categoryMin.max = event.pageSize;
    this.categoryMin.page = event.pageIndex + 1;
    this.getCategories();
  }

}
