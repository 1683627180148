import { Injectable } from '@angular/core';
import { NgxPicaService, NgxPicaImageService,NgxPicaErrorInterface, NgxPicaResizeOptionsInterface } from '@digitalascetic/ngx-pica';

@Injectable({
  providedIn: 'root'
})
export class CompressService {

  picaOptions:NgxPicaResizeOptionsInterface = {
    exifOptions:{
      forceExifOrientation:false
    },
    quality:1,
    alpha:true,
    unsharpAmount:100,
    unsharpRadius:0.5,
    unsharpThreshold:0
  } ;

  constructor(private ngxPicaService: NgxPicaService, public picaImage:NgxPicaImageService) { }

  /**función para comprimir una imagen
  */
  compress(object:File):Promise<File>{
    return new Promise((resolve, reject)=>{
      //verificamos que el archivo sea una imagen
      if(this.picaImage.isImage(object)){
        // let fileSize = object.size;
        // let _MBFile = fileSize / 1000000;
        // console.log(_MBFile);
        var img = document.createElement('img');
        var blob = URL.createObjectURL(object);
        img.src = blob;
        img.onload = () => {
          var w = img.width;
          var h = img.height;
          let divide = Math.trunc(w /1024) // reducimos imagen a función de ajuste lineal y = 1024x;
          if(divide >= 2){
            this.ngxPicaService.resizeImage(object,w/divide,h/divide,this.picaOptions).subscribe((data) => {
              this.ngxPicaService.compressImage(data,0.8,this.picaOptions).subscribe((imageResized) => {
                  resolve(imageResized);
                },(error:NgxPicaErrorInterface)=>{
                  resolve(object);
                })
              },(error:NgxPicaErrorInterface)=>{
                resolve(object);
              })
          }else{
            this.ngxPicaService.compressImage(object,0.8,this.picaOptions).subscribe((imageResized) => {
                resolve(imageResized);
              },(error:NgxPicaErrorInterface)=>{
                resolve(object);
              })
          }

        }

      }else{
        resolve(object);
      }
    });
  }
}
