<div class="row title-modal">
  <div class="col-1">

  </div>
  <div class="col-6">
    <h3>Nueva mensajeria</h3>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-11">
    <div class="row mt-5">
      <div class="col-10">
        <mat-slide-toggle [labelPosition]="'before'" [(ngModel)]="object.status" style="float:right;">Activado</mat-slide-toggle>
      </div>
      <mat-form-field class="col-12 col-md-8" appearance="outline">
        <mat-label>&nbsp;Nombre</mat-label>
        <input matInput placeholder="Nombre" name="mName" [(ngModel)]="object.name" [formControl]="complexForm.controls['name']">
        <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-12 col-md-4" appearance="outline">
        <mat-label>&nbsp;Precio</mat-label>
        <input matInput placeholder="Precio" name="mPrice" [(ngModel)]="object.price" [formControl]="complexForm.controls['price']">
        <mat-error *ngIf="complexForm.controls['price'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-12 col-md-8" appearance="outline">
        <mat-label>&nbsp;Tiempo de envio en (dias)</mat-label>
        <input matInput placeholder="Tiempo de envio en (dias)" name="mtime" [(ngModel)]="object.time" [formControl]="complexForm.controls['time']">
        <mat-error *ngIf="complexForm.controls['time'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>

    </div>
    <div class="row justify-content-end mt-4">
      <div class="col-4">
        <button mat-flat-button color="warn" style="width:100%;" [disabled]=" complexForm.invalid "  (click)="send(object);">Guardar</button>
      </div>
    </div>
  </div>
</div>
