import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LoadingService } from './../../directive/loading/loading.module';
import { SessionService } from './../../service/session/session.module';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-client-evopayment',
  templateUrl: './client-evopayment.component.html',
  styleUrls: ['./client-evopayment.component.css']
})
export class ClientEvopaymentComponent implements OnInit {

  constructor(
    protected session: SessionService,
      public loading: LoadingService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      public dialog: MatDialog,
      public snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      console.log("::::::Status",params['status']);
      this.activatedRoute.queryParams.subscribe(async(params) => {
        console.log(":::::::params",params);
      });
    });
  }

}
