import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-send-and-back',
  templateUrl: './client-send-and-back.component.html',
  styleUrls: ['./client-send-and-back.component.css']
})
export class ClientSendAndBackComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
