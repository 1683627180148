import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, 	HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from '../session/session.service';

@Injectable({
  providedIn: 'root'
})
export class EvopaymentService {

  private _endpointSession="https://evopaymentsmexico.gateway.mastercard.com/api/rest/version/64/merchant/1194757/session";
  authParameter = {
    username:'',
    password:''
  }
  private sessionCheckout = {
    checkoutMode:'',
    merchant:'',
    result:'',
    session:{
      id:'',
      updateStatus:'',
      version:''
    },
    successIndicator:''
  }

  metadata = {
    library:false
  }

  constructor(protected http: HttpClient, private session:SessionService) { }

  /**funcionalidad para setear los parametros de hosted
  @param username - usuario de creación de sesión
  @param pass - password de para crear la session
  */
  setAuthParameters(username,pass){
    this.authParameter.username = username;
    this.authParameter.password = pass;
  }

  createSession(order,saleID){
    return new Promise((resolve,reject)=>{
      let object = {
        url:this._endpointSession,
        authParameter:{
          username:this.authParameter.username,
          password:this.authParameter.password
        },
        apiOperation:'INITIATE_CHECKOUT',
        checkoutMode:'WEBSITE',
        interaction:{
          operation:"PURCHASE",
          action:{
            "3DSecure":"MANDATORY",
          },
          displayControl:{
            billingAddress:'HIDE',
            paymentTerms:'SHOW_IF_SUPPORTED'
          },
          country:'MEX',
          locale:'es',
          cancelUrl:'https://casagecapa.com.mx/#/client/response/payment/error',
          returnUrl:'https://casagecapa.com.mx/#/client/search/evopayment'+'?folio='+order.id+'&sale_id='+saleID,
          timeoutUrl:'https://casagecapa.com.mx/#/client/response/payment/timeout'
        },
        // authentication:{
        //   transactionId:order.id
        // },
        // risk:{
        //   bypassMerchantRiskRules:'ALL'
        // },
        // transaction:{
        //   id:order.id
        // },
        // authentication:{
        //   channel:'PAYER_BROWSER'
        // },
        order:order
      }
      this.session.postRequest("evopayment:createSession",object).subscribe((data:any)=>{
        this.sessionCheckout = data.object;
        console.log(this.sessionCheckout);
        resolve(data);
      },error=>{
        console.log(error);
        reject(error);
      })
    });
    // var basicAuthString = btoa(this.authParameter.username+':'+this.authParameter.password);
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type':  'application/json',
    //     'Authorization': 'Basic ' + basicAuthString,
    //     'Accept': '*/*'
    //   })
    // };
    //
    // return Observable.create(observer => {
    //   this.http.post(this._endpointSession, {"apiOperation":"CREATE_CHECKOUT_SESSION"}, httpOptions)
    //     .subscribe((response: Response) => {
    //       let answer = response;
    //       console.log(answer);
    //       observer.next(answer);
    //       observer.complete();
    //     }, error => {
    //       console.log(error);
    //       observer.error(error);
    //     });
    // });
  }

  getSessionID(){
    return this.sessionCheckout.session.id;
  }

  /**funcionalidad para cargar la libreria de evopayment*/
  loadScript(folio){
    return new Promise((resolve,reject)=>{
      var scripts = document.getElementsByTagName("script")
      for (var i = 0; i < scripts.length; ++i) {
          if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
              this.metadata.library = true;
          }
      }

      if (!this.metadata.library) {
          var dynamicScripts = ["https://evopaymentsmexico.gateway.mastercard.com/static/checkout/checkout.min.js"];
          for (var i = 0; i < dynamicScripts.length; i++) {
              let node = document.createElement('script');
              node.src = dynamicScripts [i];
              node.type = 'text/javascript';
              node.async = false;
              node.charset = 'utf-8';
              node.setAttribute('data-error', 'https://casagecapa.com.mx/#/client/response/payment/error');
              node.setAttribute('data-complete', 'https://casagecapa.com.mx/#/client/search/evopayment'+'?folio='+folio);
              document.getElementsByTagName('head')[0].appendChild(node);
              resolve(true);
          }
      }else{
        resolve(true);
      }
    });
  }
}
