
<div class="row pt-2">
  <div class="col-7">
    <img src="assets/img/client/logo.svg" style="width:13rem;" align="right">
  </div>
</div>
<div class="row">
  <div class="col-12">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container-fluid" style="justify-content: flex-end !important;">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation" style="border: none;justify-content: flex-end;">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a aria-current="page" routerLink="/admin/sales" [ngClass]="metadata.currentUrl == '/admin/sales' ? 'nav-link text-uppercase fw-light aActive':'nav-link text-uppercase fw-light anotActive'" >PEDIDOS</a>
            </li>
            <li class="nav-item">
              <a routerLink="/admin/product" [ngClass]="metadata.currentUrl == '/admin/product' ? 'nav-link text-uppercase fw-light aActive':'nav-link text-uppercase fw-light anotActive'">PRODUCTO</a>
            </li>
            <li class="nav-item">
              <a routerLink="/admin/banner" [ngClass]="metadata.currentUrl == '/admin/banner' ? 'nav-link text-uppercase fw-light aActive':'nav-link text-uppercase fw-light anotActive'">BANER</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle fw-light" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                CATÁLOGOS
              </a>
              <ul class="dropdown-menu navbar-dark bg-dark" aria-labelledby="navbarDropdown">
                <li><a class="fw-light nav-link" routerLink="/admin/category">Categorías</a></li>
                <li><a class="fw-light nav-link" routerLink="/admin/delivery">Mensajería</a></li>
                <li><a class="fw-light nav-link" routerLink="/admin/maker">Marcas</a></li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link text-uppercase fw-light" (click)="logout();" style="cursor:pointer;">SALIR</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</div>
