import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { StorageTool } from '../../service/session/storage.tool';
import { MatDialog } from '@angular/material/dialog';
import { ClientProductsShoopingComponent} from '../client-product/client-products-shooping/client-products-shooping.component';
import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';


@Component({
  selector: 'app-client-home',
  templateUrl: './client-home.component.html',
  styleUrls: ['./client-home.component.css']
})
export class ClientHomeComponent implements OnInit {
  bannerMin = {
    max: 100,
    page: 1,
  }
  categoryMin = {
    max: 200,
    page: 1,
  }
  productMin ={
    status: true,
    outstanding:true,
    max:32,
    page:1
  }
  banners = [];
  categoryList = [];
  products = [];
  galleryOptions: NgxGalleryOptions[] = [
    {
        width: '100%',
        height: '30rem',
        thumbnails: false ,
        preview: false ,
        imageAnimation: NgxGalleryAnimation.Fade,
        imageAutoPlay: true,
        imageAutoPlayPauseOnHover: true,
        imageAutoPlayInterval:5000
    },
    // max-width 800
    {
        breakpoint: 800,
        width: '100%',
        height: '600px',
    },
    // max-width 400
    {
        breakpoint: 400,
        preview: false
    }
  ];
  galleryImages: NgxGalleryImage[] = [];
  metadata = {
    login:false
  }
  constructor(public session:SessionService,
    public loading:LoadingService,
    private sanitizer: DomSanitizer,
    public storage:StorageTool,
    public dialog: MatDialog
  ) {
    this.metadata.login = this.session.getLoggedIn();
  }

  ngOnInit(): void {
    this.getProducts();
    this.getBanners();
    this.getCategories();
  }

  /**función para obtener el listado de banners
  */
  getBanners(){
    this.session.postRequestAnonimus("banner:list",this.bannerMin).subscribe((data:any)=>{
      for(let i = 0 ; i < data.object.instanceList.length; i++ ){
        data.object.instanceList[i].image = this.session.urlIMG + 'banners/'+data.object.instanceList[i].image;
      }
      this.banners = data.object.instanceList;
      let imgAux = [];
      for(let item of this.banners){
        imgAux.push({
          small:item.image,
          medium:item.image,
          big:item.image,
        });
      }
      this.galleryImages = imgAux;
    },error=>{
      console.log("error:banner:list",error);
    });
  }

  getCategories(){
    this.session.postRequestAnonimus("category:listRoot",this.categoryMin).subscribe((data:any)=>{
      for(let i = 0; i < data.object.instanceList.length; i++){
        data.object.instanceList[i].description = this.sanitizer.bypassSecurityTrustResourceUrl(this.session.urlIMG + 'category/'+data.object.instanceList[i].description);
      }
      this.categoryList = data.object.instanceList;
      // const LONGITUD_PEDAZOS = 3; // Partir en arreglo de 3
      // for (let i = 0; i < data.object.instanceList.length; i += LONGITUD_PEDAZOS) {
      //   let pedazo = data.object.instanceList.slice(i, i + LONGITUD_PEDAZOS);
      //   this.categoryList.push(pedazo);
      // }
      // console.log(this.categoryList);
    },error=>{
      console.log("error:category:list",error);
    })
  }

  getProducts(){
    this.loading.show(true,"Espere un momento...")
    this.session.postRequestAnonimus("product:findAllByStatusAndOutstanding",this.productMin).subscribe((data:any)=>{
      // console.log(data);
      for(let i = 0; i < data.object.instanceList.length; i++){

        for(let j = 0; j < data.object.instanceList[i].images.length; j++){
          if(data.object.instanceList[i].images[j].image == data.object.instanceList[i].image){
            data.object.instanceList[i].images.splice(j,1);
            break;
          }
        }

        data.object.instanceList[i].image =  this.sanitizer.bypassSecurityTrustResourceUrl(this.session.urlIMG+'uploads/'+data.object.instanceList[i].image);
        data.object.instanceList[i].showImage = data.object.instanceList[i].image;
        for(let j = 0; j < data.object.instanceList[i].images.length; j++){
          data.object.instanceList[i].images[j].image =  this.sanitizer.bypassSecurityTrustResourceUrl(this.session.urlIMG+'uploads/'+data.object.instanceList[i].images[j].image);
        }
      }
      this.products = [];
      const LONGITUD_PEDAZOS = 4; // Partir en arreglo de 3
      for (let i = 0; i < data.object.instanceList.length; i += LONGITUD_PEDAZOS) {
        let pedazo = data.object.instanceList.slice(i, i + LONGITUD_PEDAZOS);
        this.products.push(pedazo);
      }

      this.loading.hide();
    },error=>{
      console.log("product:findAllByStatusAndOutstanding:error",error);
      this.loading.hide();
    })
  }

  /**funcionalidad para agregar un articulo al carrito.
  */
  addToShoppingCard(object){
    this.storage.getAkeyFromStorage('shoppingCart').then((data:any)=>{
      let shoppingCart = [];
      if(data){
        shoppingCart = data;
      }
      let productPriceAux:any = {};
      if(object.product_prices.length == 0){
      }else{
        productPriceAux = object.product_prices[0];
        object.price = productPriceAux.price;
      }
      // this.object.attributesList = [];
      shoppingCart.push({
        quantity:1,
        product:object,
        productPrice:productPriceAux
      });
      localStorage.setItem('carritoLength', shoppingCart.length.toString());
      this.storage.saveAKeyOnTheStorage('shoppingCart',shoppingCart).then(()=>{
        this.session.setShoppingCartTotal(shoppingCart.length);

        const dialogRef = this.dialog.open(ClientProductsShoopingComponent, {
          width: '50%',
          data: object,
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != undefined ){
            if(result.transaction == 'ok'){
              // El modal se cerro con objeto
            }else{
              // El modal se cerro sin objeto
              // this.snackBar.open('No se guardo ningun registro', '', {
              //   duration: 1000
              // });
            }
          }else{
            // El modal se cerro sin seleccionar algo, dandole click fuera
            // this.snackBar.open('No se guardo ningun registro', '', {
            //   duration: 1000
            // });
          }
        });

      }).catch(e=>{
        console.log(e);
      })

    }).catch(e=>{
      console.log(e);
    })
  }

  /**se agrega funcionalida para mostrar varias imaagnes
  */
  changeImgToShow(object){
    object.showImage = object.images[Math.floor((Math.random()*object.images.length))].image;
    return true;
  }
  /**se agrega funcionalida para mostrar la imagane de portada
  */
  ResetImgToShow(object){
    object.showImage = object.image;
    return true;
  }

}
