import { Component, OnInit,ViewEncapsulation, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import { StorageTool } from '../../service/session/storage.tool';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ClientProductsShoopingComponent} from '../client-product/client-products-shooping/client-products-shooping.component';


@Component({
  selector: 'app-client-product',
  templateUrl: './client-product.component.html',
  styleUrls: ['./client-product.component.css']
})
export class ClientProductComponent implements OnInit {
  panelOpenState=false;
  categoryModel = {
    id:'',
    name:'',
    description:'',
    image:'',
    status:true,
    en_name:'',
    en_description:'',
    category:{
      id:null
    }
  }
  productMin = {
    status: true,
    order:'',
    max:19,
    page:1,
    category:{
      id:''
    }
  };
  list = [];
  metadata ={
    language:'spanish',
    getMoreProducts:true,
    totalProducts:0,
    showCategories:false
  }
  changeText = false;
  imagesCategoy = [];

  categoryMin = {
    max: 100,
    page: 1,
  }
  categories = [];
  categoryTree = [];
  mainCategory = JSON.parse(JSON.stringify(this.categoryModel));
  constructor(public dialog: MatDialog, private session:SessionService, public loading:LoadingService, public storage: StorageTool,
    private router:Router,
    public activatedRoute:ActivatedRoute,
  ) {
    //cragamos el idioma configurado
    this.storage.getAkeyFromStorage('spanish').then((data:any)=>{
      if(data){
        this.metadata.language = data;
      }
    })
  }


  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async(params: Params)=>{
      if(params['id'] == 'all'){
        this.categoryTree = [];
        this.list = [];
        this.metadata.showCategories = false;
        this.productMin.page = 1;
        this.productMin.category.id = '';
        await this.getCategories();
        this.getProducts();
      }else{
        await this.getCategories();
        this.list = [];
        this.categorySelected(params['id']);
        this.categoryTree = [];
        this.metadata.showCategories = true;
        await this.buildCategoryTree({id:params['id']});
        this.mainCategory = this.categoryTree.pop();
      }
    },error=>{
      console.log("activatedRoute:error",error);
    })
  }

  getProducts(){
    if(this.productMin.category.id == ''){
      this.loading.show(true,"Espere un momento...")
      this.session.postRequest("product:findAllByStatus",this.productMin).subscribe((data:any)=>{
        // console.log(data);
        for(let i = 0; i < data.object.instanceList.length; i++){
          for(let j = 0; j < data.object.instanceList[i].images.length; j++){
            if(data.object.instanceList[i].images[j].image == data.object.instanceList[i].image){
              data.object.instanceList[i].images.splice(j,1);
              break;
            }
          }
          data.object.instanceList[i].image = this.session.urlIMG+'uploads/'+data.object.instanceList[i].image;
          data.object.instanceList[i].showImage = data.object.instanceList[i].image;
          for(let j = 0; j < data.object.instanceList[i].images.length; j++){
            data.object.instanceList[i].images[j].image =  this.session.urlIMG+'uploads/'+data.object.instanceList[i].images[j].image;
          }
        }
        this.list = this.list.concat(data.object.instanceList);
        if(data.object.instanceList.length == 0){
          this.metadata.getMoreProducts = false;
        }else{
          this.metadata.getMoreProducts = true;
        }
        this.metadata.totalProducts = data.object.total;
        this.loading.hide();
      },error=>{
        console.log("product:findAllByStatus:error",error);
        this.loading.hide();
      })
    }else{
      this.loading.show(true,"Espere un momento...")
      this.session.postRequest("product:findAllByCategory",this.productMin).subscribe((data:any)=>{
        for(let i = 0; i < data.object.instanceList.length; i++){
          for(let j = 0; j < data.object.instanceList[i].images.length; j++){
            if(data.object.instanceList[i].images[j].image == data.object.instanceList[i].image){
              data.object.instanceList[i].images.splice(j,1);
              break;
            }
          }
          data.object.instanceList[i].image = this.session.urlIMG+'uploads/'+data.object.instanceList[i].image;
          data.object.instanceList[i].showImage = data.object.instanceList[i].image;
          for(let j = 0; j < data.object.instanceList[i].images.length; j++){
            data.object.instanceList[i].images[j].image =  this.session.urlIMG+'uploads/'+data.object.instanceList[i].images[j].image;
          }
        }
        this.list = this.list.concat(data.object.instanceList);
        if(data.object.instanceList.length == 0){
          this.metadata.getMoreProducts = false;
        }else{
          this.metadata.getMoreProducts = true;
        }
        this.metadata.totalProducts = data.object.total;
        this.loading.hide();
      },error=>{
        console.log("product:findAllByStatus:error",error);
        this.loading.hide();
      })
    }
  }

  /**función para obetner mas productos
  */
  showMoreProducts(){
    if(this.metadata.getMoreProducts){
      this.productMin.page++;
      this.metadata.getMoreProducts = false;
      this.getProducts();
    }
  }


  /**función para obtener el listado de categorias
  */
  getCategories(){
    return new Promise((resolve,reject)=>{
      this.session.postRequest("category:list",this.categoryMin).subscribe(async(data:any)=>{
        this.categories = data.object.instanceList;
        for(let item of this.categories){
          for(let i = 0; i < this.imagesCategoy.length; i++){
            if(item.name == this.imagesCategoy[i].name){
              this.imagesCategoy[i].category = item;
            }
          }
        }
        resolve(true);
      },error=>{
        console.log("error:category:list",error);
        reject(error);
      })
    })
  }

  /**función para seleccionar una categoria y cargar sus prodcutos
  */
  categorySelected(categoryId){
    this.productMin.page = 1;
    this.list = [];
    for(let i=0; i < this.imagesCategoy.length; i++){
      this.imagesCategoy[i].seleted = false;
      if(this.imagesCategoy[i].category.id == categoryId){
        this.imagesCategoy[i].seleted = true;
      }
    }
    this.productMin.category.id = categoryId;
    this.getProducts();
  }

  categorySelec(){
    this.productMin.page = 1;
    this.list = [];
    this.getProducts();
  }

  /*funcionalidad para aplicar ordenamiento a los productos
  */
  ordeProducts(){
    this.list = [];
    this.productMin.page = 1;
    this.getProducts();
  }


  /**funcionalidad para agregar un articulo al carrito.
  */
  addToShoppingCard(object){
    this.storage.getAkeyFromStorage('shoppingCart').then((data:any)=>{
      let shoppingCart = [];
      if(data){
        shoppingCart = data;
      }
      let productPriceAux:any = {};
      if(object.product_prices.length == 0){
      }else{
        productPriceAux = object.product_prices[0];
        object.price = productPriceAux.price;
      }
      // this.object.attributesList = [];
      shoppingCart.push({
        quantity:1,
        product:object,
        productPrice:productPriceAux
      });
      localStorage.setItem('carritoLength', shoppingCart.length.toString());
      this.storage.saveAKeyOnTheStorage('shoppingCart',shoppingCart).then(()=>{
        this.session.setShoppingCartTotal(shoppingCart.length);

        const dialogRef = this.dialog.open(ClientProductsShoopingComponent, {
          width: '50%',
          data: object,
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != undefined ){
            if(result.transaction == 'ok'){
              // El modal se cerro con objeto
            }else{
              // El modal se cerro sin objeto
              // this.snackBar.open('No se guardo ningun registro', '', {
              //   duration: 1000
              // });
            }
          }else{
            // El modal se cerro sin seleccionar algo, dandole click fuera
            // this.snackBar.open('No se guardo ningun registro', '', {
            //   duration: 1000
            // });
          }
        });

      }).catch(e=>{
        console.log(e);
      })

    }).catch(e=>{
      console.log(e);
    })
  }


  /**se agrega funcionalida para mostrar varias imaagnes
  */
  changeImgToShow(object){
    object.showImage = object.images[Math.floor((Math.random()*object.images.length))].image;
    return true;
  }
  /**se agrega funcionalida para mostrar la imagane de portada
  */
  ResetImgToShow(object){
    object.showImage = object.image;
    return true;
  }

  /**funcionalidad para crear el arbol de camino de la categoria
  */
  async buildCategoryTree(category){
    return new Promise(async(resolve,reject)=>{
      try{
        if(category.id == '' || category.id == null){
           resolve(category);
        }else{
          category = await this.getCategory(category);
          await this.buildCategoryTree(category.category);
          this.categoryTree.push(category);
          resolve(category);
        }
      }catch(e){
        console.log(e);
      }
    });

  }


  getCategory(object){
    return new Promise((resolve,reject)=>{
      this.session.postRequestAnonimus("category:get",object).subscribe((data:any)=>{
        resolve(data.object);
      },error=>{
        reject(error);
      });
    });
  }
}
