<app-client-menu></app-client-menu>

<div class="row justify-content-center">
  <div class="col-8 col-11">
    <h1>Envíos y devoluciones</h1>
    <h2>Su paquete de envío</h2>
    <p>
      Los paquetes generalmente se envían dentro de los 3 días posteriores a la recepción del pago y se envían a través de <strong>Estafeta</strong>, o   <strong>FedEx</strong> con seguimiento y entrega con firma.
    </p>
    <p>
      Los gastos de envío incluyen gastos de manipulación y embalaje, así como los gastos de envío. Las tarifas de manejo son fijas, mientras que las tarifas de transporte varían de acuerdo con el peso total del envío. Le recomendamos que agrupe sus artículos en un solo pedido. No podemos agrupar dos pedidos distintos colocados por separado, y las tarifas de envío se aplicarán a cada uno de ellos. Su paquete será enviado a su propio riesgo, pero se toma especial cuidado para proteger los objetos frágiles.
    </p>
    <p>
      Las cajas son de tamaño adecuado a su producto y sus artículos están bien protegidos.
    </p>

    <h2>Políticas de envío y entrega:</h2>
    <p>Las compras realizadas en tienda física <strong>no incluyen envío</strong>. </p>

    <ul>
      <li> Los envíos dentro de la Zona Metropolitana de Oaxaca se  cotizan  con  una fletera externa a nosotros dependiendo el lugar de destino. Para cotizar envíos fuera de Oaxaca es necesario comunicarse vía telefónica o WhatsApp al teléfono (951) 464 12 26</li>
      <li>Una vez realizada y aceptada su compra, nuestro staff le proporcionara una guía de rastreo generada por una compañía de paquetería externa para poder hacer rastreo de su envío.</li>
      <li>La compañía de paquetería externa tiene la obligación de entregar  el  pedido  del  cliente  en  la planta baja de su domicilio. En caso de que el cliente requiera ayuda del staff de entrega para subir e instalar el o los productos, el cliente asume los costos de este servicio con la paquetería y será responsabilidad del cliente cualquier percance, o daño que pueda surgir en el o los productos durante las maniobras de instalación de este.</li>
      <li>Es importante que la persona que va a recibir los productos comprados via online se encuentre presente el día de la entrega. En caso de que no se encuentre nadie en el domicilio, se reagendará una segunda  visita  y  el  cliente  pagará  los  gastos  incurridos  de  la misma.</li>
      <li>Es responsabilidad del cliente en relación a la entrega del producto, asegurar que su producto se haya entregado en perfectas condiciones sin daño alguno <strong>ANTES</strong> de firmar de  conformidad  y  recibido.  Si el  cliente  firma  antes  de desemplayar y validar su producto la <strong>* garantía no aplicará</strong>.</li>
    </ul>

    <h2>Políticas de devolución</h2>
    <h3>Devoluciones</h3>
    <ul>
      <li>Solicite la devolución dentro de los 2 días naturales siguientes a la fecha de entrega, <strong>aplica exclusivamente para los casos de productos con defecto de fábrica</strong>.</li>
      <li>El o los productos deberán estar en las mismas condiciones en las que fueron entregados al cliente. En el empaque original para que usted garantice su retorno en perfecto estado.</li>
      <li>Los gastos de devolución <strong>corren por cuenta del cliente</strong>.</li>
      <li>Para solicitar una devolución, nuestro cliente deberá enviar un correo dentro del plazo establecido a jefedepiso@casagecapa.com.mx con número de pedido, fecha de compra, fotos del defecto y justificación de la devolución.</li>
    </ul>
    <h2>Proceso de devolución</h2>
    <p><strong>CASA GECAPA</strong> hará la devolución del importe pagado por el cliente por el producto adquirido siempre y cuando cumpla los requisitos de defecto de fabricación.</p>
  </div>
</div>
<app-client-footer></app-client-footer>
