import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-client-alert-session',
  templateUrl: './client-alert-session.component.html',
  styleUrls: ['./client-alert-session.component.css']
})
export class ClientAlertSessionComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ClientAlertSessionComponent>) { }

  ngOnInit(): void {
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = {};
    }else{
      object.transaction = 'bad';
      object.code = 'fav:001';
    }
    this.dialogRef.close(object);
  }

}
