import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './service/session/authentication.guard';

import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminDeliveryComponent } from './admin/admin-delivery/admin-delivery.component';
import { AdminCategoryComponent } from './admin/admin-category/admin-category.component';
import { AdminBannerComponent } from './admin/admin-banner/admin-banner.component';
import { AdminMakerComponent } from './admin/admin-maker/admin-maker.component';
import { AdminProductComponent } from './admin/admin-product/admin-product.component';
import { AdminProductUpdateComponent } from './admin/admin-product/admin-product-update/admin-product-update.component';
import { AdminSalesComponent } from './admin/admin-sales/admin-sales.component';

import { ClientHomeComponent } from './client/client-home/client-home.component';
import { ClientProductComponent } from './client/client-product/client-product.component';
import { ClientShoppingCartComponent } from './client/client-shopping-cart/client-shopping-cart.component';
import { DetailsProductComponent } from './client/client-product/details-product/details-product.component';
import { ClientOrderComponent } from './client/client-order/client-order.component';
import { ClientPaymentComponent } from './client/client-payment/client-payment.component';
import { ClientPrivacyComponent } from './client/client-privacy/client-privacy.component';
import { ClientFavoriteComponent } from './client/client-favorite/client-favorite.component';
import { ClientSendAndBackComponent } from './client/client-send-and-back/client-send-and-back.component';
import { ClientEvopaymentComponent } from './client/client-evopayment/client-evopayment.component';

const routes: Routes = [
  { path: 'client/home', component: ClientHomeComponent , canActivate:[AuthenticationGuard], data:{access: ['ANONYMOUS']} },
  { path: '', pathMatch: 'full', redirectTo: 'client/home'},
  { path: 'client/product/:id', component:ClientProductComponent, canActivate:[AuthenticationGuard], data:{access: ['ANONYMOUS']} },
  { path: 'client/product/details/:id', component:DetailsProductComponent, canActivate:[AuthenticationGuard], data:{access: ['ANONYMOUS']} },
  { path: 'client/cart', component:ClientShoppingCartComponent, canActivate:[AuthenticationGuard], data:{access: ['ANONYMOUS']} },
  { path: 'client/search/:phone', component:ClientOrderComponent, canActivate:[AuthenticationGuard], data:{access: ['ANONYMOUS']} },
  { path: 'client/payment', component:ClientPaymentComponent, canActivate:[AuthenticationGuard], data:{access: ['ANONYMOUS']} },
  { path: 'client/politica_y_aviso_privacidad', component:ClientPrivacyComponent, canActivate:[AuthenticationGuard], data:{access: ['ANONYMOUS']} },
  { path: 'client/envios_devoluciones', component:ClientSendAndBackComponent, canActivate:[AuthenticationGuard], data:{access: ['ANONYMOUS']} },
  { path: 'client/favorite', canActivate:[AuthenticationGuard], data:{access: ['CLIENTE']} , component:ClientFavoriteComponent },
  { path: 'client/response/payment/:status', component:ClientEvopaymentComponent },

  { path:'admin/login', component: AdminLoginComponent },
  { path:'admin/dashboard', component: AdminDashboardComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']}  },
  { path:'admin/delivery', component: AdminDeliveryComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/category', component: AdminCategoryComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/banner', component: AdminBannerComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/maker', component: AdminMakerComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/product', component: AdminProductComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/product/update/:id', component: AdminProductUpdateComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/sales', component: AdminSalesComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash:true,scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
