<app-client-menu></app-client-menu>
<div class="row justify-content-center">
  <div class="col-12 col-md-10">
    <app-client-favorite-btn [object]="object"></app-client-favorite-btn>
  </div>
</div>
<div class="row mt-4 mb-3 justify-content-center">
  <div class="col-md-10 col-12">
    <div class="row ">
      <div class="col-md-7 col-12" style="min-height: 40rem;">
        <div class="banderaAgotadoMin" *ngIf="object.availability == 0">
          <p style="margin: 0px;">AGOTADO</p>
        </div>
        <div class="banderaMin" *ngIf="object.discount">
          <p style="margin: 0px;">DESCUENTO {{object.discountValue}}%</p>
        </div>
        <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
      </div>
      <div class="col-md-5 col-12">
        <p class="fs-4 text-center mt-4 text-uppercase">{{object.name}} </p>
        <h4 class="text-bold" *ngIf="!object.multiplePrice" >
          <del style="color:#75938d; display: block;" *ngIf="object.discount">{{metadata.unitPriceDiscount | currency:'MXN':'symbol-narrow'}} MXN</del>
          <span style="color:#243431;font-size: 1.5rem;">{{metadata.unitPrice | currency:'MXN':'symbol-narrow'}} MXN &nbsp;</span>
        </h4>
        <p class="mt-3">
          {{object.description}}
        </p>
        <div class="row mt-4">
          <div class="col-12" *ngIf="object.additional_data != ''">
            <p style="color:#7a7a7a; font-size:.7rem; margin: 0px; text-transform: uppercase; font-weight: 500;">Información adicional</p>
            <ul style=" color: #7a7a7a; font-size: .7rem; padding-left: 15px;">
              <li *ngFor="let object of additionalDataArray" style="list-style-type:none;">
                <strong>{{object.name}}:&nbsp;</strong>{{object.value}}
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <h3 *ngIf="object.multiplePrice">Características disponibles</h3>
          <!-- capcidad -->
          <div class="col-12 row" style="display: flex;" *ngIf="object.multiplePrice && capacityList.length > 0">
            <div class="col-12">
              <p style="margin: 0;font-size: small;margin-top: 15px; color: #7f2346;"><strong>Capacidad / Tamaño</strong></p>
            </div>
            <div class="col-4" *ngFor="let object of capacityList">
              <div class="contentRadioBtn" (click)="selectAProperty(object,object.capacity,'capacity')">
                <div class="contentRadioBtnRadio" *ngIf="object.enabled">
                  <i class="fa fa-check-circle-o" aria-hidden="true" *ngIf="object.selected"></i>
                  <i class="fa fa-circle-o" aria-hidden="true" *ngIf="!object.selected"></i>
                </div>
                <div class="contentRadioBtnRadioNegative" *ngIf="!object.enabled">
                  <i class="fa fa-minus-circle" aria-hidden="true"></i>
                </div>
                <div class="contentRadioBtnName">
                  {{object.capacity}}
                </div>
              </div>
            </div>
          </div>

          <!-- respaldo -->
          <div class="col-12 row" style="display: flex;" *ngIf="object.multiplePrice && backrestList.length > 0">
            <div class="col-12">
              <p style="margin: 0;font-size: small;margin-top: 15px; color: #7f2346;"><strong>Respaldo</strong></p>
            </div>
            <div class="col-4" *ngFor="let object of backrestList">
              <div class="contentRadioBtn" (click)="selectAProperty(object,object.backrest,'backrest')">
                <div class="contentRadioBtnRadio" *ngIf="object.enabled">
                  <i class="fa fa-check-circle-o" aria-hidden="true" *ngIf="object.selected"></i>
                  <i class="fa fa-circle-o" aria-hidden="true" *ngIf="!object.selected"></i>
                </div>
                <div class="contentRadioBtnRadioNegative" *ngIf="!object.enabled">
                  <i class="fa fa-minus-circle" aria-hidden="true"></i>
                </div>
                <div class="contentRadioBtnName">
                  {{object.backrest}}
                </div>
              </div>
            </div>
          </div>

          <!-- dimenciones -->
          <div class="col-12 row" style="display: flex;" *ngIf="object.multiplePrice && dimensionList.length > 0">
            <div class="col-12">
              <p style="margin: 0;font-size: small;margin-top: 15px; color: #7f2346;"><strong>Dimensiones</strong></p>
            </div>
            <div class="col-4" *ngFor="let object of dimensionList">
              <div class="contentRadioBtn" (click)="selectAProperty(object,object.dimension,'dimension')">
                <div class="contentRadioBtnRadio" *ngIf="object.enabled">
                  <i class="fa fa-check-circle-o" aria-hidden="true" *ngIf="object.selected"></i>
                  <i class="fa fa-circle-o" aria-hidden="true" *ngIf="!object.selected"></i>
                </div>
                <div class="contentRadioBtnRadioNegative" *ngIf="!object.enabled">
                  <i class="fa fa-minus-circle" aria-hidden="true"></i>
                </div>
                <div class="contentRadioBtnName">
                  {{object.dimension}}
                </div>
              </div>
            </div>
          </div>

          <!-- madera -->
          <div class="col-12 row" style="display: flex;" *ngIf="object.multiplePrice && woodList.length > 0">
            <div class="col-12">
              <p style="margin: 0;font-size: small;margin-top: 15px; color: #7f2346;"><strong>Madera</strong></p>
            </div>
            <div class="col-4" *ngFor="let object of woodList">
              <div class="contentRadioBtn" (click)="selectAProperty(object,object.wood,'wood')">
                <div class="contentRadioBtnRadio" *ngIf="object.enabled">
                  <i class="fa fa-check-circle-o" aria-hidden="true" *ngIf="object.selected"></i>
                  <i class="fa fa-circle-o" aria-hidden="true" *ngIf="!object.selected"></i>
                </div>
                <div class="contentRadioBtnRadioNegative" *ngIf="!object.enabled">
                  <i class="fa fa-minus-circle" aria-hidden="true"></i>
                </div>
                <div class="contentRadioBtnName">
                  {{object.wood}}
                </div>
              </div>
            </div>
          </div>

          <!-- tapiz -->
          <div class="col-12 row" style="display: flex;" *ngIf="object.multiplePrice && tapestryList.length > 0">
            <div class="col-12">
              <p style="margin: 0;font-size: small;margin-top: 15px; color: #7f2346;"><strong>Tapiz</strong></p>
            </div>
            <div class="col-4" *ngFor="let object of tapestryList">
              <div class="contentRadioBtn" (click)="selectAProperty(object,object.tapestry,'tapestry')">
                <div class="contentRadioBtnRadio" *ngIf="object.enabled">
                  <i class="fa fa-check-circle-o" aria-hidden="true" *ngIf="object.selected"></i>
                  <i class="fa fa-circle-o" aria-hidden="true" *ngIf="!object.selected"></i>
                </div>
                <div class="contentRadioBtnRadioNegative" *ngIf="!object.enabled">
                  <i class="fa fa-minus-circle" aria-hidden="true"></i>
                </div>
                <div class="contentRadioBtnName" matTooltip="{{object.tapestry}}">
                  <div *ngIf="!object.istapestry" [ngStyle]="{'background': object.tapestryimg }" class="cuadrado"></div>
                  <div *ngIf="object.istapestry"class="cuadrado"> <img src="{{object.tapestryimg}}" > </div>
                </div>
              </div>
            </div>
          </div>

          <!-- tejido -->
          <div class="col-12 row" style="display: flex;" *ngIf="object.multiplePrice && tissueList.length > 0">
            <div class="col-12">
              <p style="margin: 0;font-size: small;margin-top: 15px; color: #7f2346;"><strong>Tejido</strong></p>
            </div>
            <div class="col-4" *ngFor="let object of tissueList">
              <div class="contentRadioBtn" (click)="selectAProperty(object,object.tissue,'tissue')">
                <div class="contentRadioBtnRadio" *ngIf="object.enabled">
                  <i class="fa fa-check-circle-o" aria-hidden="true" *ngIf="object.selected"></i>
                  <i class="fa fa-circle-o" aria-hidden="true" *ngIf="!object.selected"></i>
                </div>
                <div class="contentRadioBtnRadioNegative" *ngIf="!object.enabled">
                  <i class="fa fa-minus-circle" aria-hidden="true"></i>
                </div>
                <div class="contentRadioBtnName">
                  {{object.tissue}}
                </div>
              </div>
            </div>
          </div>


          <!-- barniz -->
          <div class="col-12 row" style="display: flex;" *ngIf="object.multiplePrice && varnishList.length > 0">
            <div class="col-12">
              <p style="margin: 0;font-size: small;margin-top: 15px; color: #7f2346;"><strong>Barniz</strong></p>
            </div>
            <div class="col-4" *ngFor="let object of varnishList">
              <div class="contentRadioBtn" (click)="selectAProperty(object,object.varnish,'varnish')">
                <div class="contentRadioBtnRadio" *ngIf="object.enabled">
                  <i class="fa fa-check-circle-o" aria-hidden="true" *ngIf="object.selected"></i>
                  <i class="fa fa-circle-o" aria-hidden="true" *ngIf="!object.selected"></i>
                </div>
                <div class="contentRadioBtnRadioNegative" *ngIf="!object.enabled">
                  <i class="fa fa-minus-circle" aria-hidden="true"></i>
                </div>
                <div class="contentRadioBtnName" matTooltip="{{object.varnish}}">
                  <div *ngIf="!object.isTexture" [ngStyle]="{'background': object.texture }" class="cuadrado"></div>
                  <div *ngIf="object.isTexture"class="cuadrado"> <img src="{{object.texture}}" > </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" *ngIf="object.multiplePrice">
            <button mat-button color="primary" class="btnGeneric" matTooltip="Limpiar" (click)="resetProperties();" style="float: right;">Seleccionar otras propiedades</button>
          </div>

          <div class="col-12 mt-4" style="text-align: start;" *ngIf="object.multiplePrice && metadata.showCarritoBTN">
            <hr>
            <h3 class="m-0"> Precio: <del style="color:#75938d;" *ngIf="object.discount">{{metadata.unitPriceDiscount | currency:'MXN':'symbol-narrow'}}</del> &nbsp; <span style="color:#243431;">{{metadata.unitPrice | currency:'MXN':'symbol-narrow'}} &nbsp;</span> </h3>
            <span style="font-size: 9pt; color: #cc4648;">{{object.availability}} Disponibles</span>
          </div>
          <div class="col-12 col-md-8 form-group content-select mt-4" style="display: flex;" *ngIf="object.availability > 0;">
            <label for="exampleFormControlSelect1" style="margin-top: 4px; font-size: .7rem;">CANTIDAD</label>
            <select class="custom-select" name="quantity" [(ngModel)]="metadata.quantity" style="font-size: .8rem; margin-left:5px;">
              <option *ngFor="let object of fakeQuantity " [value]="object">{{object}}</option>
            </select>
            <i></i>
          </div>

          <div class="col-10 d-grid">
            <button type="button" class="btn btn-success add-shopping text-uppercase fw-light btnGeneric" (click)="addProductToShoopingCart();"  *ngIf="object.availability > 0 && metadata.showCarritoBTN">Agregar a carrito</button>
            <button type="button" name="button" class="btn btn-dark add-shopping text-uppercase btnGeneric" style="width: 100%;"  *ngIf="object.availability == 0">AGOTADO</button>

          </div>
          <div class="col-2">
            <!-- <button mat-icon-button color="warn" aria-label="Example icon button with a heart icon">
              <mat-icon>favorite</mat-icon>
            </button> -->
          </div>


        </div>
        <div class="row mt-4 justify-content-center">
          <div class="col-11 bg-light p-2">
            <div class="col-12" *ngIf="object.observation != ''">
              <p style="color:#7a7a7a; font-size:.7rem;"> <strong>Nota: </strong> {{object.observation}}</p>
            </div>
            <!-- <div class="col-12 " *ngIf="object.freeDelivery">
              <img src="assets/img/client/icono-envio.svg" style="width:2rem;"> <p style="color:#7a7a7a; font-size:.7rem;" >* ENVIO GRATIS </p>
            </div> -->
            <!-- <p class="mt-2 fw-light">Envío gratuito estándar para pedidos a partir de $1090.00 MXN <br>
              Entrega estimada el día</p> -->
              <div class="btn-group pt-2 mt-2" role="group" aria-label="Basic outlined example">
                <!-- <button type="button" class="btn btn-outline-dark p-0" style="border-color: transparent!important;">
                  <img src="assets/img/client/amex-gris.svg" style="width:4rem;">
                </button> -->
                <button type="button" class="btn btn-outline-dark p-0" style="border-color: transparent!important;">
                  <img src="assets/img/client/master-card.svg" style="width:4rem;">
                </button>
                <!-- <button type="button" class="btn btn-outline-dark p-0" style="border-color: transparent!important;">
                  <img src="assets/img/client/oxxo-gris.svg" style="width:4rem;">
                </button>
                <button type="button" class="btn btn-outline-dark p-0" style="border-color: transparent!important;">
                  <img src="assets/img/client/paypal-gris.svg" style="width:4rem;">
                </button> -->
                <button type="button" class="btn btn-outline-dark p-0" style="border-color: transparent!important;">
                  <img src="assets/img/client/visa-gris.svg" style="width:4rem;">
                </button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br>
<section class="text-center mt-5 mb-5" *ngIf="productLastSee.length > 0">
  <p class="fs-4 text-center fw-light mt-4 text-uppercase mb-4">ÚLTIMOS VISTOS</p>
  <div class="picsum-img-wrapper">
    <div class="row">
      <div class="col-md-3 col-6 mt-2" *ngFor=" let object of productLastSee">
        <mat-card class="text-center bg-light category productCard" >
          <div class="banderaagotado" *ngIf="object.availability == 0">
            <p style="margin: 0px;">AGOTADO</p>
          </div>
          <div class="bandera" *ngIf="object.discount">
            <p style="margin: 0px;">DESCUENTO {{object.discountValue}}%</p>
          </div>
          <div mat-card-image class=" imgCard" (mouseover)="changeImgToShow(object)" (mouseout)="ResetImgToShow(object);">
            <img [src]="object.showImage" width="100%" routerLink="/client/product/details/{{object.id}}">
          </div>
          <app-client-favorite-btn [object]="object"></app-client-favorite-btn>
          <div class="row justify-content-center colores" style="width: 100%; margin: 0px;">
            <ul class="color">
              <li class="color" *ngFor="let object of object.attributesList" matTooltip="{{object.wood}}">
                <div class="circulo" *ngIf="!object.isTexture" [ngStyle]="{'background': object.texture }"></div><p></p>
                <div class="circulo" *ngIf="object.isTexture"> <img src="{{object.texture}}" class="circulo" style="position:absolute;"> </div><p></p>
              </li>
            </ul>
          </div>
          <div class="row justify-content-center" style="width: 100%; margin: 0px;" routerLink="/client/product/details/{{object.id}}">
            <p class="producto-title">{{object.name}}</p>
          </div>
          <div class="row justify-content-center" style="width: 100%; margin: 0px;">
            <h3 class="precio">{{object.price | currency:'MXN':'symbol-narrow'}} MXN</h3>
          </div>
          <div class="btn-product">
            <!-- <button type="button" class="btn btn-success add-shopping text-uppercase fw-light btnGeneric" *ngIf="object.availability > 0" (click)="addToShoppingCard(object)">Agregar a carrito</button> -->
            <button type="button" class="btn btn-warning add-shopping text-uppercase fw-light mt-1 btnGeneric" routerLink="/client/product/details/{{object.id}}">Ver más</button>
          </div>
        </mat-card>
      </div>
    </div>

  </div>
  <!-- <button type="button" class="btn btn-outline-danger mt-2 pl-3 pr-3 btnGeneric" routerLink="/client/product">Ver Más</button> -->
</section>
<br>
<section class="text-center mt-5 mb-5">
  <p class="fs-4 text-center fw-light mt-4 text-uppercase mb-4">Productos relacionados </p>
  <div class="picsum-img-wrapper">
    <div class="row">
      <div class="col-md-3 col-6 mt-2" *ngFor=" let object of products">
        <mat-card class="text-center bg-light category productCard" >
          <div class="banderaagotado" *ngIf="object.availability == 0">
            <p style="margin: 0px;">AGOTADO</p>
          </div>
          <div class="bandera" *ngIf="object.discount">
            <p style="margin: 0px;">DESCUENTO {{object.discountValue}}%</p>
          </div>
          <div mat-card-image class=" imgCard" (mouseover)="changeImgToShow(object)" (mouseout)="ResetImgToShow(object);">
            <img [src]="object.showImage" width="100%" routerLink="/client/product/details/{{object.id}}" >
          </div>
          <app-client-favorite-btn [object]="object"></app-client-favorite-btn>
          <div class="row justify-content-center colores" style="width: 100%; margin: 0px;">
            <ul class="color">
              <li class="color" *ngFor="let object of object.attributesList" matTooltip="{{object.wood}}">
                <div class="circulo" *ngIf="!object.isTexture" [ngStyle]="{'background': object.texture }"></div><p></p>
                <div class="circulo" *ngIf="object.isTexture"> <img src="{{object.texture}}" class="circulo" style="position:absolute;"> </div><p></p>
              </li>
            </ul>
          </div>
          <div class="row justify-content-center" style="width: 100%; margin: 0px;" routerLink="/client/product/details/{{object.id}}">
            <p class="producto-title">{{object.name}}</p>
          </div>
          <div class="row justify-content-center" style="width: 100%; margin: 0px;">
            <h3 class="precio">{{object.price | currency:'MXN':'symbol-narrow'}} MXN</h3>
          </div>
          <div class="btn-product">
            <!-- <button type="button" class="btn btn-success add-shopping text-uppercase fw-light btnGeneric" *ngIf="object.availability > 0" (click)="addToShoppingCard(object)">Agregar a carrito</button> -->
            <button type="button" class="btn btn-warning add-shopping text-uppercase fw-light mt-1 btnGeneric" routerLink="/client/product/details/{{object.id}}">Ver más</button>
          </div>
        </mat-card>
      </div>
    </div>

  </div>
  <!-- <button type="button" class="btn btn-outline-danger mt-2 pl-3 pr-3 btnGeneric" routerLink="/client/product">Ver Más</button> -->
</section>
<app-client-footer></app-client-footer>
