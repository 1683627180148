import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import { StorageTool } from '../../service/session/storage.tool';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-client-favorite',
  templateUrl: './client-favorite.component.html',
  styleUrls: ['./client-favorite.component.css']
})
export class ClientFavoriteComponent implements OnInit {
  wishListMin = {
    user:{
      id:''
    },
    max:19,
    page:1
  }
  list = [];
  metadata = {
    language:'',
    getMoreProducts:true,
    totalProducts:true
  }
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  pageEvent: PageEvent;
  constructor(public dialog: MatDialog, private session:SessionService, public loading:LoadingService, public storage: StorageTool,
    private router:Router,
    public activatedRoute:ActivatedRoute
  ) {
    this.storage.getAkeyFromStorage('spanish').then((data:any)=>{
      if(data){
        this.metadata.language = data;
      }
    })
  }

  ngOnInit(): void {
    this.wishListMin.user.id = this.session.getSession().user.id;
    this.getProducts();
  }

  /**funcionalidad para obtener prooductos en la lista de deseos
  */
  getProducts(){
    this.loading.show(true,"Espere un momento...");
    this.session.postRequest("wishlist:findAllByUser",this.wishListMin).subscribe((data:any)=>{
      if(data.object.instanceList.length == 0){
        this.metadata.getMoreProducts = false;
      }else{
        this.metadata.getMoreProducts = true;
      }
      for(let i = 0; i < data.object.instanceList.length; i++){
        for(let j = 0; j < data.object.instanceList[i].product.images.length; j++){
          if(data.object.instanceList[i].product.images[j].image == data.object.instanceList[i].product.image){
            data.object.instanceList[i].product.images.splice(j,1);
            break;
          }
        }
        data.object.instanceList[i].product.image = this.session.urlIMG+'uploads/'+data.object.instanceList[i].product.image;
        data.object.instanceList[i].product.showImage = data.object.instanceList[i].product.image;
        for(let j = 0; j < data.object.instanceList[i].product.images.length; j++){
          data.object.instanceList[i].product.images[j].image =  this.session.urlIMG+'uploads/'+data.object.instanceList[i].product.images[j].image;
        }
      }
      this.list = data.object.instanceList;
      this.metadata.totalProducts = data.object.total;
      this.paginator.total = data.object.total;
      this.loading.hide();
    },error=>{
      console.log(error);
      this.loading.hide();
    })
  }

  /**función para obetner mas productos
  */
  showMoreProducts(){
    if(this.metadata.getMoreProducts){
      this.wishListMin.page++;
      this.metadata.getMoreProducts = false;
      this.getProducts();
    }
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.wishListMin.max = event.pageSize;
    this.wishListMin.page = event.pageIndex + 1;
    this.getProducts();
  }

  delete(object){
    this.loading.show(true,"Espere un momento...");
    this.session.postRequest("wishlist:delete",object).subscribe((data)=>{
      for(let i = 0; i < this.list.length; i++){
        if(this.list[i].id == object.id){
          this.list.splice(i,1);
        }
      }
      this.loading.hide();
    },error=>{
      console.log(error);
      this.loading.hide();
    })
  }

}
