import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { SessionService } from './../service/session/session.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from './../directive/loading/loading.service';
import { StorageTool } from './../service/session/storage.tool';
import { Router } from '@angular/router';
import { SocialAuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  registerForm: FormGroup;


  metadata = {
    showRegister:false
  }

  objectModel = {
    email:"",
    password:"",
    remember_me:true
  }


  registerModel = {
    id:'',
    name:'',
    lastname:'',
    email:'',
    phone:'',
    password:'',
    password_confirmation:'',
    role:'CLIENTE'
  }

  object =  JSON.parse(JSON.stringify(this.objectModel));
  register = JSON.parse(JSON.stringify(this.registerModel));


  constructor(
    public fb: FormBuilder,
    private session: SessionService,
    private snackBar: MatSnackBar,
    public loadingService:LoadingService,
    private router:Router,
    private storage:StorageTool,
    private authService: SocialAuthService,
    public dialogRef: MatDialogRef<LoginComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
      this.loginForm = fb.group({
        // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
        'username': [null, Validators.required],
        'password': [null, Validators.compose([Validators.required])],
      });

      this.registerForm = fb.group({
        // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
        'name': [null, Validators.required],
        'lastname': [null, Validators.compose([Validators.required])],
        'email': [null, Validators.required],
        'phone': [null, Validators.required],
        'password': [null, Validators.required],
        'password_confirmation': [null, Validators.required],
      });
    }

    ngOnInit(): void {
    }

    /**funcionalidad para cambiar la vista entre login y registro
    */
    changeView(){
      this.metadata.showRegister = !this.metadata.showRegister;
      if(this.metadata.showRegister){
        this.register =  JSON.parse(JSON.stringify(this.registerModel));
      }else{
        this.object =  JSON.parse(JSON.stringify(this.objectModel));
      }
    }


    send() {
      this.loadingService.show(true,'Iniciando sesion');
      // Mandamos a invocar el servicio de login
      this.session.login(this.object).then((data:any)=> {
        if (data.transaction === 'ok') {
          // this.snackBar.open(data.message, 'Aceptar', {duration: 5000});
          if(this.object.remember_me){
            this.storage.saveAKeyOnTheStorage("object",this.object);
          }
          this.close(true);
          this.loadingService.hide();
          //logica para determinar a donde se loguea el usuario
          // let role = this.session.getRoles();
          // switch(role[0]){
          //   case 'ADMINISTRATOR':
          //     this.router.navigate(['/admin/sales']);
          //     this.snackBar.open('Bienvenido', 'Aceptar', {duration: 5000});
          //   break;
          //   default:
          //     this.snackBar.open('Lamentablemente aun no tenemos un acceso para tu usuario...', 'Error', {duration: 5000});
          //   break;
          // }
        } else {
          this.loadingService.hide();
          this.snackBar.open('Usuario ó Contraseña incorrectos', 'Error', {duration: 5000});
          console.log('Usuario o Contraseña incorrectos')
        }
      }).catch((error)=>{
        this.loadingService.hide();
        this.snackBar.open('Usuario ó Contraseña incorrectos', 'Error', {duration: 5000});
        console.log('Error: ', error);
      });
    }

    createUser(){
      this.loadingService.show(true,'Estamos creando el usuario espere un momento.');
      this.session.postRequestAnonimus("auth:create",this.register).subscribe((data:any)=>{
        if (data.transaction === 'ok') {
          this.snackBar.open("Inicio de sesion exitoso", 'Aceptar', {duration: 5000});
          // si el usuario se registro con exito lo logueamos.
          this.object.email = this.register.email;
          this.object.password = this.register.password;
          this.send();
        } else {
          this.loadingService.hide();
          this.snackBar.open('Verifica tu información.', 'Error', {duration: 5000});
          console.log('Usuario o Contraseña incorrectos')
        }
      },error=>{
        console.log(error);
        this.loadingService.hide();
      });
    }


    signInWithGoogle(): void {
      this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data:any)=>{
        // console.log("GOOOGE",data);
        let loginGoogleModel = {
          name:data.firstName,
          email:data.email,
          lastname:data.lastName,
          authToken:data.authToken,
          idToken:data.idToken,
          photoUrl:data.photoUrl,
          role:'CLIENT',
          googleId:data.id
        }
        this.loadingService.show(true,"Espere un momento...");
        this.session.loginWithGoogle(loginGoogleModel).then( async (data:any)=> {
          if (data.transaction === 'ok') {
            this.snackBar.open(data.message, 'Aceptar', {duration: 5000});
            if(true){
              this.storage.saveAKeyOnTheStorage('object',{email:data.email,password:data.id,remember_me:true});
            }
            // tslint:disable-next-line:prefer-const
            let role = this.session.getRoles();
            // console.log(role[0]);
            switch(role[0]){
              case 'CLIENTE':
                // this.router.navigate(['/web/coupons']);
                this.snackBar.open('Bienvenido', 'Aceptar', {duration: 5000});
                break;
              default:
                this.snackBar.open('Lamentablemente aun no tenemos un acceso para tu usuario...', 'Error', {duration: 5000});
                break;
            }
            this.close(true);
            this.loadingService.hide();
          } else {
            this.loadingService.hide();
            this.snackBar.open('Usuario ó Contraseña incorrectos', 'Error', {duration: 5000});
            console.log('Usuario o Contraseña incorrectos');
          }
        }).catch((error)=>{
          this.loadingService.hide();
          this.snackBar.open('Usuario ó Contraseña incorrectos', 'Error', {duration: 5000});
          console.log('Error: ', error);
        });
        // console.log(loginGoogleModel);
      }).catch(e=>{
        console.log(e);
      });
    }

    signInWithFacebook(): void {
      this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((data:any)=>{
        console.log("Facebook",data);
        let email = ''
        if (data.email == undefined) {
          email = data.id;
        } else {
          email = data.email;
        }
        let loginFacebookModel = {
          name:data.firstName,
          email:email,
          lastname:data.lastName,
          authToken:data.authToken,
          photoUrl:data.photoUrl,
          role:'CLIENT',
          facebookId:data.id
        }
        this.loadingService.show(true,"Espere un momento...");
        this.session.loginWithFacebook(loginFacebookModel).then( async (data:any)=> {
          if (data.transaction === 'ok') {
            this.snackBar.open(data.message, 'Aceptar', {duration: 5000});
            if(true){
              this.storage.saveAKeyOnTheStorage('object',{email:data.email,password:data.id,remember_me:true});
            }
            // tslint:disable-next-line:prefer-const
            let role = this.session.getRoles();
            // console.log(role[0]);
            switch(role[0]){
              case 'CLIENTE':
                // this.router.navigate(['/web/coupons']);
                this.snackBar.open('Bienvenido', 'Aceptar', {duration: 5000});
                break;
              default:
                this.snackBar.open('Lamentablemente aun no tenemos un acceso para tu usuario...', 'Error', {duration: 5000});
                break;
            }
            this.close(true);
            this.loadingService.hide();
          } else {
            this.loadingService.hide();
            this.snackBar.open('Usuario ó Contraseña incorrectos', 'Error', {duration: 5000});
            console.log('Usuario o Contraseña incorrectos');
          }
        }).catch((error)=>{
          this.loadingService.hide();
          this.snackBar.open('Usuario ó Contraseña incorrectos', 'Error', {duration: 5000});
          console.log('Error: ', error);
        });
        // console.log(loginGoogleModel);
      }).catch(e=>{
        console.log(e);
      });
    }

    close(status){
      let object = {
        transaction:'',
        code:'',
        object:{}
      };
      if(status){
        object.transaction = 'ok';
        object.object = this.object;
      }else{
        object.transaction = 'bad';
        object.code = 'category:001';
      }
      this.dialogRef.close(object);
    }

  }
