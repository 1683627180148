<app-client-menu></app-client-menu>
<div class="row mt-4 mb-3 justify-content-center">
  <div class="col-md-10 col-12">
    <div class="row ">
      <div class="col-12 col-lg-8" style="margin-top: 30px; padding: 0px 5px;">
        <div style="padding: 10px; padding-top: 0px;">
          <div class="table-prod">
                <table cellspacing="0">
                  <tr style="">
                      <th>Articulo</th>
                      <th>Precio</th>
                      <th>Cantidad</th>
                      <th>Total</th>
                      <th></th>
                  </tr>
                  <tr style="" *ngFor="let object of shoopingCart">
                      <td>
                        <div class="row">
                          <div class="col-md-3 p-0" >
                            <img class="img-product" src="{{object.product.image}}" mat-list-icon  alt="" width="100%">
                          </div>
                          <div class="col-12 col-md-8">
                            <div class="" style="font-size: .8rem; color: #3c3c3b; text-align: left;">{{object.product.name}}</div>
                            <div class="" style="font-size: .8rem; color: #3c3c3b; text-align: left;" *ngIf="object.productPrice.id != ''">
                              <span *ngIf="object.productPrice.capacity != ''"> | {{object.productPrice.capacity}}</span>
                              <span *ngIf="object.productPrice.backrest != ''"> | {{object.productPrice.backrest}}</span>
                              <span *ngIf="object.productPrice.dimension != ''"> | {{object.productPrice.dimension}}</span>
                              <span *ngIf="object.productPrice.tapestry != ''"> | {{object.productPrice.tapestry}}</span>
                              <span *ngIf="object.productPrice.tissue != ''"> | {{object.productPrice.tissue}}</span>
                              <span *ngIf="object.productPrice.varnish != ''"> | {{object.productPrice.varnish}}</span>
                              <span *ngIf="object.productPrice.wood != ''"> | {{object.productPrice.wood}}</span>
                            </div>

                          </div>
                        </div>
                      </td>
                      <td>
                        <p style="font-size: .75rem;" >{{object.product.price | currency:'MXN':'symbol-narrow'}}</p>
                      </td>
                      <td>
                        {{object.quantity}}
                        <!-- <div class="input-group" style="width:5.5rem;">
                          <div class="input-group-prepend" style="cursor:pointer;" (click)="changeQuantity('restar',object);">
                            <div class="input-group-text" id="btnGroupAddon">-</div>
                          </div>
                          <input name="quantity" class="form-control" style="text-align: center; font-size: .75rem;" [(ngModel)]="object.quantity" disabled>
                          <div class="input-group-append" style="cursor:pointer;" (click)="changeQuantity('sumar',object);">
                            <div class="input-group-text" id="btnGroupAddon">+</div>
                          </div>
                        </div> -->
                      </td>
                      <td>
                        <p style="font-size: .75rem;">{{(object.quantity*object.product.price)| currency:'USD':'symbol-narrow'}}</p>
                      </td>
                      <td>
                        <span class="material-icons" (click)="deleteAProduct(object);" style="cursor:pointer;">delete_outline</span>
                      </td>
                  </tr>
              </table>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 bg-light">
        <table class="w-100 mt-3">
          <tbody>
            <tr>
              <th>
                <h2>Total</h2>
              </th>
              <td class="text-right">
                <h2>{{metadata.subtotal | currency:'MXN':'symbol-narrow'}}MXN</h2>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt-5">
            <div class="btn-group pt-2 mt-2 w-100" role="group" aria-label="Basic outlined example">
              <button type="button" class="btn btn-outline-dark p-0" style="border-color: transparent!important;">
                <img src="assets/img/client/amex-gris.svg" style="width:4rem;">
              </button>
              <button type="button" class="btn btn-outline-dark p-0" style="border-color: transparent!important;">
                <img src="assets/img/client/master-card.svg" style="width:4rem;">
              </button>
              <!-- <button type="button" class="btn btn-outline-dark p-0" style="border-color: transparent!important;">
                <img src="assets/img/client/oxxo-gris.svg" style="width:4rem;">
              </button> -->
              <!-- <button type="button" class="btn btn-outline-dark p-0" style="border-color: transparent!important;">
                <img src="assets/img/client/paypal-gris.svg" style="width:4rem;">
              </button> -->
              <button type="button" class="btn btn-outline-dark p-0" style="border-color: transparent!important;">
                <img src="assets/img/client/visa-gris.svg" style="width:4rem;">
              </button>
            </div>
        </div>
        <div class="row justify-content-center mt-5">
          <div class="col-md-10 col-12">
            <button type="button" class="btn btn-success add-shopping text-uppercase btn-block fw-light btnGeneric" [disabled]="shoopingCart.length == 0" routerLink="/client/payment">Realizar pedido</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-client-footer></app-client-footer>
