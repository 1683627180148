
/**Arreglo que contiene todos los endpoints para acceso a al backend.
* *Code:
* @param code código interno para el manejo en frontEnd
* @param endpoint  endpoint para pedir al backEnd
* *
*/
let urls: Array<{ code: string, endpoint: string }> = [
  {
    code:'auth:login',
    endpoint:"auth/login"
  },{
    code: 'auth:signup',
    endpoint: 'auth/signup'
  },{
    code: 'auth:loginWithFacebook',
    endpoint: 'auth/loginWithFacebook'
  },{
    code: "auth:loginWithGoogle",
    endpoint: "auth/loginWithGoogle"
  },{
    code: "auth:user",
    endpoint: "auth/user"
  },{
    code: "auth:logout",
    endpoint: "auth/logout"
  },{
    code: "auth:logout",
    endpoint: "auth/logout"
  },{
    code: "auth:create",
    endpoint: "auth/create"
  },{
    code: "category:list",
    endpoint: "category/list"
  },{
    code: "category:get",
    endpoint: "category/get"
  },{
    code: "category:update",
    endpoint: "category/update"
  },{
    code: "product:list",
    endpoint: "product/list"
  },{
    code: "product:get",
    endpoint: "product/get"
  },{
    code: "product:update",
    endpoint: "product/update"
  },{
    code: "product:upload",
    endpoint: "product/upload"
  },{
    code: "delivery:list",
    endpoint: "delivery/list"
  },{
    code: "delivery:update",
    endpoint: "delivery/update"
  },{
    code: "delivery:get",
    endpoint: "delivery/get"
  },{
    code: "product:addImage",
    endpoint: "product/addImage"
  },{
    code: "product:deleteImage",
    endpoint: "product/deleteImage"
  },{
    code:"product:findAllByStatusAndOutstanding",
    endpoint:"product/findAllByStatusAndOutstanding"
  },{
    code:"product:findAllByStatus",
    endpoint:"product/findAllByStatus"
  },{
    code: "delivery:findAllByStatus",
    endpoint: "delivery/findAllByStatus"
  },{
    code: "sale:update",
    endpoint: "sale/update"
  },{
    code: "sale:findAllByPhone",
    endpoint: "sale/findAllByPhone"
  },{
    code: "sale:findByMexpago",
    endpoint: "sale/findByMexpago"
  },{
    code: "sale:checkAndValidate",
    endpoint: "sale/checkAndValidate"
  },{
    code: "sale:findByPhoneAndFolio",
    endpoint: "sale/findByPhoneAndFolio"
  },{
    code: "order:findBySale",
    endpoint: "order/findBySale"
  },{
    code: "order:findAllStatus",
    endpoint: "order/findAllStatus"
  },{
    code: "order:changeStatus",
    endpoint: "order/changeStatus"
  },{
    code: "sale:findAllByStatus",
    endpoint: "sale/findAllByStatus"
  },{
    code: "sale:findAllByStatusAndStatusOrder",
    endpoint: "sale/findAllByStatusAndStatusOrder"
  },{
    code: "product:findAllByCategory",
    endpoint: "product/findAllByCategory"
  },{
    code: "product:searching",
    endpoint: "product/searching"
  },{
    code: "banner:list",
    endpoint: "banner/list"
  },{
    code: "banner:upload",
    endpoint: "banner/upload"
  },{
    code: "banner:update",
    endpoint: "banner/update"
  },{
    code: "banner:delete",
    endpoint: "banner/delete"
  },{
    code: "banner:get",
    endpoint: "banner/get"
  },{
    code: "productAttribute:findAllByProduct",
    endpoint: "productAttribute/findAllByProduct"
  },{
    code: "productAttribute:update",
    endpoint: "productAttribute/update"
  },{
    code: "productAttribute:deleteAttribute",
    endpoint: "productAttribute/deleteAttribute"
  },{
    code: "productAttribute:uploadAttribute",
    endpoint: "productAttribute/uploadAttribute"
  },{
    code: "productAttribute:addValueToAttribute",
    endpoint: "productAttribute/addValueToAttribute"
  },{
    code: "productAttribute:deleteValueFromAttribute",
    endpoint: "productAttribute/deleteValueFromAttribute"
  },{
    code: "place:list",
    endpoint: "place/list"
  },{
    code: "place:update",
    endpoint: "place/update"
  },{
    code: "place:get",
    endpoint: "place/get"
  },{
    code: "place:delete",
    endpoint: "place/delete"
  },{
    code: "buildingtype:list",
    endpoint: "buildingtype/list"
  },{
    code: "buildingtype:update",
    endpoint: "buildingtype/update"
  },{
    code: "buildingtype:get",
    endpoint: "buildingtype/get"
  },{
    code: "buildingtype:delete",
    endpoint: "buildingtype/delete"
  },{
    code: "property:list",
    endpoint: "property/list"
  },{
    code: "property:update",
    endpoint: "property/update"
  },{
    code: "property:upload",
    endpoint: "property/upload"
  },{
    code: "property:deleteImage",
    endpoint: "property/deleteImage"
  },{
    code: "property:addImage",
    endpoint: "property/addImage"
  },{
    code: "property:get",
    endpoint: "property/get"
  },{
    code: "property:deleteLabel",
    endpoint: "property/deleteLabel"
  },{
    code: "property:addLabel",
    endpoint: "property/addLabel"
  },{
    code: "property_tresd:update",
    endpoint: "property_tresd/update"
  },{
    code: "property_tresd:delete",
    endpoint: "property_tresd/delete"
  },{
    code: "property_tresd:findByProperty",
    endpoint: "property_tresd/findByProperty"
  },{
    code: "property:findAllByStatusAndOutstanding",
    endpoint: "property/findAllByStatusAndOutstanding"
  },{
    code: "property:findAllByStatus",
    endpoint: "property/findAllByStatus"
  },{
    code: "meeting:update",
    endpoint: "meeting/update"
  },{
    code: "meeting:list",
    endpoint: "meeting/list"
  },{
    code: "meeting:delete",
    endpoint: "meeting/delete"
  },{
    code: "productPrice:delete",
    endpoint: "productPrice/delete"
  },{
    code: "productPrice:update",
    endpoint: "productPrice/update"
  },{
    code: "productPrice:findAllByProduct",
    endpoint: "productPrice/findAllByProduct"
  },{
    code: "productPrice:upload",
    endpoint: "productPrice/upload"
  },{
    code: "contact:update",
    endpoint: "contact/update"
  },{
    code: "contact:list",
    endpoint: "contact/list"
  },{
    code: "contact:delete",
    endpoint: "contact/delete"
  },{
    code: "place:upload",
    endpoint: "place/upload"
  },{
    code: "slide:list",
    endpoint: "slide/list"
  },{
    code: "slide:upload",
    endpoint: "slide/upload"
  },{
    code: "slide:update",
    endpoint: "slide/update"
  },{
    code: "slide:delete",
    endpoint: "slide/delete"
  },{
    code: "slide:get",
    endpoint: "slide/get"
  },{
    code: "blog:list",
    endpoint: "blog/list"
  },{
    code: "blog:update",
    endpoint: "blog/update"
  },{
    code: "blog:upload",
    endpoint: "blog/upload"
  },{
    code: "blog:deleteImage",
    endpoint: "blog/deleteImage"
  },{
    code: "blog:addImage",
    endpoint: "blog/addImage"
  },{
    code: "blog:get",
    endpoint: "blog/get"
  },{
    code: "blog:findAllImages",
    endpoint: "blog/findAllImages"
  },{
    code: "blog:searching",
    endpoint: "blog/searching"
  },{
    code: "maker:list",
    endpoint: "maker/list"
  },{
    code: "maker:update",
    endpoint: "maker/update"
  },{
    code: "maker:delete",
    endpoint: "maker/delete"
  },{
    code: "maker:get",
    endpoint: "maker/get"
  },{
    code: "maker:upload",
    endpoint: "maker/upload"
  },{
    code: "product:delete",
    endpoint: "product/delete"
  },{
    code: "category:delete",
    endpoint: "category/delete"
  },{
    code: "mothmezcalero:get",
    endpoint: "mothmezcalero/get"
  },{
    code: "mothmezcalero:update",
    endpoint: "mothmezcalero/update"
  },{
    code: "mothmezcalero:delete",
    endpoint: "mothmezcalero/delete"
  },{
    code: "product:findAllByMaker",
    endpoint: "product/findAllByMaker"
  },{
    code: "mothmezcalero:upload",
    endpoint: "mothmezcalero/upload"
  },{
    code: "category:upload",
    endpoint: "category/upload"
  },{
    code: "category:listRoot",
    endpoint: "category/listRoot"
  },{
    code: "category:findAllByCategory",
    endpoint: "category/findAllByCategory"
  },{
    code: "productCategory:update",
    endpoint: "productCategory/update"
  },{
    code: "productCategory:get",
    endpoint: "productCategory/get"
  },{
    code: "productCategory:delete",
    endpoint: "productCategory/delete"
  },{
    code: "productCategory:listRoot",
    endpoint: "productCategory/listRoot"
  },{
    code: "productCategory:findAllByCategory",
    endpoint: "productCategory/findAllByCategory"
  },{
    code: "productCategory:findAllByProduct",
    endpoint: "productCategory/findAllByProduct"
  },{
    code: "wishlist:update",
    endpoint: "wishlist/update"
  },{
    code: "wishlist:findAllByUser",
    endpoint: "wishlist/findAllByUser"
  },{
    code: "wishlist:delete",
    endpoint: "wishlist/delete"
  },{
    code: "wishlist:findByUserAndProduct",
    endpoint: "wishlist/findByUserAndProduct"
  },{
    code: "evopayment:createSession",
    endpoint: "evopayment/createSession"
  },{
    code: "sale:get",
    endpoint: "sale/get"
  }
];

export default urls;
