import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { SessionModule } from './service/session/session.module';
import { LoadingModule } from './directive/loading/loading.module';
import { EvopaymentModule } from './service/evopayment/evopayment.module';
import { KsfUploadFilesModule } from './directive/ksf-upload-files/ksf-upload-files.module';
import { UploadImgMinComponent } from './directive/upload-img-min/upload-img-min.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxPicaModule } from '@digitalascetic/ngx-pica';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { CompressService } from './compress/compress.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClientMenuComponent } from './client/client-menu/client-menu.component';
import { ClientMenuService } from './client/client-menu/client-menu.service';
import { ClientFooterComponent } from './client/client-footer/client-footer.component';
import { ClientHomeComponent } from './client/client-home/client-home.component';
import { ClientProductComponent } from './client/client-product/client-product.component';
import { DetailsProductComponent } from './client/client-product/details-product/details-product.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ImageProductDirective } from './admin/admin-product/image-product-directive/image-product.directive';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminToolbarComponent } from './admin/admin-toolbar/admin-toolbar.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminDeliveryComponent } from './admin/admin-delivery/admin-delivery.component';
import { AdminCategoryComponent } from './admin/admin-category/admin-category.component';
import { AdminCategoryUpdateComponent } from './admin/admin-category/admin-category-update/admin-category-update.component';
import { AdminDeliveryUpdateComponent } from './admin/admin-delivery/admin-delivery-update/admin-delivery-update.component';
import { AdminBannerComponent } from './admin/admin-banner/admin-banner.component';
import { AdminBannerUpdateComponent } from './admin/admin-banner/admin-banner-update/admin-banner-update.component';
import { AdminMakerComponent } from './admin/admin-maker/admin-maker.component';
import { AdminMakerUpdateComponent } from './admin/admin-maker/admin-maker-update/admin-maker-update.component';
import { AdminProductComponent } from './admin/admin-product/admin-product.component';
import { AdminProductUpdateComponent } from './admin/admin-product/admin-product-update/admin-product-update.component';
import { AdminSalesComponent } from './admin/admin-sales/admin-sales.component';
import { AdminSalesDetailsComponent } from './admin/admin-sales/admin-sales-details/admin-sales-details.component';
import { ClientProductsShoopingComponent } from './client/client-product/client-products-shooping/client-products-shooping.component';
import { LoginComponent } from './login/login.component';
import { ClientOrderComponent } from './client/client-order/client-order.component';
import { ClientShoppingCartComponent } from './client/client-shopping-cart/client-shopping-cart.component';
import { ClientPaymentComponent } from './client/client-payment/client-payment.component';
import { ClientPrivacyComponent } from './client/client-privacy/client-privacy.component';
import { ClientFavoriteComponent } from './client/client-favorite/client-favorite.component';
import { ClientFavoriteBtnComponent } from './client/client-favorite-btn/client-favorite-btn.component';
import { ClientAlertSessionComponent } from './client/client-alert-session/client-alert-session.component';
import { ClientSendAndBackComponent } from './client/client-send-and-back/client-send-and-back.component';
import { ClientEvopaymentComponent } from './client/client-evopayment/client-evopayment.component';

@NgModule({
  declarations: [
    AppComponent,
    UploadImgMinComponent,
    ImageProductDirective,
    AdminLoginComponent,
    AdminToolbarComponent,
    AdminDashboardComponent,
    AdminDeliveryComponent,
    AdminCategoryComponent,
    AdminCategoryUpdateComponent,
    AdminDeliveryUpdateComponent,
    AdminBannerComponent,
    AdminBannerUpdateComponent,
    AdminMakerComponent,
    AdminMakerUpdateComponent,
    AdminProductComponent,
    AdminProductUpdateComponent,
    AdminSalesComponent,
    AdminSalesDetailsComponent,
    ClientProductsShoopingComponent,
    ClientMenuComponent,
    ClientFooterComponent,
    ClientHomeComponent,
    ClientProductComponent,
    DetailsProductComponent,
    LoginComponent,
    ClientOrderComponent,
    ClientShoppingCartComponent,
    ClientPaymentComponent,
    ClientPrivacyComponent,
    ClientFavoriteComponent,
    ClientFavoriteBtnComponent,
    ClientAlertSessionComponent,
    ClientSendAndBackComponent,
    ClientEvopaymentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPicaModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatDatepickerModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSelectModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatRadioModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    NgxGalleryModule,
    SocialLoginModule,
    SessionModule.forRoot(),
    LoadingModule.forRoot(),
     EvopaymentModule.forRoot(),
    KsfUploadFilesModule.forRoot(),
  ],
  entryComponents:[
    UploadImgMinComponent,
    AdminDeliveryUpdateComponent,
    AdminCategoryUpdateComponent,
    AdminMakerUpdateComponent,
    ImageProductDirective,
    LoginComponent,
    ClientProductsShoopingComponent,
    ClientFavoriteBtnComponent,
    ClientAlertSessionComponent
  ],
  providers: [ 
    ClientMenuService,
    CompressService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '323265415182-7132elkakh0o9hqoj7i2jhfsvriuarq0.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              '1638210453178924'
            )
          },
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
