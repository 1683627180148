<div class="row justify-content-center m-0">
  <div class="col-12">
    <div class="row">
      <div class="col-12" style="padding-left: 10px; text-align: start">
        <div class="row mt-3">
          <h3 style="color: #666666;" class="col-12 text-center"> Se requiere iniciar sesión en  <strong style="color: #c6945d;"> CASA GECAPA </strong>para continuar</h3>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3 mb-2" style="width: 100%;">
      <button mat-flat-button color="primary" class="col-md-5 col-12 btnGeneric" (click)="close(true);">Iniciar sesión</button>
      <div class="col-1"></div>
      <button mat-flat-button color="accent" class="col-md-5 col-12 btnGeneric" (click)="close(false);">Cancelar</button>
    </div>
  </div>
</div>
