<app-client-menu></app-client-menu>
<div class="row">
  <div class="col-12 col-md-3">
    <ol class="breadcrumb mb-1">
      <li class="breadcrumb-item"><a routerLink="/" class="breadcrumb-item-a">Gecapa</a></li>
      <li class="breadcrumb-item"><a routerLink="/client/favorite" class="breadcrumb-item-a">Favoritos</a></li>
    </ol>
    <p class="h3 pl-3">FAVORITOS</p>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-12 col-md-10">
    <table class="table tableEcommerce">
      <thead class="thead">
        <tr class="trEcommerce">
          <th>Producto</th>
          <th>Detalles</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let object of list; let i = index;">
          <td style="width: 250px;">
            <div class="mainTitleWithImg">
              <div class="tableImg" style="width: 150px !important;">
                <img src="{{object.product.image}}" alt="">
              </div>
            </div>
          </td>

          <td>
            <div class="row justify-content-center" style="width: 100%; margin: 0px;">
              <p class="producto-title">{{object.product.name}}</p>
            </div>
            <div class="row justify-content-center" style="width: 100%; margin: 0px;">
              <h3 class="precio">{{object.product.price | currency:'MXN':'symbol-narrow'}} MXN</h3>
            </div>
            <div style="display: inline-block; width: 30%; margin-right: 15px;">
              <div class="btn-product d-grid">
                <button class="btn btn-warning add-shopping text-uppercase fw-light mt-1 btnGeneric" routerLink="/client/product/details/{{object.product.id}}">Ver más</button>
              </div>
            </div>
            <div style="width:30%; display: inline-block;">
              <div class="btn-product d-grid">
                <button type="button" class="btn btn-success add-shopping text-uppercase fw-light btnGeneric" (click)="delete(object);">Eliminar</button>

                <!-- <button class="btn btn-warning add-shopping text-uppercase fw-light mt-1 btnGeneric" routerLink="/client/product/details/{{object.product.id}}">Ver más</button> -->
              </div>
            </div>


          </td>
        </tr>
      </tbody>
    </table>
    <div class="row justify-content-end mb-3" style="margin-left:0px; margin-right:0px;">
      <div class="col-6 ">
        <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
<app-client-footer></app-client-footer>
