import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { EvopaymentService } from './evopayment.service';
export * from './evopayment.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    EvopaymentService,
  ],
  exports: [
  ]
})

export class EvopaymentModule {
  public static forRoot(): ModuleWithProviders< EvopaymentModule > {
    return {
      ngModule: EvopaymentModule,
    };
  }
 }
