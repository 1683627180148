<app-client-menu></app-client-menu>
<div class="row justify-content-center mt-2">
  <div class="col-md-11 col-12">
    <div class="row mt-5">
      <div class="col-md-2 col-6">
        <p class="text-uppercase pt-3" style="color:#cac6c6;">{{metadata.totalProducts}} artículos</p>
      </div>
      <div class="col-md-2 col-6">
        <mat-form-field>
          <mat-label>Ordenar por</mat-label>
          <select matNativeControl required [(ngModel)]="productMin.order" (change)="ordeProducts();">
            <option [value]="'Precio de menor a mayor'">Precio de menor a mayor</option>
            <option [value]="'Precio de mayor a menor'">Precio de mayor a menor</option>
            <option [value]="'Destacados'">Destacados</option>
            <option [value]="'Lo más reciente'">Lo más reciente</option>
          </select>
        </mat-form-field>
      </div>
      <div class="col-md-3 col-12 text-right">

        <!-- <select class="custom-select" name="order" [(ngModel)]="productMin.order" (change)="ordeProducts();">
          <option  [value]="''"><p style="font-size: .8rem; color: #a2a2a2; font-weight: bolder; margin-top: 10px;">Ordenar por</p></option>
          <option [value]="'Precio de menor a mayor'">Precio de menor a mayor</option>
          <option [value]="'Precio de mayor a menor'">Precio de mayor a menor</option>
          <option [value]="'Destacados'">Destacados</option>
          <option [value]="'Lo más reciente'">Lo más reciente</option>
        </select> -->
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12 col-md-3" style="padding-left:0px;">
        <ol class="breadcrumb mb-1">
          <li class="breadcrumb-item"><a routerLink="/" class="breadcrumb-item-a">Gecapa</a></li>
          <li class="breadcrumb-item" *ngIf="metadata.showCategories"><a routerLink="/client/product/all" class="breadcrumb-item-a">Productos</a></li>
          <li class="breadcrumb-item" *ngFor="let object of categoryTree"><a routerLink="/client/product/{{object.id}}" class="breadcrumb-item-a">{{object.name}}</a></li>

        </ol>
        <p class="h3 pl-3" *ngIf="metadata.showCategories">{{mainCategory.name}}</p>
        <p class="h3 pl-3" *ngIf="!metadata.showCategories">PRODUCTOS</p>

        <!-- <select class="custom-select" name="order" [(ngModel)]="productMin.category.id" (change)="categorySelec();">
          <option  [value]="''"><p style="font-size: .8rem; color: #a2a2a2; font-weight: bolder; margin-top: 10px;">Todas</p></option>
          <option [value]="object.id" *ngFor="let object of categories">{{object.name}}</option>
        </select> -->
      </div>
      <div class="col-md-3 col-6 mb-3" *ngFor=" let object of list" style="padding-left:5px; padding-right:5px;">
        <mat-card class="text-center bg-light category productCard img-list" >
          <div class="banderaagotado" *ngIf="object.availability == 0">
            <p style="margin: 0px;">AGOTADO</p>
          </div>
          <div class="bandera" *ngIf="object.discount">
            <p style="margin: 0px;">DESCUENTO {{object.discountValue}}%</p>
          </div>
          <!--Se utiliza una variable para dar el efecto de hover, se tomo temporalmente la variable like pero
          se puede cambiar posteriormente
          --->
          <div mat-card-image class=" imgCard" (mouseover)="changeImgToShow(object)" (mouseout)="ResetImgToShow(object);">
            <img [src]="object.showImage" width="100%" routerLink="/client/product/details/{{object.id}}">
          </div>
          <app-client-favorite-btn [object]="object"></app-client-favorite-btn>

          <div class="row justify-content-center colores" style="width: 100%; margin: 0px;">
            <ul class="color">
              <li class="color" *ngFor="let object of object.attributesList" matTooltip="{{object.wood}}">
                <div class="circulo" *ngIf="!object.isTexture" [ngStyle]="{'background': object.texture }"></div><p></p>
                <div class="circulo" *ngIf="object.isTexture"> <img src="{{object.texture}}" class="circulo" style="position:absolute;"> </div><p></p>
              </li>
            </ul>
          </div>
          <div class="row justify-content-center" style="width: 100%; margin: 0px;" routerLink="/client/product/details/{{object.id}}">
            <p class="producto-title">{{object.name}}</p>
          </div>
          <div class="row justify-content-center" style="width: 100%; margin: 0px;">
            <h3 class="precio">{{object.price | currency:'MXN':'symbol-narrow'}} MXN</h3>
          </div>
          <div class="btn-product d-grid">
            <!-- <button type="button" class="btn btn-success add-shopping text-uppercase fw-light btnGeneric" style="font-size: 9pt !important;" *ngIf="object.availability > 0" (click)="addToShoppingCard(object);">Agregar a carrito</button> -->
            <button class="btn btn-warning add-shopping text-uppercase fw-light mt-1 btnGeneric" routerLink="/client/product/details/{{object.id}}">Ver más</button>
          </div>
        </mat-card>
      </div>
      <div class="col-12" style="text-align: center;">
        <button name="button" class="btn btn-outline-danger mt-2 pl-3 pr-3 btnGeneric" *ngIf="metadata.getMoreProducts" (click)="showMoreProducts();">VER MÁS PRODUCTOS</button>
      </div>
    </div>
  </div>
</div>
<app-client-footer></app-client-footer>
