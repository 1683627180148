import { Injectable } from '@angular/core';
import { SessionService } from '../../service/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class ClientMenuService {
  categoryMinModel = {
    max: 300,
    page: 1,
    status:true,
    category:{
      id:''
    }
  }
  categoryList = [];
  constructor(public session:SessionService) { }
  /**funcionalidad para obtener las categorias
  */
  getCategorires(){
    return new Promise((resolve,reject)=>{
      if(this.categoryList.length > 0){
        resolve(this.categoryList);
      }else{
        try{
          let categoryReq = JSON.parse(JSON.stringify(this.categoryMinModel));
          this.session.postRequest("category:listRoot",categoryReq).subscribe(async(data:any)=>{
            this.categoryList = data.object.instanceList.map((item) =>{
              item.description = this.session.urlIMG+'category/'+item.description;
              item.categoryList = [];
               return item;
            });
             let menuSide = this.categoryList.length;
             menuSide -= 3;

             for(let i =menuSide; i < this.categoryList.length; i++ ){
               this.categoryList[i].left = true;
             }
             resolve(this.categoryList);
            for(let i = 0; i < this.categoryList.length; i++){
              let categoryChildReq = JSON.parse(JSON.stringify(this.categoryMinModel));
              categoryChildReq.category.id = this.categoryList[i].id;
              if(this.categoryList[i].more > 0){
                this.categoryList[i].categoryList = await this.categoriFromBD(categoryChildReq)
              }else{
                this.categoryList[i].categoryList = [];
              }
            }
          },error=>{
            console.log(error);
            reject(error);
          })

        }catch(e){
          reject(e);
        }
      }
    });
  }

  /**funcionalidad para obtener las categorias de la bd
  */
  categoriFromBD(categoryChildReq){
    return new Promise((resolve,reject)=>{
      this.session.postRequest("category:findAllByCategory",categoryChildReq).subscribe(async(data:any)=>{
        let categoryList = data.object.instanceList.map((item) =>{
          item.description = this.session.urlIMG+'category/'+item.description;
           return item;
        });
        for(let i = 0; i < categoryList.length; i++){
          let categoryChildReq = JSON.parse(JSON.stringify(this.categoryMinModel));
          categoryChildReq.category.id = categoryList[i].id;
          if(categoryList[i].more > 0){
            categoryList[i].categoryList = await this.categoriFromBD(categoryChildReq)
          }else{
            categoryList[i].categoryList = [];
          }
        }
        let bano = {name:''};
        categoryList = categoryList.filter((item)=>{
          if(item.name == 'BAÑO')
            bano = item;
          return item.name != 'BAÑO';
        });
        if(bano.name != ''){
          categoryList.push(bano);
        }

        //Enviamos la categoria de baño hasta abajo
        resolve(categoryList);
      },error=>{
        console.log("error:category:findAllByCategory",error);
      })
    });
  }
}
