import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AdminCategoryUpdateComponent } from '../../admin-category/admin-category-update/admin-category-update.component';
import { AdminMakerUpdateComponent}  from '../../admin-maker/admin-maker-update/admin-maker-update.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-product-update',
  templateUrl: './admin-product-update.component.html',
  styleUrls: ['./admin-product-update.component.css']
})
export class AdminProductUpdateComponent implements OnInit {

  complexForm: FormGroup;
  catCtrl = new FormControl();
  productModel = {
    id:'',
    name:'',
    price:'0',
    short_description:'',
    description:'',
    image:'',
    tags:'',
    category_id:null,
    maker_id:'',
    status:true,
    en_name:'',
    en_short_description:'',
    en_description:'',
    availability:1,
    outstanding:false,
    multiplePrice:false,
    freeDelivery:false,
    discount:false,
    discountValue:0,
    priceDiscount:0,
    observation:'',
    en_observation:'',
    sku:'',
    additional_data:''
  };

  categoryMin = {
    max: 200,
    page: 1,
  }
  categoryList = [];
  makerMin = {
    max: 200,
    page: 1,
  }
  makerList = [];
  imgeModel = {
    img:{
      name:'',
      type:'',
      file:''
    },
    uploadted:false
  }
  imgesList = [];

  object = JSON.parse(JSON.stringify(this.productModel));

  //modelos de Atributos
  productAttributeModel = {
      id:'',
      name:'',
      product:{
        id:''
      }
  }
  productAttributeValueModel = {
    id:'',
    value:'',
    quantity:0,
    isTexture:false,
    texture:'',
    productAttribute:{
      id:''
    }
  }
  productAttributeList = [];

  productPriceModel = {
    id:'',
    price:'',
    quantity:0,
    wood:'',
    varnish:'',
    capacity:'',
    isTexture:false,
    texture:'#000',
    weight:'',
    dimension:'',
    tapestry:'',
    tissue:'',
    backrest:'',
    observation:'',
    istapestry:false,
    tapestryimg:'#000',
    product:{
      id:''
    }
  }
  productPriceList = [];
  //
  indexTAB = 0;
  additionalDataModel = {
    name:'',
    value:''
  }
  additionalDataArray = [];
  list = [];
categorySelectedList = [];
productCategoryModel = {
  id:'',
  category:{
    id:''
  },
  product:{
    id:''
  }
}
  constructor(private session: SessionService, private router:Router, public activatedRoute:ActivatedRoute, public snackBar: MatSnackBar, public loading:LoadingService, public fb: FormBuilder, public dialog: MatDialog ) {
    this.complexForm = fb.group({
      'name': [null, Validators.compose([Validators.required])],
      'price': [null, Validators.compose([Validators.required])],
      'description': [null, Validators.compose([Validators.required])],
      'maker': [null, Validators.compose([Validators.required])],
    });
   }

   ngOnInit(): void {
     this.getMakers();
     this.activatedRoute.params.subscribe((params: Params)=>{
       if(params['id'] == 'new'){
         this.addImgModel();
         this.additionalDataArray.push(JSON.parse(JSON.stringify(this.additionalDataModel)));
         this.getCategoriesRoot();
       }else{
         this.object.id = params['id'];
         this.loading.show(true,"Espere un momento...");
         this.session.postRequest("product:get",this.object).subscribe((data:any)=>{
           this.object = data.object;
           this.getCategoriesRoot();
           //verificamos si hay imagenes cargadas
           for(let item of this.object.images){
             let aux = JSON.parse(JSON.stringify(this.imgeModel));
             aux.uploadted = true;
             aux.img.name = item.image;
             if(aux.img.name == this.object.image){
               aux.cover = true;
             }
             this.imgesList.push(aux);
           }
           this.addImgModel();

           this.object.categoria = parseInt(this.object.categoria);
           let arrayAuxtextArray = this.object.additional_data.split('|');
           for(let item of arrayAuxtextArray){
             if(item){
               let auxArra = JSON.parse(JSON.stringify(this.additionalDataModel));
               let itemTexts = item.split('[');
               auxArra.name = itemTexts[0];
               if(itemTexts.length == 2){
                 auxArra.value = itemTexts[1];
               }
               this.additionalDataArray.push(auxArra);
             }
           }
           this.additionalDataArray.push(JSON.parse(JSON.stringify(this.additionalDataModel)));
           this.loading.hide();
         },error=>{
           console.log('product:get',error);
           this.loading.hide();
         })
       }
     },error=>{
       console.log("activatedRoute:error",error);
     })
   }

   send(exit){
     return new Promise((resolve,reject)=>{
       if(this.object.image != ""){
         this.loading.show(true,"Espere un momento...");
         if(!this.object.discount){
           this.object.discountValue = 0;
         }
         //agregamos datos adicionales
         let arrayAuxtext = '';
         for(let i = 0; i < this.additionalDataArray.length; i++){
           if(this.additionalDataArray[i].name != '' && this.additionalDataArray[i].name != ''){
             arrayAuxtext = arrayAuxtext + this.additionalDataArray[i].name+'['+this.additionalDataArray[i].value+'|';
           }
         }
         this.object.additional_data = arrayAuxtext;
         this.session.postRequest("product:update",this.object).subscribe(async(data:any)=>{
           this.object.id = data.object.id;
           this.saveCategories();
           //Recorremos las imagenes para asocialas al producto
           for(let item of this.imgesList){
             if(item.uploadted)
              await this.saveImages(item);
           }
           this.loading.hide();
           resolve(true);
           if(exit){
             this.router.navigate(['/admin/product']);
             this.snackBar.open('Registro guardado', 'Aceptar', {duration: 5000});
           }
         },error=>{
           console.log("error:product:update",error);
           this.snackBar.open(error.message, 'Error', {duration: 5000});
           this.loading.hide();
           reject(error);
         });
       }else{
         this.snackBar.open('Seleccione una imagen de portada', 'Aceptar', {duration: 5000});
       }
     });
   }

   addAdditionalData(){
     this.additionalDataArray.push(JSON.parse(JSON.stringify(this.additionalDataModel)));
   }

   /**funcionalidad para selccionar una imagen con portada principal
   */
   coverSelected(object){
     console.log(object);
     for(let i = 0; i < this.imgesList.length; i++){
       if(object.img.name != ""){
         if(object.uploadted){
           if(this.imgesList[i].img.name == object.img.name){
             this.imgesList[i].cover = true;
             this.object.image = this.imgesList[i].img.name;
           }else{
             this.imgesList[i].cover = false;
           }
         }else{
           this.snackBar.open('Por favor suba la imagen para seleccionarla como portada.', 'Aceptar', {duration: 5000});
         }
       }else{
         this.snackBar.open('Ese campo no tiene un imagen.', 'Aceptar', {duration: 5000});
       }
     }

   }

   saveImages(object){
     return new Promise((resolve,reject)=>{
       let aux ={
         image:object.img.name,
         product:{
           id:this.object.id
         }
       }
       this.session.postRequest("product:addImage",aux).subscribe((data:any)=>{
         resolve(true);
       },error=>{
         reject(error);
       })
     });
   }


     /**función para obtener el listado de categorias
     */
     getCategories(){
       this.session.postRequest("category:list",this.categoryMin).subscribe((data:any)=>{
         this.categoryList = data.object.instanceList;
       },error=>{
         console.log("error:category:list",error);
       })
     }

     /**función para obtener el listado de categorias
     */
     getMakers(){
       this.session.postRequest("maker:list",this.makerMin).subscribe((data:any)=>{
         this.makerList = data.object.instanceList;
       },error=>{
         console.log("error:category:list",error);
       })
     }


     /**funcionalidad para agregar un nuevo cascaron de imagen
     */
     addImgModel(){
       this.imgesList.push(JSON.parse(JSON.stringify(this.imgeModel)));
     }

     deleteImgModel(){
       let rm = this.imgesList.pop();
       console.log(rm);
       if(rm.img.name != '' && this.object.id != ''){
         if(rm.cover){
           this.object.image = "";
         }
         let aux ={
           image:rm.img.name,
           product:{
             id:this.object.id
           }
         }
         /*funcion para eliminar una imagen de la bd
         */
         this.session.postRequest("product:deleteImage",aux).subscribe((data:any)=>{

         },error=>{
           console.log(error);
         })
       }

     }
     deleteAnImage(object){
       if(object.img.name != '' && this.object.id != ''){
         if(object.cover){
           this.object.image = "";
         }
         let aux ={
           image:object.img.name,
           product:{
             id:this.object.id
           }
         }
         /*funcion para eliminar una imagen de la bd
         */
         this.session.postRequest("product:deleteImage",aux).subscribe((data:any)=>{
           for(let i = 0; i < this.imgesList.length; i++){
             if(this.imgesList[i].img.name == object.img.name){
               this.imgesList.splice(i,1);
             }
           }
         },error=>{
           console.log(error);
         })
       }else{
         for(let i = 0; i < this.imgesList.length; i++){
           if(this.imgesList[i].img.name == object.img.name){
             this.imgesList.splice(i,1);
           }
         }
       }
     }

     /**funcionalidades de atributos
     */
    async changeTab(ev){
       // console.log(ev);
       if(ev.index == 1){
         try{
           await this.send(false);
           // console.log("Buscamos los atributos del producto");
           this.session.postRequest("productPrice:findAllByProduct",{product:{id:this.object.id}}).subscribe((data:any)=>{
             this.productPriceList = [];
             for(let item of data.object.instanceList){
               item.image = JSON.parse(JSON.stringify(this.imgeModel));
               if(item.isTexture){
                 item.image.img.name = item.texture.substring(item.texture.lastIndexOf("/")+1, item.texture.length);
                 item.image.uploadted = true;
               }
               this.productPriceList.push(item);
             }
             // for(let i = 0; i < this.productAttributeList.length; i++){
             //   this.addNewValueForAttribute(this.productAttributeList[i]);
             // }
             this.addNewPrice();
           },error=>{
             console.log(error);
           });
         }catch(e){
           this.snackBar.open('Hubo un error intenta nuevamente', 'Aceptar', {duration: 5000});
         }

       }
     }

     /**funcionalidad para agregar una nuevo precio
     */
     addNewPrice(){
       let aux = JSON.parse(JSON.stringify(this.productPriceModel));
       aux.product.id = this.object.id
       aux.image = JSON.parse(JSON.stringify(this.imgeModel));
       this.productPriceList.push(aux);
     }

     /**
     */
     autoSaveProductPrice(object){
       this.loading.wait();
       if(object.isTexture){
          object.texture =this.session.urlIMG+"textures/"+ object.image.img.name ;
       }
       if(object.istapestry){
          object.tapestryimg = this.session.urlIMG+"textures/"+ object.imagetapestry.img.name ;
       }
       this.session.postRequest("productPrice:update",object).subscribe((data:any)=>{
         let isNew = false;
         if(object.id == ''){
           //agregamos un nuevo atributo vacio
           this.addNewAttribute();
           isNew = true;
         }
         object.id = data.object.id;
         if(isNew){
           this.addNewPrice();
         }
         this.snackBar.open('Registro actualizado exitosamente', 'Aceptar', {duration: 3000});
         this.loading.hide();
       },error=>{
         console.log(error);
         this.loading.hide();
       });
     }

     /**funcioanlidad para eliminar un precio
     **/

     deleteProductPrice(object){
       this.loading.wait();
       this.session.postRequest("productPrice:delete",object).subscribe((data:any)=>{
         for(let i = 0; i < this.productPriceList.length; i++){
           if(this.productPriceList[i].id == object.id){
             this.productPriceList.splice(i,1);
             this.snackBar.open('Registro eliminado exitosamente', 'Aceptar', {duration: 2000});
             this.loading.hide();
             break;
           }
         }
       },error=>{
         this.loading.hide();
       })
     }


     /**funcionalidad para agregar una nuevo atributo
     */
     addNewAttribute(){
       let aux = JSON.parse(JSON.stringify(this.productAttributeModel));
       aux.product.id = this.object.id;
       aux.productAttributeValues = [];
       this.productAttributeList.push(aux);
     }

     /**funcionalidad para agregar un nuevo valor de atributo
     */
     addNewValueForAttribute(object){
       let aux = JSON.parse(JSON.stringify(this.productAttributeValueModel));
       aux.productAttribute.id = object.id;
       aux.image = JSON.parse(JSON.stringify(this.imgeModel));
       object.productAttributeValues.push(aux);
     }

     /**funcionalidad para guardar un atributo
     **/
     autoSaveAttribute(object){
       this.loading.wait();
       this.session.postRequest("productAttribute:update",object).subscribe((data:any)=>{
         let isNew = false;
         if(object.id == ''){
           //agregamos un nuevo atributo vacio
           this.addNewAttribute();
           isNew = true;
         }
         object.id = data.object.id;
         if(isNew){
           //agregamos un valor
           this.addNewValueForAttribute(object);
         }
         this.snackBar.open('Registro actualizado exitosamente', 'Aceptar', {duration: 3000});
         this.loading.hide();
       },error=>{
         console.log(error);
         this.loading.hide();
       });
     }

     /**funcioanlidad para eliminar un atributo
     **/

     deleteAttribute(object){
       this.loading.wait();
       this.session.postRequest("productAttribute:deleteAttribute",object).subscribe((data:any)=>{
         for(let i = 0; i < this.productAttributeList.length; i++){
           if(this.productAttributeList[i].id == object.id){
             this.productAttributeList.splice(i,1);
             this.snackBar.open('Registro eliminado exitosamente', 'Aceptar', {duration: 2000});
             this.loading.hide();
             break;
           }
         }
       },error=>{
         this.loading.hide();
       })
     }

     /**funcionalidad para actualizar los datos del valor de atributo
     */
     saveProductAttributeValue(object,att){
       this.loading.wait();
       if(object.isTexture){
          object.texture = this.session.urlIMG+"textures/"+ object.image.img.name ;
       }
       if(object.istapestry){
          object.tapestryimg = this.session.urlIMG+"textures/"+ object.imagetapestry.img.name ;
       }
       this.session.postRequest("productAttribute:addValueToAttribute",object).subscribe((data:any)=>{
         if(object.id == ''){
           this.addNewValueForAttribute(att);
         }
         object.id = data.object.id;
         this.snackBar.open('Registro actualizado exitosamente', 'Aceptar', {duration: 3000});
         this.loading.hide();
       },error=>{
         console.log(error);
         this.loading.hide();
       });
     }

     deleteAttributeValue(object,att){
       this.loading.wait();
       this.session.postRequest("productAttribute:deleteValueFromAttribute",object).subscribe((data:any)=>{
         for(let i = 0; i < att.productAttributeValues.length; i++){
           if(att.productAttributeValues[i].id == object.id){
             att.productAttributeValues.splice(i,1);
             this.snackBar.open('Registro eliminado exitosamente', 'Aceptar', {duration: 2000});
             this.loading.hide();
             break;
           }
         }
       },error=>{
         this.loading.hide();
       })
     }


     /** funcionalidad para crear una categoria
     */
     updatemaker(uuid){
       const dialogRef = this.dialog.open(AdminMakerUpdateComponent, {
         width: '50%',
         data: uuid,
       });
       dialogRef.afterClosed().subscribe(result => {
         if(result != undefined ){
           if(result.transaction == 'ok'){
             // El modal se cerro con objeto
             this.object.maker_id = result.object.id;
             this.getMakers();
           }else{
             // El modal se cerro sin objeto
             this.snackBar.open('No se guardo ningun registro', '', {
               duration: 1000
             });
           }
         }else{
           // El modal se cerro sin seleccionar algo, dandole click fuera
           this.snackBar.open('No se guardo ningun registro', '', {
             duration: 1000
           });
         }
       });
     }


     /** funcionalidad para crear una categoria
     */
     updateCategory(uuid){
       const dialogRef = this.dialog.open(AdminCategoryUpdateComponent, {
         width: '50%',
         data: uuid,
       });
       dialogRef.afterClosed().subscribe(result => {
         if(result != undefined ){
           if(result.transaction == 'ok'){
             // El modal se cerro con objeto
             this.object.category_id = result.object.id;
             this.getCategories();
           }else{
             // El modal se cerro sin objeto
             this.snackBar.open('No se guardo ningun registro', '', {
               duration: 1000
             });
           }
         }else{
           // El modal se cerro sin seleccionar algo, dandole click fuera
           this.snackBar.open('No se guardo ningun registro', '', {
             duration: 1000
           });
         }
       });
     }

     nextTab(){
       if(this. object.nombre != ''&& this.object.image != ''){
         this.indexTAB++;
       }else{
         this.snackBar.open('Seleccione una imagen de portada', 'Aceptar', {duration: 5000});
       }
     }

     /**función para obtener el listado de categorias
     */
     getCategoriesRoot(){
       this.session.postRequest("category:listRoot",this.categoryMin).subscribe((data:any)=>{

         this.list = data.object.instanceList.map((item) =>{
           item.description = this.session.urlIMG+'category/'+item.description;
            return item;
         });
         if(this.object.id != ''){
           this.getCategoriesforProduct()
         }
       },error=>{
         console.log("error:category:list",error);
       })
     }


     loadChildCategories(object){
       object.opened = true;
       this.session.postRequest("category:findAllByCategory",{status:true,category:{id:object.id}}).subscribe((data:any)=>{
         object.categoryList = data.object.instanceList.map((item) =>{
           item.description = this.session.urlIMG+'category/'+item.description;
            return item;
         });
         this.markCategory(object.categoryList);
       },error=>{
         console.log("error:category:list",error);
       })
     }

     closeCategoryList(object){
       object.opened = false;
     }

     checkedCategory(object){
      object.selected = true;
      this.categorySelectedList.push(object)
     }

     saveCategories(){
       for(let i = 0; i < this.categorySelectedList.length; i++ ){
         let auxCategory = JSON.parse(JSON.stringify(this.productCategoryModel));
         auxCategory.category.id = this.categorySelectedList[i].id;
         auxCategory.product.id = this.object.id;
         this.session.postRequest("productCategory:update",auxCategory).subscribe((data:any)=>{
         },error=>{
           console.log("error:productCategory:update",error);
         })
       }
     }

     unCheckedCategory(object){
       let auxCategory = JSON.parse(JSON.stringify(this.productCategoryModel));
       auxCategory.category.id = object.id;
       auxCategory.product.id = this.object.id;
       this.loading.show(true,"Espere un momento...");
       this.session.postRequest("productCategory:delete",auxCategory).subscribe((data:any)=>{
         object.selected = false;
         for(let i = 0; i < this.categorySelectedList.length; i++){
           if(this.categorySelectedList[i].id == object.id){
             this.categorySelectedList.splice(i,1);
             break;
           }
         }
         this.loading.hide();
       },error=>{
         this.loading.hide();
         console.log("error:productCategory:delete",error);
       })

     }

     remove(object){
       let auxCategory = JSON.parse(JSON.stringify(this.productCategoryModel));
       auxCategory.category.id = object.id;
       auxCategory.product.id = this.object.id;
       this.loading.show(true,"Espere un momento...");
       this.session.postRequest("productCategory:delete",auxCategory).subscribe((data:any)=>{
         object.selected = false;
         for(let i = 0; i < this.categorySelectedList.length; i++){
           if(this.categorySelectedList[i].id == object.id){
             this.categorySelectedList.splice(i,1);
             break;
           }
         }
         this.loading.hide();
       },error=>{
         this.loading.hide();
         console.log("error:productCategory:delete",error);
       })
     }

     /**funcionalidad para obtener categorias de una productos
     */
     getCategoriesforProduct(){
       let auxCategory = JSON.parse(JSON.stringify(this.productCategoryModel));
       auxCategory.product.id = this.object.id;
       this.session.postRequest("productCategory:findAllByProduct",auxCategory).subscribe((data:any)=>{
         for(let item of data.object.instanceList){
           this.categorySelectedList.push(item.category);
         }
         this.markCategory(this.list);
       },error=>{
         console.log("error:productCategory:findAllByProduct",error);
       })
     }

     /**funcionalidad para marcar como categorias seleccionadas
     */
     markCategory(list){
       for(let item of this.categorySelectedList){
         for(let i = 0; i < list.length; i++){
           if(list[i].id == item.id){
             list[i].selected = true;
           }
         }
       }
     }



}
