<app-client-menu></app-client-menu>
<section class="row">
  <div class="col-12 m-0 banners">
    <!-- <img src="assets/img/client/img-banner.png" alt="banner-home" width="100%"> -->
    <!-- <ngb-carousel [interval]="5000">
      <ng-template ngbSlide *ngFor="let object of banners">
        <div class="picsum-img-wrapper" *ngIf="object.url == ''">
          <img [src]="object.image" alt="banner-home" width="100%">
        </div>
        <div class="picsum-img-wrapper" *ngIf="object.url != ''">
          <a [href]="object.url">
            <img [src]="object.image" alt="banner-home" width="100%">
          </a>
        </div>
      </ng-template>
    </ngb-carousel> -->
    <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
  </div>
</section>
<section class="text-center ">
  <p class="fs-4 text-center fw-light mt-4 text-uppercase">Lo destacado</p>
  <div class="picsum-img-wrapper" *ngFor="let slideProducts of products">
    <div class="row">
      <div class="col-md-3 col-6 mt-2" *ngFor=" let object of slideProducts">
        <mat-card class="text-center bg-light category productCard" >
          <div class="banderaagotado" *ngIf="object.availability == 0">
            <p style="margin: 0px;">AGOTADO</p>
          </div>
          <div class="bandera" *ngIf="object.discount">
            <p style="margin: 0px;">DESCUENTO {{object.discountValue}}%</p>
          </div>
          <div mat-card-image class=" imgCard" (mouseover)="changeImgToShow(object)" (mouseout)="ResetImgToShow(object);">
            <img [src]="object.showImage" width="100%" routerLink="/client/product/details/{{object.id}}">
          </div>
          <app-client-favorite-btn [object]="object"></app-client-favorite-btn>
          <!-- <button type="button"  [ngClass]="object.like ? 'boton-like':'boton-dislike'" (click)="object.like=!object.like"  [matTooltip]="object.like ? 'Quitar de favoritos':'Agregar a favoritos'"></button> -->
          <div class="row justify-content-center colores" style="width: 100%; margin: 0px;">
            <ul class="color">
              <li class="color" *ngFor="let object of object.attributesList" matTooltip="{{object.wood}}">
                <div class="circulo" *ngIf="!object.isTexture" [ngStyle]="{'background': object.texture }"></div><p></p>
                <div class="circulo" *ngIf="object.isTexture"> <img src="{{object.texture}}" class="circulo" style="position:absolute;"> </div><p></p>
              </li>
            </ul>
          </div>
          <div class="row justify-content-center" style="width: 100%; margin: 0px;" routerLink="/client/product/details/{{object.id}}">
            <p class="producto-title">{{object.name}}</p>
          </div>
          <div class="row justify-content-center" style="width: 100%; margin: 0px;">
            <h3 class="precio">{{object.price | currency:'MXN':'symbol-narrow'}} MXN</h3>
          </div>
          <div class="btn-product">
            <!-- <button type="button" class="btn btn-success add-shopping text-uppercase fw-light btnGeneric" *ngIf="object.availability > 0" (click)="addToShoppingCard(object)">Agregar a carrito</button> -->
            <button type="button" class="btn btn-warning add-shopping text-uppercase fw-light mt-1 btnGeneric" routerLink="/client/product/details/{{object.id}}">Ver más</button>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <button type="button" class="btn btn-outline-danger mt-2 pl-3 pr-3 btnGeneric" routerLink="/client/product/all">Ver Más</button>
</section>
<section>
  <div class="row mt-2">
    <div class="col-md-4 col-6 category p-0" *ngFor="let object of categoryList">
      <img [src]="object.description" class="img-list" alt="Random first slide" width="100%">
      <div class="btn-product-category">
        <h2 class="mb-0 mt-1 text-uppercase" style="color:white;">{{object.name}}</h2>
        <button type="button" class="btn btn-outline-light add-shopping text-uppercase fw-light btnGeneric" routerLink="/client/product/{{object.id}}">Ver colección</button>
      </div>
    </div>
  </div>
</section>
<app-client-footer></app-client-footer>
