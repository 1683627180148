<div class="row bg-dark">
  <div class="col-md-12 col-12">
    <div class="row mt-3 p-2">
      <div class="col-4">
        <address class="text-white">
            <strong>AYUDA</strong><br>
            <strong><a routerLink="/client/politica_y_aviso_privacidad">Aviso de Privacidad</a></strong>
            <br>
            <strong><a routerLink="/client/envios_devoluciones">Envíos y devoluciones</a></strong>
        </address>
      </div>
      <div class="col-4">
        <address class="text-white">
            <strong>CONTACTO</strong><br>
            Heroico Colegio Militar 616 Col. Reforma<br>
            C.P. 68050 Oaxaca de Juárez, México<br>
            951 204 6270<br>
            gecapa@outlook.com<br>
            <div
            class="fb-like"
            data-share="true"
            data-width="50"
            data-show-faces="true">
          </div>
        </address>
      </div>
      <div class="col-4">
        <address class="text-white ml-5">
            <strong>ENCUENTRANOS</strong><br>
            <div class="btn-group pt-2" role="group" aria-label="Basic outlined example">
              <button type="button" class="btn btn-outline-dark" style="border-color: transparent!important;" (click)="goExternal('https://www.facebook.com/casagecapa')">
                <img src="assets/img/client/facebook.svg" style="width:1.2rem;">
              </button>
              <button type="button" class="btn btn-outline-dark" style="border-color: transparent!important;" (click)="goExternal('https://www.instagram.com/casagecapa')">
                <img src="assets/img/client/instagram.svg" style="width:1.2rem;">
              </button>
            </div><br>
            <strong>ACEPTAMOS</strong><br>
            <div class="btn-group pt-2" role="group" aria-label="Basic outlined example">
              <button type="button" class="btn btn-outline-dark p-0" style="border-color: transparent!important;">
                <img src="assets/img/client/tarjeta-amex-color.png" style="width:2rem;">
              </button>
              <button type="button" class="btn btn-outline-dark p-0" style="border-color: transparent!important;">
                <img src="assets/img/client/tarjeta-master-color.png" style="width:2rem;">
              </button>
              <!-- <button type="button" class="btn btn-outline-dark p-0" style="border-color: transparent!important;">
                <img src="assets/img/client/tarjeta-oxxo-color.png" style="width:2rem;">
              </button> -->
              <!-- <button type="button" class="btn btn-outline-dark p-0" style="border-color: transparent!important;">
                <img src="assets/img/client/tarjeta-paypal-color.png" style="width:2rem;">
              </button> -->
              <button type="button" class="btn btn-outline-dark p-0" style="border-color: transparent!important;">
                <img src="assets/img/client/tarjeta-visa-color.png" style="width:2rem;">
              </button>
            </div>
        </address>
      </div>
    </div>
  </div>

</div>
<div class="row m-0">
  <div class="col-12 text-center" style="background:#e41a5b; color:#fff;">
    <p class="pt-1 m-0">© Copyright 2022 - Casa GECAPA</p>
  </div>
</div>
