<app-admin-toolbar></app-admin-toolbar>

<div class="row justify-content-center">
  <div class="col-11 col-md-10 cardTono">
    <div class="row justify-content-between mt-3">
      <div class="col-8">
        <h2 class="mb-2 titlePrimary">Categorias</h2>
      </div>

      <div class="col-3">
        <button mat-button color="primary" (click)="updateCategory('new',null);"><mat-icon aria-hidden="false" aria-label="Nuevo">add_circle</mat-icon> Nueva Categoria</button>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 mt-4">
        <div class="" *ngFor="let object of list;">
          <div class="treeRow">
            <div class="treeArrows">
              <button mat-icon-button color="primary" *ngIf="object.more > 0 && !object.opened" (click)="loadChildCategories(object);">
                <mat-icon>navigate_next</mat-icon>
              </button>
              <button mat-icon-button color="primary" *ngIf="object.opened" (click)="closeCategoryList(object);">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
              <button mat-icon-button color="primary" *ngIf="object.more == 0" disabled>
                <mat-icon>remove</mat-icon>
              </button>
            </div>
            <div class="treeTitle">
              {{object.name}}
            </div>
            <div class="treeActions">
              <button mat-icon-button color="primary" (click)="updateCategory(object.id,'edit');" matTooltip="Editar">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button color="primary" (click)="delete(object);" matTooltip="Eliminar">
                <mat-icon>delete</mat-icon>
              </button>
              <button mat-icon-button color="primary" (click)="updateCategory('new',object.id);" matTooltip="Agregar una nueva categoria a {{object.name}}">
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>

          <div class="" style="padding-left: 60px;" *ngIf="object.opened">
            <div class=""  *ngFor="let object of object.categoryList;">
              <div class="treeRow">
                <div class="treeArrows">
                  <button mat-icon-button color="primary" *ngIf="object.more > 0 && !object.opened" (click)="loadChildCategories(object);">
                    <mat-icon>navigate_next</mat-icon>
                  </button>
                  <button mat-icon-button color="primary" *ngIf="object.opened" (click)="closeCategoryList(object);">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>
                  <button mat-icon-button color="primary" *ngIf="object.more == 0" disabled>
                    <mat-icon>remove</mat-icon>
                  </button>
                </div>
                <div class="treeTitle">
                  {{object.name}}
                </div>
                <div class="treeActions">
                  <button mat-icon-button color="primary" (click)="updateCategory(object.id,'edit');" matTooltip="Editar">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button mat-icon-button color="primary" (click)="delete(object);" matTooltip="Eliminar">
                    <mat-icon>delete</mat-icon>
                  </button>
                  <button mat-icon-button color="primary" (click)="updateCategory('new',object.id);" matTooltip="Agregar una nueva categoria a {{object.name}}">
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </div>
              <div class="" style="padding-left: 60px;" *ngIf="object.opened">
                <div class=""  *ngFor="let object of object.categoryList;">
                  <div class="treeRow">
                    <div class="treeArrows">
                      <button mat-icon-button color="primary" *ngIf="object.more > 0 && !object.opened" (click)="loadChildCategories(object);">
                        <mat-icon>navigate_next</mat-icon>
                      </button>
                      <button mat-icon-button color="primary" *ngIf="object.opened" (click)="closeCategoryList(object);">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                      </button>
                      <button mat-icon-button color="primary" *ngIf="object.more == 0" disabled>
                        <mat-icon>remove</mat-icon>
                      </button>
                    </div>
                    <div class="treeTitle">
                      {{object.name}}
                    </div>
                    <div class="treeActions">
                      <button mat-icon-button color="primary" (click)="updateCategory(object.id,'edit');" matTooltip="Editar">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button mat-icon-button color="primary" (click)="delete(object);" matTooltip="Eliminar">
                        <mat-icon>delete</mat-icon>
                      </button>
                      <button mat-icon-button color="primary" (click)="updateCategory('new',object.id);" matTooltip="Agregar una nueva categoria a {{object.name}}">
                        <mat-icon>add</mat-icon>
                      </button>
                    </div>
                  </div>

                  <div class="" style="padding-left: 60px;" *ngIf="object.opened">
                    <div class=""  *ngFor="let object of object.categoryList;">
                      <div class="treeRow">
                        <div class="treeArrows">
                          <button mat-icon-button color="primary" *ngIf="object.more > 0 && !object.opened" (click)="loadChildCategories(object);">
                            <mat-icon>navigate_next</mat-icon>
                          </button>
                          <button mat-icon-button color="primary" *ngIf="object.opened" (click)="closeCategoryList(object);">
                            <mat-icon>keyboard_arrow_down</mat-icon>
                          </button>
                          <button mat-icon-button color="primary" *ngIf="object.more == 0" disabled>
                            <mat-icon>remove</mat-icon>
                          </button>
                        </div>
                        <div class="treeTitle">
                          {{object.name}}
                        </div>
                        <div class="treeActions">
                          <button mat-icon-button color="primary" (click)="updateCategory(object.id,'edit');" matTooltip="Editar">
                            <mat-icon>edit</mat-icon>
                          </button>
                          <button mat-icon-button color="primary" (click)="delete(object);" matTooltip="Eliminar">
                            <mat-icon>delete</mat-icon>
                          </button>
                          <!-- <button mat-icon-button color="primary" (click)="updateCategory('new',object.id);" matTooltip="Agregar una nueva categoria a {{object.name}}">
                            <mat-icon>add</mat-icon>
                          </button> -->
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>

          </div>


        </div>

      </div>
    </div>

  </div>
</div>
